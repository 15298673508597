import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import * as uuid from "uuid";
import * as ApiV2 from "../../ApiV2";
import BoxCommand from "../../command/box-command";
import HighlightTextCommand from "../../command/hightlight-Command";
import LineCommand from "../../command/line-command";
import HighLightSectionCommand from "../../command/section-command";
import { AnnoType, DataCache } from "../../config/constant";
import {
  Annotation,
  bookMarkState,
  IMouseState,
  IPageState,
  ITabState,
  IWordState,
  leftSidebarState,
  modalContainerState,
  mouseState,
  pageSelectedState,
  tabState,
  tagState,
} from "../../recoil";
import { docuviewareMainState } from "../../recoil/docuviewareState";
import { annotationService } from "../../service/annotationService";
import { documentService } from "../../service/document";
import { lineService } from "../../service/lineService";
import {
  FocusedText,
  GetSelectionBoxParameterFromWords,
} from "../../utils/helpers";
import { TabContextMenuProps } from "./menuContext/mainMenuContext";
import { MouseMode } from "../../recoil/mouseState";
import { CacheLXApp } from "../../App";
import { PageHelper } from "../../utils/pageHelper";
import { approvalStampService } from "../../service/approvalStampService";
import EditApprovalStampCommand from "../../command/ApprovalStampEdit-command";
import { approvalStampState } from "../../recoil/approvalStampState";
let clickCount = 0;
let resetclickCountTimeOut: NodeJS.Timeout | null = null;
let hightlightClickTimeOut: NodeJS.Timeout | null = null;
let IsShowTooltip = false;
const contextualMenuOpen = false;
let timeOutRawHightLightWord: any = null;
let regionsMask: any[] = [];
let timerMouseEnter: any;
let currentselectedWords: ApiV2.LxWordResponse[] = [];
export let _SelectedText = "";
export let _mouseModeState: IMouseState | null = null;

export const UseRegisterEventDocuvieware = () => {
  const [docuviewareMain, setDocuviewareMain] =
    useRecoilState(docuviewareMainState);
  const [pageState, setPageState] =
    useRecoilState<IPageState>(pageSelectedState);
  const [bookMarkCurrentState, setBookMarkState] =
    useRecoilState(bookMarkState);
  const [mouseModeState, setMouseMode] =
    useRecoilState<IMouseState>(mouseState);
  const [tabCurrentState, setTabState] = useRecoilState<ITabState>(tabState);
  const [, setLeftSidebarState] = useRecoilState(leftSidebarState);
  const [, setTagState] = useRecoilState(tagState);
  const [modalContainer, setModalContainerState] =
    useRecoilState(modalContainerState);
  const [, SetApprovalStampState] = useRecoilState(approvalStampState);
  const [mainMenuContext, setMainMenuContextState] =
    useState<TabContextMenuProps | null>();
  useEffect(() => {
    CacheLXApp.MainMenuContext = {
      ...CacheLXApp.MainMenuContext,
      IsShowContextMenu: mainMenuContext?.IsShowContextMenu,
    };
  }, [mainMenuContext?.IsShowContextMenu]);
  useEffect(() => {
    _mouseModeState = mouseModeState;
  }, [mouseModeState]);

  useEffect(() => {
    CacheLXApp.PageState = pageState;
  }, [pageState]);
  useEffect(() => {
    CacheLXApp.DocuviewareMain = docuviewareMain;
  }, [docuviewareMain]);
  function RemoveSelectedTextRegionByColor(
    listRegion: any[],
    dom: any,
    colorName: string,
    isAll = false
  ) {
    if (!listRegion || listRegion?.length == 0) return;
    const arr = [...listRegion];
    if (isAll == true) {
      dom.SelectedTextRegions.clear();
    }
    if (arr) {
      const region = arr.filter((o) => {
        return o.outerHTML.indexOf(colorName) > 0;
      });
      if (region) {
        region.forEach((element) => {
          const index = arr.indexOf(element);
          listRegion.splice(index, 1);
          if (element.parentNode) {
            element.parentNode.removeChild(element);
          }
        });
      }
    }
  }
  function IsWordSelected(x: ApiV2.LxWordResponse, posX: any, posY: any) {
    let result = false;
    if (x.rects) {
      const x1 = x.rects[0] as ApiV2.RectangleF;
      result =
        (x1?.left || 0) <= posX &&
        (x1.left || 0) + (x1.width || 0) > posX &&
        (x1.top || 0) <= posY &&
        (x1.top || 0) + (x1.height || 0) >= posY;
      if (!result && x.rects.length > 1) {
        const x2 = x.rects[1] as ApiV2.RectangleF;
        result =
          (x2.left || 0) <= posX &&
          (x2.left || 0) + (x2.width || 0) > posX &&
          (x2.top || 0) <= posY &&
          (x2.top || 0) + (x2.height || 0) >= posY;
      }
      return result;
    }
    return result;
  }
  const setDataPageChange = (pageNo: number) => {
    if (
      (CacheLXApp.PageState?.oldCurrentPage || 0) > 0 &&
      CacheLXApp.PageState?.oldCurrentPage != pageNo
    ) {
      return;
    }
    CacheLXApp.FocusWord = null;
    const pageSelected =
      (CacheLXApp.DocuviewareMain?.DocuviewareInit?.pages || []).find(
        (o) => o.viewerPageNo == pageNo
      ) || null;
    setPageState((cur) => {
      return { ...cur, currentPage: pageNo, PageObj: pageSelected };
    });
    if (CacheLXApp.PageState) {
      CacheLXApp.PageState = { ...CacheLXApp.PageState, currentPage: pageNo };
    }
    documentService.SelectedPage(
      (res: any) => {
        if (!res) return;
        const data = JSON.parse(res);
        if (data) {
          const pageDetail = data as ApiV2.WordInPage;
          if (CacheLXApp.pageDetails) {
            CacheLXApp.pageDetails = [pageDetail];
          }
        }
        const tab =
          CacheLXApp.DocuviewareMain?.DocuviewareInit?.tabDetails?.find((x) => {
            if (x.startPage && x.totalPage) {
              return (
                x.startPage <= pageNo && pageNo < x.startPage + x.totalPage
              );
            }
            return false;
          });
        if (tab) {
          setTabState({ ...tabCurrentState, selectedTab: tab });
        }
        setTimeout(() => {
          if (pageNo === CacheLXApp.PageState?.rectangleFSelected?.pageNo) {
            FocusedText(
              CacheLXApp.PageState?.rectangleFSelected?.rectangleSelections,
              CacheLXApp.PageState?.rectangleFSelected?.pageNo || 0,
              DataCache.docuViewareID() || "",
              CacheLXApp.PageState?.rectangleFSelected?.color
            );
          }
        }, 100);
      },
      DataCache.docuViewareID(),
      {
        Data: JSON.stringify({ currentPage: pageNo }),
        Type: "SelectedPageCommand",
      }
    );
  };
  const onMenuContext = (e: any, annot?: Annotation) => {
    const root = DocuViewareAPI.Root(DataCache.docuViewareID());
    const mo = root.Internal.Text.captureSelectedText(
      e.clientX,
      e.clientY,
      e.clientX,
      e.clientY,
      !1,
      !1
    );
    const mousePos = mo && mo.length >= 1 ? mo[0].mousePos : {};
    annot = annot ||
      CacheLXApp.AnnotState || {
        AnnotationId: "",
        Data: "",
        TagType: "",
        ObjId: "",
        ClientTag: "",
        Subject: "",
        replaceId: "",
      };

    e?.preventDefault();
    e?.stopPropagation();
    const param = {
      regionsMask: CacheLXApp.WordCurrentState?.regionsMaskLasted,
      targetPoint: { x: e.clientX, y: e.clientY },
      PageSelected:
        (docuviewareMain?.DocuviewareInit?.pages || []).find(
          (o) => o.viewerPageNo == CacheLXApp.PageState?.currentPage
        ) || null,
      WordDetected: CacheLXApp.WordCurrentState?.wordDetected,
      AnnotationId: annot?.AnnotationId,
      AnnotationTagType: annot?.TagType,
      BoxId:
        annot?.TagType === AnnoType.BOX ||
        annot?.TagType === AnnoType.REPLACEBOX
          ? annot?.ObjId || ""
          : null,
      LastMousePos: mousePos,
      ObjMouseMove: CacheLXApp.DataMouseMoveInView,
      NoteId: annot?.TagType === AnnoType.NOTE ? annot?.ObjId || "" : null,
      ReplaceId: annot?.replaceId || "",
      TagId: annot?.TagType === AnnoType.TAG ? annot?.ObjId : null,
      refreshMenu: true,
      annotSelect: annot,
    } as TabContextMenuProps;
    CacheLXApp.MainMenuContext = {
      ...CacheLXApp.MainMenuContext,
      ...param,
      IsShowContextMenu: true,
    };
    setMainMenuContextState((cur) => {
      return {
        ...cur,
        ...param,
        IsShowContextMenu: true,
      };
    });
  };
  const getWordFocus = (data: any) => {
    let word: ApiV2.LxWordResponse | null | undefined = null;
    const pageDetail = CacheLXApp?.pageDetails?.find(
      (x) => x.pageNo === data.pageNo
    );
    if (!pageDetail) return null;
    const page = (
      CacheLXApp.DocuviewareMain?.DocuviewareInit?.pages || []
    ).find((o) => o.viewerPageNo == data.pageNo);
    if (pageDetail && pageDetail.words) {
      let posX = data.mousePos.Left / 72;
      let posY = data.mousePos.Top / 72;
      if (page?.rotationAngles == 270) {
        const a = {
          L: data.mousePos.Top / 72,
          T: (page?.pageHeight || 0) - data.mousePos.Right / 72,
        };
        posX = a.L;
        posY = a.T;
      }
      if (page?.rotationAngles == 90) {
        const a = {
          L: (page?.pageWidth || 0) - data.mousePos.Bottom / 72,
          T: data.mousePos.Left / 72,
        };
        posX = a.L;
        posY = a.T;
      }
      if (page?.rotationAngles == 180) {
        const a = {
          L: (page?.pageWidth || 0) - data.mousePos.Right / 72,
          T: (page?.pageHeight || 0) - data.mousePos.Top / 72,
        };
        posX = a.L;
        posY = a.T;
      }
      word = pageDetail.words.find((x: any) => {
        return IsWordSelected(x, posX, posY);
      });
    }
    return word;
  };
  const RegisterEventDocuvieware = (onAfterInit: any) => {
    function isInitialized(reloadDocument: any) {
      const root = DocuViewareAPI.Root(DataCache.docuViewareID());
      if (root) {
        root.Internal.Annotations.CANVAS_PADDING = 4;
        root.dom.maxUploadSize = 10000000000000;
      }
      const initialize = DocuViewareAPI.IsInitialized(
        DataCache.docuViewareID()
      );
      if (initialize) {
        onAfterInit(reloadDocument);
        return;
      }
    }

    DocuViewareAPI.RegisterOnDocumentInited(DataCache.docuViewareID(), () => {
      documentService.SelectedPage(
        (res: any) => {
          if (!res) return;
          const data = JSON.parse(res);
          if (data) {
            const pageDetail = data as ApiV2.WordInPage;
            if (CacheLXApp.DocuviewareMain) {
              CacheLXApp.pageDetails = [pageDetail];
            }
          }
        },
        DataCache.docuViewareID(),
        {
          Data: JSON.stringify({ currentPage: 1 }),
          Type: "SelectedPageCommand",
        }
      );
    });
    DocuViewareAPI.RegisterOnNewDocumentLoaded(
      DataCache.docuViewareID(),
      (reloadDocument: any) => {
        isInitialized(reloadDocument);
        setTimeout(() => {
          documentService.centerScroll();
        }, 1200);
      }
    );
    DocuViewareAPI.RegisterOnPageChanged(
      DataCache.docuViewareID(),
      async () => {
        const pageNo = DocuViewareAPI.GetCurrentPage(DataCache.docuViewareID());
        setDataPageChange(pageNo || CacheLXApp.PageState?.currentPage);
      }
    );
    DocuViewareAPI.RegisterOnGetDataBeforePageChanged(
      DataCache.docuViewareID(),
      () => {}
    );

    DocuViewareAPI.RegisterOnMouseMove(
      DataCache.docuViewareID(),
      (data: any) => {
        // if (CacheLXApp.MainMenuContext?.IsShowContextMenu) {
        //   return;
        // }
        CacheLXApp.DataMouseMoveInView = data;
        regionsMask = [];
        const mousePos = data && data.mousePos ? data.mousePos : {};
        if (!CacheLXApp.MainMenuContext?.IsShowContextMenu) {
          CacheLXApp.MainMenuContext = {
            ...CacheLXApp.MainMenuContext,
            LastMousePos: mousePos,
            regionsMask: [],
          };
          setMainMenuContextState((cur) => {
            return {
              ...cur,
              regionsMask: [],
            };
          });
        }
        const word = getWordFocus(data);
        if (
          word &&
          (_mouseModeState?.mouseMode == MouseMode.Select ||
            _mouseModeState?.mouseMode == MouseMode.Tag)
        ) {
          rawHightLightWord(word || null, data);
        }
        if (_mouseModeState?.mouseMode == MouseMode.Mask) {
          regionsMask = data.regions;
        }
        CacheLXApp.WordCurrentState = {
          ...CacheLXApp.WordCurrentState,
          wordDetected: word || null,
        };
      }
    );

    DocuViewareAPI.RegisterOnAnnotationAdded(
      DataCache.docuViewareID(),
      async (obj: any) => {
        const anno = obj.anno;
        if (_mouseModeState?.mouseMode === -1) {
          if (!anno) return;
          let height = anno?.properties?.height;
          if (anno?.properties?.width > anno?.properties?.height) {
            height = anno?.properties?.width;
          }
          const addLineParam: ApiV2.AddLineParam = {
            left: anno?.properties?.left,
            top: anno?.properties?.top,
            borderWidth: anno?.properties?.borderWidth,
            height: anno?.properties?.height,
            width: anno?.properties?.width,
            color: rgbaToHex(anno?.properties?.strokeColor),
            id: anno?.id,
          };
          const currentPage = DocuViewareAPI.GetCurrentPage(
            DataCache.docuViewareID()
          );
          const addLine = {
            viewerPageNo: currentPage,
            addLineParam: addLineParam,
          };
          LineCommand(
            DataCache.docuViewareID(),
            addLine,
            null,
            null,
            null,
            () => {
              setLeftSidebarState((cur) => {
                return {
                  ...cur,
                  refreshTabActive: { IsRefresh: true, TabIndex: -1 },
                };
              });
              setMouseMode({ mouseMode: -1 });

              lineService.onLineClick(
                DataCache.docuViewareID() || "",
                currentPage,
                CacheLXApp.LineBorderWidth?.borderAdd || 0,
                CacheLXApp.LineBorderWidth?.colorAdd || "#333"
              );
            }
          );
        }
      }
    );
    DocuViewareAPI.RegisterOnAnnotationMouseEnter(
      DataCache.docuViewareID(),
      (obj: any) => {
        if (CacheLXApp.MainMenuContext?.IsShowContextMenu) return;
        window.clearTimeout(timerMouseEnter);
        timerMouseEnter = window.setTimeout(() => {
          const rootDocuVieware = DocuViewareAPI.Root(
            DataCache.docuViewareID()
          );
          if (!rootDocuVieware) return;
          const dom = rootDocuVieware.dom;
          if (_mouseModeState?.mouseMode != 3) {
            rootDocuVieware.Internal.Text.clearSelection();
            RemoveSelectedTextRegionByColor(
              dom.SelectedTextRegions._cache,
              dom,
              "rgba(0, 0, 0, 0.3)"
            );
          }
          const clientTag = obj.id.clientTag.toString() + "_" + "_";
          const subject = obj.id.subject.toString();
          // if (clientTag.indexOf(AnnoType.LINE) >= 0) {
          //   setLineState({ border: obj.id.properties?.borderWidth, color: rgbaToHex(obj.id.properties?.strokeColor) });
          // }
          const dataTags = clientTag.split("_");
          CacheLXApp.AnnotState = {
            ...CacheLXApp.AnnotState,
            AnnotationId: obj.id.id,
            ObjId: dataTags[1] || "",
            replaceId:
              subject == AnnoType.HIGHLIGHT
                ? dataTags[1] || ""
                : subject == AnnoType.REPLACEBOX
                ? dataTags[2] || ""
                : "",
            Data: dataTags[1],
            TagType: dataTags[0],
            ClientTag: dataTags[0],
            Subject: subject,
            author: obj.id.author,
            creationTime: obj.id.creationTime,
          };
          if (
            CacheLXApp.AnnotState?.TagType === AnnoType.REPLACEBOX &&
            obj.id.properties.type === 2
          ) {
            const pageNo = DocuViewareAPI.GetCurrentPage(
              DataCache.docuViewareID()
            );
            if (!IsShowTooltip) {
              annotationService.AddAnnotations(
                DataCache.docuViewareID(),
                {
                  Data: JSON.stringify({
                    pageNo: pageNo,
                    id: CacheLXApp.AnnotState?.ObjId || "",
                  }),
                  Type: "ShowReplaceBox",
                },
                () => {
                  IsShowTooltip = true;
                }
              );
            }
            return;
          } else if (
            CacheLXApp.AnnotState?.TagType === AnnoType.REPLACE &&
            obj.id.properties.type === 2
          ) {
            const pageNo = DocuViewareAPI.GetCurrentPage(
              DataCache.docuViewareID()
            );
            if (!IsShowTooltip) {
              annotationService.AddAnnotations(
                DataCache.docuViewareID(),
                {
                  Data: JSON.stringify({
                    viewerPageNo: pageNo,
                    replaceObjId: CacheLXApp.AnnotState?.ObjId || "",
                    rectangleWords: [
                      {
                        left: obj.id.rect.Left || 0,
                        top: obj.id.rect.Top || 0,
                        width: obj.id.rect.Width || 0,
                        height: obj.id.rect.Height || 0,
                      },
                    ],
                  } as ApiV2.ReplaceTooltipWeb),
                  Type: "ShowReplace",
                },
                () => {
                  IsShowTooltip = true;
                }
              );
            }
          }
        }, 200);
      }
    );
    DocuViewareAPI.RegisterOnAnnotationMouseLeave(
      DataCache.docuViewareID(),
      (obj: any) => {
        CacheLXApp.AnnotState = {
          ...CacheLXApp.AnnotState,
          AnnotationId: "",
          Data: "",
          TagType: "",
          ObjId: "",
          ClientTag: "",
          Subject: "",
          author: "",
          creationTime: "",
          replaceId: "",
        };
        if (CacheLXApp.MainMenuContext?.IsShowContextMenu) return;
        window.clearTimeout(timerMouseEnter);
        const tag = obj.id.clientTag.toString().split("_")[0];
        if (tag === AnnoType.REPLACE && obj.id.properties.type === 2) {
          IsShowTooltip = false;
          annotationService.AddAnnotations(
            DataCache.docuViewareID(),
            {
              Type: "DeleteReplaceTooltip",
            },
            null
          );
          return;
        } else if (
          tag === AnnoType.REPLACEBOX &&
          obj.id.properties.type === 2
        ) {
          IsShowTooltip = false;
          annotationService.AddAnnotations(
            DataCache.docuViewareID(),
            {
              Type: "DeleteReplaceBoxTooltip",
            },
            null
          );
          return;
        }
      }
    );

    DocuViewareAPI.RegisterOnAreaSelected(DataCache.docuViewareID(), () => {
      const currentPage = DocuViewareAPI.GetCurrentPage(
        DataCache.docuViewareID()
      );
      const rect = DocuViewareAPI.GetSelectionAreaCoordinates(
        DataCache.docuViewareID()
      );
      if (contextualMenuOpen) return;
      if (rect) {
        if (_mouseModeState?.mouseMode == 0) {
          const pageNo = DocuViewareAPI.GetCurrentPage(
            DataCache.docuViewareID()
          );
          const param = {
            viewerPageNo: pageNo,
            addBoxParam: {
              left: rect.left,
              top: rect.top,
              width: rect.width,
              height: rect.height,
              id: uuid.v4(),
            },
          };
          BoxCommand(DataCache.docuViewareID(), param, null, () => {
            setLeftSidebarState((cur) => {
              return {
                ...cur,
                refreshTabActive: { IsRefresh: true, TabIndex: -1 },
              };
            });
          });
        } else if (_mouseModeState?.mouseMode == 1) {
          const selectionBox: ApiV2.RectangleSelection[] = [
            {
              left: rect.left,
              top: rect.top,
              width: rect.width,
              height: rect.height,
              viewerPageNo: currentPage,
            },
          ];
          let mode = 1;
          // nhấn giữ shift thì UnHighLightWords
          if (CacheLXApp.KeyCodeOfKeyDown === 17) {
            mode = 0;
          }
          const param: ApiV2.HighLightOrUnHighLightWords = {
            rectangleSelection: selectionBox,
            mode: mode,
            viewerPageNo: CacheLXApp.PageState?.currentPage,
          };
          HighLightSectionCommand(DataCache.docuViewareID(), param, () => {
            CacheLXApp.KeyCodeOfKeyDown = null;
            setLeftSidebarState((cur) => {
              return {
                ...cur,
                refreshTabActive: {
                  IsRefresh: true,
                  TabIndex: -1,
                  ForceSearch: true,
                },
              };
            });
          });
        }
      }

      DocuViewareAPI.Root(
        DataCache.docuViewareID()
      ).Internal.Selection.clearSelectionArea();
    });

    DocuViewareAPI.RegisterOnAnnotationMouseDown(
      DataCache.docuViewareID(),
      async (obj: any) => {
        const objAnno = obj.data;
        const clientTag = objAnno.clientTag.toString() + "_" + "_";
        if (
          CacheLXApp.MainMenuContext?.IsShowContextMenu &&
          clientTag.split("_")[0] != AnnoType.Bookmark
        )
          return;
        const rootDocuVieware = DocuViewareAPI.Root(DataCache.docuViewareID());
        rootDocuVieware.Internal.Text.clearSelection();
        const dom = rootDocuVieware.dom;
        RemoveSelectedTextRegionByColor(
          dom.SelectedTextRegions._cache,
          dom,
          "rgba(0, 0, 0, 0.3)"
        );
        if (objAnno.clientTag.toString().indexOf("LINE") >= 0) {
          CacheLXApp.LineBorderWidth = {
            ...CacheLXApp.LineBorderWidth,
            borderEdit: objAnno.properties?.borderWidth,
            colorEdit: rgbaToHex(objAnno.properties?.strokeColor),
          };
        }
        const dataTags = clientTag.split("_");
        const subject = objAnno.subject.toString();
        CacheLXApp.AnnotState = {
          ...CacheLXApp.AnnotState,
          AnnotationId: objAnno.id,
          ObjId: clientTag.split("_")[1] || "",
          replaceId:
            subject == AnnoType.HIGHLIGHT
              ? dataTags[1] || ""
              : subject == AnnoType.REPLACEBOX
              ? dataTags[2] || ""
              : "",
          Data: clientTag.split("_")[1],
          TagType: clientTag.split("_")[0],
          ClientTag: clientTag.split("_")[0],
          Subject: subject,
          author: objAnno.author || "",
          creationTime: objAnno.creationTime || "",
        };
        const word = getWordFocus(CacheLXApp.DataMouseMoveInView);
        CacheLXApp.WordCurrentState = {
          ...CacheLXApp.WordCurrentState,
          wordDetected: word || null,
        };
        if (3 == obj.event.which) {
          onMenuContext(obj.event, CacheLXApp.AnnotState);
        }
      }
    );
    DocuViewareAPI.RegisterOnAnnotationStopEdit(
      DataCache.docuViewareID(),
      () => {
        setLeftSidebarState((cur) => {
          return {
            ...cur,
            refreshTabActive: { IsRefresh: true, TabIndex: -1 },
          };
        });
      }
    );
    DocuViewareAPI.RegisterOnMouseDown(DataCache.docuViewareID(), (e: any) => {
      const root = DocuViewareAPI.Root(DataCache.docuViewareID());
      if (3 != e.which) {
        if (
          _mouseModeState?.mouseMode != 3 &&
          _mouseModeState?.mouseMode != 4
        ) {
          root.events.isSelectingText = false;
          if (CacheLXApp.MainMenuContext?.IsShowContextMenu) {
            CacheLXApp.WordCurrentState = {
              ...CacheLXApp.WordCurrentState,
              wordDetected: null,
            };
          }
        } else {
          root.events.isSelectingText = true;
        }
      } else {
        if (!CacheLXApp.AnnotState?.AnnotationId) {
          const word = getWordFocus(CacheLXApp.DataMouseMoveInView);
          CacheLXApp.WordCurrentState = {
            ...CacheLXApp.WordCurrentState,
            wordDetected: word || null,
          };
          onMenuContext(e);
        }
      }
    });
    DocuViewareAPI.RegisterOnMouseUp(
      DataCache.docuViewareID(),
      async (obj: any) => {
        if (CacheLXApp.clickOutDocument) {
          CacheLXApp.clickOutDocument = false;
          return;
        }
        if (
          CacheLXApp.AnnotState?.AnnotationId &&
          CacheLXApp.AnnotState?.TagType != AnnoType.HIGHLIGHT
        ) {
          return;
        }
        clickCount++;
        setTagState((cur) => {
          return { ...cur, idTagSelected: "" };
        });
        if (_mouseModeState?.mouseMode === 0) return;
        const root = DocuViewareAPI.Root(DataCache.docuViewareID());
        const word = CacheLXApp.WordCurrentState?.wordDetected;
        const dom = root.dom;
        if (regionsMask) {
          _SelectedText = DocuViewareAPI.GetSelectedText(
            DataCache.docuViewareID()
          );
        } else {
          _SelectedText = "";
        }
        //tag
        if (_mouseModeState?.mouseMode === 4) {
          RemoveSelectedTextRegionByColor(
            dom.SelectedTextRegions._cache,
            dom,
            "rgba(0, 0, 0, 0.3)"
          );
          if (word) {
            if (currentselectedWords) {
              if (currentselectedWords.length == 0) {
                RemoveSelectedTextRegionByColor(
                  dom.SelectedTextRegions._cache,
                  dom,
                  "rgba(24, 138, 255, 0.4)"
                );
              }
              const re1 = currentselectedWords.find((o) => {
                if (!o || !word.id) return false;
                return (
                  (o.id && o.id + 1 === word.id) ||
                  (o.id && o.id - 1 === word.id)
                );
              });
              if (
                !re1 &&
                re1 === undefined &&
                currentselectedWords.length > 0
              ) {
                RemoveSelectedTextRegionByColor(
                  dom.SelectedTextRegions._cache,
                  dom,
                  "rgba(24, 138, 255, 0.4)"
                );
                currentselectedWords = [];
              }
              const re = currentselectedWords.find((o) => {
                if (!o || !word.id) return false;
                return o.id == word.id;
              });
              if (re && re != undefined) return;
              currentselectedWords = [...currentselectedWords, word];
            } else {
              currentselectedWords = [...currentselectedWords, word];
            }
            currentselectedWords.forEach((item) => {
              item.rects?.forEach((element) => {
                const e = {
                  left:
                    ((element.left || 0) * 72) /
                    CacheLXApp.DataMouseMoveInView.scale,
                  top:
                    ((element.top || 0) * 72) /
                    CacheLXApp.DataMouseMoveInView.scale,
                  width:
                    ((element.width || 0) * 72) /
                    CacheLXApp.DataMouseMoveInView.scale,
                  height:
                    ((element.height || 0) * 72) /
                    CacheLXApp.DataMouseMoveInView.scale,
                };
                dom.SelectedTextRegions.addNew(
                  CacheLXApp.PageState?.currentPage,
                  e,
                  "rgba(24, 138, 255, 0.4)",
                  null,
                  2,
                  null
                );
              });
            });
          } else {
            currentselectedWords = [];
          }

          CacheLXApp.WordCurrentState = {
            ...CacheLXApp.WordCurrentState,
            currentselectedWords: currentselectedWords,
          };
          if (currentselectedWords.length === 0) {
            RemoveSelectedTextRegionByColor(
              dom.SelectedTextRegions._cache,
              dom,
              "rgba(24, 138, 255, 0.4)"
            );
          }
          return;
        }
        if (
          word &&
          word.rects &&
          (_mouseModeState?.mouseMode === 2 || _mouseModeState?.mouseMode === 1)
        ) {
          if (clickCount == 2) {
            HightLightLine();
            if (hightlightClickTimeOut) {
              clearTimeout(hightlightClickTimeOut);
            }
            return;
          }
          hightlightClickTimeOut = setTimeout(function () {
            const selectionBox = GetSelectionBoxParameterFromWords([word]);
            const param: ApiV2.HighLightOrUnHighLightWords = {
              rectangleSelection: selectionBox,
              mode: -1,
              viewerPageNo: CacheLXApp.PageState?.currentPage,
            };
            HighlightTextCommand(DataCache.docuViewareID(), param, () => {
              setLeftSidebarState((cur) => {
                return {
                  ...cur,
                  refreshTabActive: {
                    IsRefresh: true,
                    TabIndex: -1,
                    ForceSearch: true,
                  },
                };
              });
            });
          }, 200);
        }
        if (regionsMask && _mouseModeState?.mouseMode === MouseMode.Mask) {
          if (!CacheLXApp.lxSetting?.autoHighlightMask) {
            CacheLXApp.WordCurrentState = {
              ...CacheLXApp.WordCurrentState,
              regionsMaskLasted: regionsMask || [],
            };
            setTimeout(() => {
              onMenuContext(obj);
            }, 200);
          } else {
            const pageNo = DocuViewareAPI.GetCurrentPage(
              DataCache.docuViewareID()
            );
            CacheLXApp.WordCurrentState = {
              ...CacheLXApp.WordCurrentState,
              regionsMaskLasted: regionsMask || [],
            };
            documentService.HighLightOrUnHighLightMask(
              -1,
              pageNo,
              regionsMask,
              () => {
                CacheLXApp.AnnotState = {
                  ...(CacheLXApp.AnnotState || {}),
                  TagType: "",
                };
                setLeftSidebarState((cur) => {
                  return {
                    ...cur,
                    refreshTabActive: {
                      IsRefresh: true,
                      TabIndex: -1,
                      ForceSearch: true,
                    },
                  };
                });
              }
            );
          }
        } else {
          CacheLXApp.WordCurrentState = {
            ...CacheLXApp.WordCurrentState,
            regionsMaskLasted: [],
          };
        }
        if (resetclickCountTimeOut) {
          clearTimeout(resetclickCountTimeOut);
        }
        resetclickCountTimeOut = setTimeout(function () {
          clickCount = 0;
          if (
            _mouseModeState?.mouseMode != 3 &&
            _mouseModeState?.mouseMode != 4
          ) {
            RemoveSelectedTextRegionByColor(
              dom.SelectedTextRegions._cache,
              dom,
              "",
              true
            );
            CacheLXApp.WordCurrentState = {
              ...CacheLXApp.WordCurrentState,
              wordDetected: null,
            };
            CacheLXApp.AnnotState = {
              ...CacheLXApp.AnnotState,
              AnnotationId: "",
              Data: "",
              TagType: "",
              ObjId: "",
              ClientTag: "",
              Subject: "",
              author: "",
              creationTime: "",
              replaceId: "",
            };
          }
        }, 200);
      }
    );
    DocuViewareAPI.RegisterOnAnnotationMouseDblClick(
      DataCache.docuViewareID(),
      (obj: any) => {
        if (
          obj.data.clientTag.toString().toLowerCase().indexOf("bookmark") >= 0
        ) {
          const id = obj.data.clientTag.toString().split("_")[1];
          setBookMarkState((cur) => {
            const item = cur.ListBookmark.find((o) => o.id == id);
            if (item?.position?.isEmpty) {
              return cur;
            }
            return {
              ...cur,
              ShowBookMarkModal: "edit",
              itemSelecteds: item ? [item] : [],
            };
          });
        }
        if (obj.data.clientTag.toString().toLowerCase().indexOf("toc") >= 0) {
          const istitle =
            obj.data.clientTag.toString().toLowerCase().indexOf("title") >= 0;
          setModalContainerState({
            ...modalContainer,
            showEditHeaderToc: true,
            EditTocHeaderParam: {
              text: obj.data.properties.text,
              editTitle: istitle,
              tocColumEnumType: istitle
                ? ApiV2.TocColumEnum.NUMBER_0
                : (parseInt(
                    obj.data.clientTag.toString().split("_")[1]
                  ) as ApiV2.TocColumEnum),
            },
          });
        }
      }
    );
    DocuViewareAPI.RegisterOnAnnotationEdited(
      DataCache.docuViewareID(),
      (obj: any) => {
        if (obj.annot.annotApp.subject === AnnoType.ApprovalStamp) {
          SetApprovalStampState((cur) => {
            const item = cur.ApprovalStampViewItems.find(
              (o) => o.id == obj.annot.annotApp.clientTag.split("_")[1]
            );
            EditApprovalStampCommand(
              CacheLXApp.PageState?.currentPage || 0,
              {
                approvalStampDesignRepx: item?.approvalStampDesignRepx || "",
                createdBy: item?.createdBy || "",
                creationTime: item?.creationTime || "",
                id: item?.id || "",
                date: item?.date || "",
                docNo: item?.docNo || "",
                stampTemplateId: item?.stampTemplateId || "",
                transparent: item?.transparent || 0,
                location: {
                  centerX: obj.annot.annotApp.properties.left,
                  centerY: obj.annot.annotApp.properties.top,
                  width: obj.annot.annotApp.properties.width / 0.393700787,
                  height: obj.annot.annotApp.properties.height / 0.393700787,
                },
              },
              item,
              (data: ApiV2.ApprovalStampViewItem[]) => {
                if (data) {
                  SetApprovalStampState((cur) => {
                    return {
                      ...cur,
                      isAdd: false,
                      ApprovalStampViewItems: data,
                      EditApprovalStampInfo: null,
                    };
                  });
                  documentService.RefreshPage(null, DataCache.docuViewareID());
                }
              }
            );
            return cur;
          });
          // GetListApprovalStamp();
        }
      }
    );
  };
  const rgbaToHex = (color: string): string => {
    if (/^rgb/.test(color)) {
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");

      // rgb to hex
      // eslint-disable-next-line no-bitwise
      let hex = `#${(
        (1 << 24) +
        (parseInt(rgba[0], 10) << 16) +
        (parseInt(rgba[1], 10) << 8) +
        parseInt(rgba[2], 10)
      )
        .toString(16)
        .slice(1)}`;

      // added alpha param if exists
      if (rgba[4]) {
        const alpha = Math.round(0o1 * 255);
        const hexAlpha = (alpha + 0x10000)
          .toString(16)
          .substr(-2)
          .toUpperCase();
        hex += hexAlpha;
      }

      return hex;
    }
    return color;
  };
  const HightLightLine = () => {
    const word = CacheLXApp.WordCurrentState?.wordDetected;
    if (
      word &&
      word.rects &&
      (mouseModeState.mouseMode === 2 || mouseModeState.mouseMode === 1)
    ) {
      const param: ApiV2.HighLightUnHighLightLine = {
        pageNo: CacheLXApp.PageState?.currentPage,
        isHotKey: true,
        x: CacheLXApp.MainMenuContext?.LastMousePos?.Right / 72,
        y: CacheLXApp.MainMenuContext?.LastMousePos?.Top / 72,
      };
      documentService.HighLightLine(param, () => {
        setLeftSidebarState((cur) => {
          return {
            ...cur,
            refreshTabActive: { IsRefresh: true, TabIndex: -1 },
          };
        });
      });
    }
  };
  const rawHightLightWord = (
    word: ApiV2.LxWordResponse | null,
    objMouseMove: any
  ) => {
    if (!objMouseMove || !word) return;
    if (timeOutRawHightLightWord) {
      clearTimeout(timeOutRawHightLightWord);
      timeOutRawHightLightWord = null;
    }
    CacheLXApp.FocusWord = word;
    timeOutRawHightLightWord = setTimeout(() => {
      if (
        _mouseModeState?.mouseMode == 1 ||
        _mouseModeState?.mouseMode == 2 ||
        _mouseModeState?.mouseMode == 4
      ) {
        if (word) {
          PageHelper.drawFocusWord(word, CacheLXApp.DataMouseMoveInView.pageNo);
          return;
        }
      }
    }, 100);
  };
  return { RegisterEventDocuvieware, mainMenuContext, setMainMenuContextState };
};
