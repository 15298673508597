import * as Api from '../ApiV2';
import { LocalStorageKey } from '../config/constant';
import { annotationService } from '../service/annotationService';
import undoManager from './undo-manager';
import { UndoHightLight } from './undoHightLight';
import { CacheLXApp } from "../App";
const TrackingApi = new Api.TrackingApi();
const HighlightMaskCommand = (
  docuViewareID: string | null | undefined,
  param: Api.HighLightOrUnHighLightWords,
  callback: any,
) => {
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, 'true');
  let ParamUndo = '';
  const HighLightOrUnHighLightByMask = (param: Api.HighLightOrUnHighLightWords) => {
    if (!param) return;
    const dataRequest = {
      Data: JSON.stringify(param),
      Type: 'HighlightMaskCommand',
    };
    annotationService.AddAnnotations(docuViewareID, dataRequest, async (res: any) => {
      ParamUndo = res;
      if (callback) {
        CacheLXApp.pageDetails = [
          (
            await TrackingApi.apiLegalxtractGetWordByPageNoGet(
              param.viewerPageNo || 0
            )
          ).data,
        ];
        callback();
      }
    });
  };
  HighLightOrUnHighLightByMask(param);
  undoManager.add({
    undo: function () {
      UndoHightLight(docuViewareID, ParamUndo, callback);
    },
    redo: function () {
      HighLightOrUnHighLightByMask(param);
    },
  });
};

export default HighlightMaskCommand;
