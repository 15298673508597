import { useRecoilState } from "recoil";
import * as Api from "../ApiV2";
import PageNumberingCommand from "../command/pageNumbering-command";
import { DataCache, LocalStorageKey } from "../config/constant";
import {
  docuviewareMainState,
  IPageState,
  leftSidebarState,
  pageSelectedState,
  tabState,
  topmenuStripState,
} from "../recoil";
import { documentService } from "./document";
import { TabIndex } from "../components/left-sidebar/left-sidebar-container";
import { App, CacheLXApp } from "../App";
import { AppHelper } from "../utils/AppHelper";
import { defaultStampData } from "../recoil/topmenuStripState";
const StampApi = new Api.StampApi();
const TocApi = new Api.TocApi();
const TrackingApi = new Api.TrackingApi();

const topMenuStripService = () => {
  const [topmenuStrip, setTopmenuStripState] =
    useRecoilState(topmenuStripState);
  const [docuviewareMain, setDocuviewareMainState] =
    useRecoilState(docuviewareMainState);

  const [tab, setTabState] = useRecoilState(tabState);
  const [pageState, setPageState] =
    useRecoilState<IPageState>(pageSelectedState);
  const [leftSidebar, setLeftSidebarState] = useRecoilState(leftSidebarState);
  const SaveStamp = async (param: Api.LxStamp) => {
    // lưu vào userConfigApp cm to inch
    const newParam = JSON.parse(JSON.stringify(param));
    let userConfigApp = LocalStorageKey.GetOjectItem<Api.UserConfigApp>(
      LocalStorageKey.UserConfigApp
    );
    if (newParam.stamp?.location) {
      newParam.stamp.location.height = AppHelper.CmToInch(
        newParam.stamp.location?.height || 2
      );
      newParam.stamp.location.width = AppHelper.CmToInch(
        newParam.stamp.location?.width || 2
      );
    }
    userConfigApp = { ...userConfigApp, mainStamp: newParam };
    localStorage.setItem(
      LocalStorageKey.UserConfigApp,
      JSON.stringify(userConfigApp)
    );
    //-----------------------------------
    const result = await StampApi.apiLegalxtractApplyStampAllPagePost(
      param.showFromTOC,
      param.automaticCount,
      param.tocIndexPattern || "",
      {
        ...param.stamp,
        fontName: param.stamp?.fontName || defaultStampData?.stamp!.fontName,
        fontSize: param.stamp?.fontSize ||  defaultStampData?.stamp!.fontSize,
        fontColor: param.stamp?.fontColor || defaultStampData?.stamp!.fontColor,
        logoBase64:
          param.stamp?.logoBase64?.replace(/^data:image\/[a-z]+;base64,/, "") ||
          null,
      } as Api.TabStamp
    );
    if (CacheLXApp.DocuviewareMain?.DocuviewareInit?.isTOC) {
      setPageState((cur) => {
        return {
          ...cur,
          oldCurrentPage: pageState.currentPage,
          oldCurrentPageId: pageState.PageObj?.id || "",
        };
      });
      setTimeout(() => {
        setDocuviewareMainState((cur) => {
          return {
            ...cur,
            DocuviewareInit: result.data,
          };
        });
      }, 200);
    } else {
      TrackingApi.apiLegalxtractGetTabDetailsGet().then(async (res) => {
        setDocuviewareMainState((current) => {
          return {
            ...current,
            DocuviewareInit: {
              ...current.DocuviewareInit,
              tabDetails: res.data,
              tabStampWeb: {
                addFrame: param.stamp?.addFrame || false,
                showStamp: param.showStamp || false,
                automaticCount: param.automaticCount || false,
                showFromTOC: param.showFromTOC || false,
                tocIndexPattern: param.tocIndexPattern || "",
                autoScale: param.stamp?.autoScale || false,
                logoBase64: param.stamp?.logoBase64 || null,
                title: param.stamp?.title || "",
                transparent: param.stamp?.transparent || 0,
                location: {
                  height: AppHelper.CmToInch(
                    param.stamp?.location?.height || 2
                  ),
                  width: AppHelper.CmToInch(param.stamp?.location?.width || 2),
                  marginRight: param.stamp?.location?.marginRight || 0,
                  marginTop: param.stamp?.location?.marginTop || 0,
                },
                fontColor: param.stamp?.fontColor || "#333",
                fontSize: param.stamp?.fontSize || 12,
                fontName: param.stamp?.fontName || "Arial",
                indexPattern: param.stamp?.index || "",
              } as Api.TabStampWeb,
            },
          };
        });
        setTabState({
          ...tab,
          selectedTab:
            res.data.find((o) => o.uniqueId == tab.selectedTab?.uniqueId) ||
            null,
        });
        documentService.RefreshPage(() => {
          DocuViewareAPI.UpdateLayout(
            docuviewareMain.DocuviewareInit?.docuViewareID
          );
          DocuViewareAPI.PostCustomServerAction(
            DataCache.docuViewareID(),
            false,
            "RedrawPages",
            "",
            () => {}
          );
        }, docuviewareMain.DocuviewareInit?.docuViewareID);
      });
    }
    if (leftSidebar.lastPanelSelected == TabIndex.TOCTab) {
      setLeftSidebarState((cur) => {
        return {
          ...cur,
          refreshTabActive: { IsRefresh: true, TabIndex: -1 },
        };
      });
    }
    setTopmenuStripState({
      ...topmenuStrip,
      dropdownList: {
        ...topmenuStrip.dropdownList,
        StampChecked: true,
      },
    });
  };
  const SaveOneStamp = async (param: Api.LxStamp) => {
    await StampApi.apiLegalxtractApplyOneStampPost(tab.selectedTab?.uniqueId, {
      ...param.stamp,
      logoBase64:
        param.stamp?.logoBase64?.replace(/^data:image\/[a-z]+;base64,/, "") ||
        null,
    });
    TrackingApi.apiLegalxtractGetTabDetailsGet().then(async (res) => {
      setDocuviewareMainState((current) => {
        return {
          ...current,
          DocuviewareInit: { ...current.DocuviewareInit, tabDetails: res.data },
        };
      });
      setTabState({
        ...tab,
        selectedTab:
          res.data.find((o) => o.uniqueId == tab.selectedTab?.uniqueId) || null,
      });
      if (leftSidebar.lastPanelSelected == TabIndex.TOCTab) {
        setLeftSidebarState((cur) => {
          return {
            ...cur,
            refreshTabActive: { IsRefresh: true, TabIndex: -1 },
          };
        });
      }
      documentService.RefreshPage(
        null,
        docuviewareMain.DocuviewareInit?.docuViewareID
      );
    });
  };
  const RemoveAllStamp = async () => {
    await StampApi.apiLegalxtractRemoveAllStampPost();
    TrackingApi.apiLegalxtractGetTabDetailsGet().then((res) => {
      setDocuviewareMainState((current) => {
        return {
          ...current,
          DocuviewareInit: { ...current.DocuviewareInit, tabDetails: res.data },
        };
      });
      setTabState({
        ...tab,
        selectedTab:
          res.data.find((o) => o.uniqueId == tab.selectedTab?.uniqueId) || null,
      });
      setTopmenuStripState({
        ...topmenuStrip,
        dropdownList: {
          ...topmenuStrip.dropdownList,
          StampChecked: false,
        },
      });
      documentService.RefreshPage(
        null,
        docuviewareMain.DocuviewareInit?.docuViewareID
      );
    });
  };
  const ProcessPageNumbering = async (param: Api.LxPageNumber) => {
    PageNumberingCommand(param, () => {
      setTopmenuStripState((cur) => {
        return {
          ...cur,
          dropdownList: {
            ...cur.dropdownList,
            PageNumberingChecked: param.showPageNumber || false,
          },
          mainPageNumber: param,
        };
      });
      setLeftSidebarState((cur) => {
        return { ...cur, refreshTabActive: { IsRefresh: true, TabIndex: -1 } };
      });
      documentService.RefreshPage(
        null,
        docuviewareMain.DocuviewareInit?.docuViewareID
      );
    });
  };
  return { SaveStamp, ProcessPageNumbering, SaveOneStamp, RemoveAllStamp };
};
export { topMenuStripService };
