import { atom } from "recoil";
import * as Api from "../ApiV2";
interface TOCSate {
  tocData: Api.TocTabInfo[];
  tocSelectedItem: Api.TocTabInfo[];
  tocFocusItem: Api.TocTabInfo | null;
  isShowDate: boolean;
  isShowAppendix: boolean;
}

export const TOCState = atom<TOCSate>({
  key: "TOCState",
  default: {
    tocData: [],
    tocSelectedItem: [],
    tocFocusItem: null,
    isShowDate: false,
    isShowAppendix: false,
  },
});
