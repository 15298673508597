import { atom } from "recoil";
import * as Api from "../ApiV2";

type ReplaceParamWeb = Api.ReplaceParamWeb & {
  onlyReplaceAll?: boolean;
  isReplaceFromSearch?: boolean;
  isReplaceFromWatchDog?: boolean;
  isReplaceTemplate?: boolean;
  isFocusWordHightLight?: boolean;
  cacheKey?: string | null;
};
interface IReplaceState {
  replacementParams: ReplaceParamWeb;
  replaceWords: Api.ReplaceObj[];
  isOpenCreateEditReplacement: boolean;
  editReplacement: Api.ReplaceObj | null;
  watchDogSelecteds: Api.WatchDogObj[];
  sort: string;
  replaceBoxParams: {
    isReplaceTemplate?: boolean;
    replaceBy?: string | null;
    fontName?: string | null;
    fontSize?: number | null;
    foreColor?: string | null;
    backColor?: string | null;
  };
}
const replaceState = atom<IReplaceState>({
  key: "replacementState",
  default: {
    replacementParams: {
      fontColor: "#e74c3c",
      extractColor: "#ffffff",
      fontName: "",
      fontSize: 0,
      isReplaceFromSearch: false,
      replaceBy: "",
    } as ReplaceParamWeb,
    replaceBoxParams: {
      replaceBy: "",
      fontName: "",
      fontSize: 0,
      foreColor: "#e74c3c",
      backColor: "#ffffff",
    },
    isOpenCreateEditReplacement: false,
    editReplacement: null,
    sort: "",
    replaceWords: [] as Api.ReplaceObj[],
  } as IReplaceState,
});
export type { IReplaceState, ReplaceParamWeb };
export { replaceState };
