import {
  Checkbox,
  DefaultButton,
  IStackTokens,
  Modal,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { v4 } from "uuid";
import { useLang } from "../i18n/useLang";

import { TextField } from "@fluentui/react";
import { useRecoilState } from "recoil";
import { ModalContent, StyledExample } from "../assets/style/modal/modal";
import ModalHeader from "../control/modalHeader";
import { searchState } from "../recoil";
import { CustomSearchTemplate } from "../recoil/searchState";
import * as Api from "../ApiV2";
const stackTokens: IStackTokens = { childrenGap: 10 };
const verticalStackTokens: IStackTokens = { childrenGap: 5 };

interface AddSearchCustomTemplateModalProps {
  isShow: boolean;
  editTemplate?: CustomSearchTemplate | null;
  onCloseModal: () => void;
  handleSaveCustomTemplates: (param: any) => void;
}

export default (props: AddSearchCustomTemplateModalProps) => {
  const { tr } = useLang();
  const [searchCurrentState] = useRecoilState(searchState);
  const [formData, setFormData] = useState<CustomSearchTemplate>({
    id: v4(),
    name: "",
    pattern: "",
    checked: true,
    isRegex: false,
  });
  const [patternInputErrorMessage, setPatternInputErrorMessage] = useState("");
  const [nameInputErrorMessage, setNameInputErrorMessage] = useState("");
  const onCloseModal = (): void => {
    props.onCloseModal();
  };

  useEffect(() => {
    if (props.editTemplate) {
      setFormData({
        id: props.editTemplate.id,
        pattern: props.editTemplate.pattern,
        name: props.editTemplate.name,
        checked: props.editTemplate.checked,
        isRegex: props.editTemplate.isRegex,
      });
    } else {
      setFormData({
        id: v4(),
        pattern: "",
        name: "",
        checked: true,
        isRegex: false,
      });
    }
  }, [props.isShow]);

  const onSubmit = async () => {
    if (!formData.pattern?.trim()) {
      setPatternInputErrorMessage(tr("pleaseSetPattern"));
      return;
    }
    if (!formData.name?.trim()) {
      setNameInputErrorMessage(tr("pleaseSetName"));
      return;
    }

    formData.pattern = formData.pattern?.trim();
    formData.name = formData.name.trim();
    if (
      searchCurrentState.searchingTemplateParams.customTemplateItems.find(
        (o) => o.pattern == formData.pattern && o.id != props.editTemplate?.id
      )
    ) {
      onCloseModal();
      return;
    }
    if (props.editTemplate) {
      props.handleSaveCustomTemplates(formData);
      onCloseModal();
      return;
    }
    props.handleSaveCustomTemplates(formData);
    onCloseModal();
  };

  return (
    <Modal isOpen={props.isShow} onDismiss={onCloseModal} isBlocking={true}>
      <div className="ms-Grid" dir="ltr">
        <ModalContent style={{ width: 550 }}>
          <Stack tokens={verticalStackTokens}>
            <ModalHeader
              title={props.editTemplate ? tr("template") : tr("template")}
              onCloseModal={onCloseModal}
            />
            <label>{tr("customSeachHelpsYouToFindVariblePatterns")}</label>
            <Stack tokens={stackTokens}>
              <TextField
                label={tr("insertPattern")}
                autoFocus={true}
                value={formData.pattern || ""}
                errorMessage={patternInputErrorMessage as any}
                onChange={(event, value) => {
                  formData.pattern = value ? value : "";
                  if (value && value.trim()) {
                    setPatternInputErrorMessage("");
                  }
                  setFormData({ ...formData });
                }}
              />
              <Checkbox
                label={tr("regexForComplexPattern")}
                checked={formData?.isRegex}
                onChange={(e, value) => {
                  setFormData({ ...formData, isRegex: value });
                }}
              />
              <strong>{tr("customPattern")}</strong>
              <div className="ms-Grid-row">
                <div
                  className="ms-Grid-col ms-md4"
                  style={{ textAlign: "center" }}
                >
                  <DefaultButton
                    style={{ width: "100%" }}
                    onClick={() => {
                      setFormData({
                        ...formData,
                        pattern: formData.pattern + "#",
                      });
                    }}
                  >
                    #
                  </DefaultButton>
                  <label>{tr("number")}</label>
                </div>
                <div
                  className="ms-Grid-col ms-md4"
                  style={{ textAlign: "center" }}
                >
                  <DefaultButton
                    style={{ width: "100%" }}
                    onClick={() => {
                      formData.pattern = formData.pattern + "&";
                      setFormData({ ...formData });
                    }}
                  >
                    &
                  </DefaultButton>
                  <label>{tr("letter")}</label>
                </div>
                <div
                  className="ms-Grid-col ms-md4"
                  style={{ textAlign: "center" }}
                >
                  <DefaultButton
                    style={{ width: "100%" }}
                    onClick={() => {
                      formData.pattern = formData.pattern + "%";
                      setFormData({ ...formData });
                    }}
                  >
                    %
                  </DefaultButton>
                  <label>{tr("symbol")}</label>
                </div>
              </div>
              <StyledExample>
                <div>{tr("example")}</div>
                <div style={{ display: "flex" }}>
                  <div>{tr("journalNo")}</div>
                  <div style={{ marginLeft: 40 }}>
                    <div>&nbsp;&nbsp;K2002-00140 </div>
                    <div>&nbsp;&nbsp;&####%#####</div>
                  </div>
                </div>
              </StyledExample>
              <TextField
                label={tr("namePattern")}
                value={formData.name || ""}
                maxLength={200}
                errorMessage={nameInputErrorMessage as any}
                onChange={(event, value) => {
                  formData.name = value ? value : "";
                  if (value && value.trim()) {
                    setPatternInputErrorMessage("");
                  }
                  setFormData({ ...formData });
                }}
              />
            </Stack>
            <Stack horizontal horizontalAlign="end" tokens={stackTokens}>
              <PrimaryButton
                text={tr("save")}
                onClick={onSubmit}
                allowDisabledFocus
              />
              <DefaultButton
                text={tr("close")}
                onClick={onCloseModal}
                allowDisabledFocus
              />
            </Stack>
          </Stack>
        </ModalContent>
      </div>
    </Modal>
  );
};
