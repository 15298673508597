import { Checkbox, DirectionalHint, Stack } from "@fluentui/react";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { Icon } from "@fluentui/react/lib/Icon";
import { Link } from "@fluentui/react/lib/Link";
import {
  ITooltipHostStyles,
  TooltipDelay,
  TooltipHost,
} from "@fluentui/react/lib/Tooltip";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  StyledCustomTemplateInputGroup,
  StyledCustomTemplateItem,
} from "../../../assets/style/left-sidebar/searchTab";
import { LocalStorageKey } from "../../../config/constant";
import { useLang } from "../../../i18n/useLang";
import {
  dialogState,
  modalContainerState,
  pageSelectedState,
  searchState,
} from "../../../recoil";
import { CustomSearchTemplate } from "../../../recoil/searchState";
import { documentService } from "../../../service/document";
import { TemplateSearchDefault } from "./templateSearchDefault";
// import { TemplateSearchBelgium } from "./templateSearchBelgium";
import { userService } from "../../../service/userService";
const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: "inline-block" },
};
interface ITemplateSearchProps {
  heightList: number;
}
export const TemplateSearch = (props: ITemplateSearchProps) => {
  const [searchCurrentState, setSearchState] = useRecoilState(searchState);
  const [modalContainer, setModalContainerState] =
    useRecoilState(modalContainerState);
  const [pageState] = useRecoilState(pageSelectedState);
  const [, setDialogState] = useRecoilState(dialogState);
  const { tr } = useLang();
  const { getUser } = userService();
  const handleCustomTemplateClicks = (template: any) => {
    setModalContainerState({ ...modalContainer, editCustomTemplate: template });
  };
  useEffect(() => {
    localStorage.setItem(
      LocalStorageKey.TemplateSearch,
      JSON.stringify(searchCurrentState.searchingTemplateParams)
    );
    setTimeout(() => {
      documentService.CalculateHeightSearchResult();
    }, 300);
  }, [searchCurrentState.searchingTemplateParams]);
  return (
    <>
      <div className="ms-Grid mg-top15" style={{ width: "100%" }} dir="ltr">
        {(getUser()?.userDataLogin?.result?.user_info?.countryCode ===
          "BEL" && (
          <TemplateSearchDefault
            heightList={props.heightList}
          ></TemplateSearchDefault>
        )) || (
          <TemplateSearchDefault
            heightList={props.heightList}
          ></TemplateSearchDefault>
        )}
      </div>
    </>
  );
};
