import Cookies from "js-cookie";
import { Constants, LocalStorageKey } from "./../config/constant";
import globalAxios from "axios";
import { Utility } from "../config/utility";
import { userService } from "../service/userService";
const globalAxiosConfig = () => {
  const { getUser } = userService();
  const requestHandler = (request: any) => {
    // @ts-ignore
    request.headers["TimezoneOffset"] = new Date().getTimezoneOffset() / -1;
    request.headers["IdentifiedKey"] = localStorage.IdentifiedKey?.toString();
    const TokenNovaLegalXtract = Cookies.get(Constants.TokenThirdParty);
    // @ts-ignore
    request.headers["TokenThirdParty"] = TokenNovaLegalXtract || "";
    // @ts-ignore
    const user = getUser();
    if (user) {
      request.headers["Authorization"] = "Bearer " + user.token;
      request.headers["TokenLegalManager"] =
        user.userDataLogin?.result?.access_token;
      request.headers["LicenseKey"] =
        user.userDataLogin?.result?.user_info?.licenseKey;
      request.headers["UserInfo"] = encodeURI(
        JSON.stringify({
          email: user.userDataLogin?.result?.user_info?.email || "",
          userName: user.userDataLogin?.result?.user_info?.fullName || "",
          countryCode: user.userDataLogin?.result?.user_info?.countryCode || "",
        })
      );
    }
    return request;
  };
  const responseHandler = (response: any) => {
    return response;
  };
  globalAxios.defaults.baseURL = Utility.baseUrl();
  globalAxios.interceptors.request.use((request) => requestHandler(request));
  globalAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (err) => {
      console.log(err);
      if (err.response.status === 401) {
        window.location.href = "/login";
      }
      return err;
    }
  );
};

export default globalAxiosConfig;
