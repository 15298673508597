import { defaultmainPageNumber } from "./../recoil/topmenuStripState";
import { LocalStorageKey } from "./../config/constant";
import * as Api from "../ApiV2";
import undoManager from "./undo-manager";
const PageNumberingApi = new Api.PageNumberingApi();
const PageNumberingCommand = (param: Api.LxPageNumber, callback: any) => {
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, "true");
  let paramUndo = defaultmainPageNumber;
  const PageNumbering = async (param: Api.LxPageNumber) => {
    await PageNumberingApi.apiLegalxtractProcessPageNumberingPost(param);
    const userConfigApp = LocalStorageKey.GetOjectItem<Api.UserConfigApp>(
      LocalStorageKey.UserConfigApp
    );
    LocalStorageKey.SetItem(
      { ...userConfigApp, mainPageNumber: param } as Api.UserConfigApp,
      LocalStorageKey.UserConfigApp
    );
    if (param) {
      paramUndo = JSON.parse(JSON.stringify(param));
      paramUndo.showPageNumber = !param.showPageNumber;
    }
    if (callback) {
      callback();
    }
  };
  PageNumbering(param);
  undoManager.add({
    undo: function () {
      PageNumbering(paramUndo);
    },
    redo: function () {
      PageNumbering(param);
    },
  });
};
export default PageNumberingCommand;
