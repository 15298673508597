import { CacheLXApp } from "../App";
import * as Api from "../ApiV2";
import { DataCache } from "../config/constant";
import { CheckVisibleElement } from "./helpers";
import { number } from "yup";
export const PageHelper = {
  getNextLineItemWord: () => {
    const words = CacheLXApp.pageDetails[0].words;
    if (words && words.length > 0) {
      const word = words.find(
        (x) => x.logicLineId === (CacheLXApp.FocusWord?.logicLineId || 0) + 1
      );
      return word;
    }
    return null;
  },
  getPreLineItemWord: () => {
    const words = CacheLXApp.pageDetails[0].words;
    if (words && words.length > 0) {
      const word = words.find(
        (x) => x.logicLineId === (CacheLXApp.FocusWord?.logicLineId || 0) - 1
      );
      return word;
    }
    return null;
  },
  getNextItemWord: () => {
    const words = CacheLXApp.pageDetails[0].words;
    if (words && words.length > 0) {
      const index = words.findIndex((x) => x.id === CacheLXApp.FocusWord?.id);
      if (index >= 0 && index + 1 < words.length) {
        return words[index + 1];
      }
    }
    return null;
  },
  getPreItemWord: () => {
    const words = CacheLXApp.pageDetails[0].words;
    if (words && words.length > 0) {
      const index = words.findIndex((x) => x.id === CacheLXApp.FocusWord?.id);
      if (index >= 0 && index - 1 >= 0) {
        return words[index - 1];
      }
    }
    return null;
  },
  getFirstItemWord: () => {
    const words = CacheLXApp.pageDetails[0].words;
    if (words && words.length > 0) {
      return words[0];
    }
    return null;
  },
  getLastItemWord: () => {
    const words = CacheLXApp.pageDetails[0].words;
    if (words && words.length > 0) {
      return words[words.length - 1];
    }
    return null;
  },
  isLastItem: (item: Api.LxWordResponse | null) => {
    const words = CacheLXApp.pageDetails[0].words;
    if (words && words.length > 0) {
      const lastItem = words[words.length - 1];
      return item === lastItem;
    }
    return false;
  },
  isFirstItem: (item: Api.LxWordResponse | null) => {
    const words = CacheLXApp.pageDetails[0].words;
    if (words && words.length > 0) {
      const firstItem = words[0];
      return item === firstItem;
    }
    return false;
  },
  setPageState: (state: any, callback: () => void) => {
    CacheLXApp.PageState = { ...CacheLXApp.PageState, ...state };
    callback && callback();
  },
  drawFocusWord: (word: Api.LxWordResponse | null, pageNo: number) => {
    if (word) {
      let e: any;
      const root = DocuViewareAPI.Root(DataCache.docuViewareID());
      if (!root) return;
      root.Internal.Text.clearSelection();
      const dom = root.dom;
      PageHelper.RemoveSelectedTextRegionByColor(
        dom.SelectedTextRegions._cache,
        dom,
        "rgba(0, 0, 0, 0.3)"
      );

      if (word.rects) {
        e = {
          left:
            ((word.rects[0].left || 0) * 72) /
            (CacheLXApp.DataMouseMoveInView?.scale || 0.75),
          top:
            ((word.rects[0].top || 0) * 72) /
            (CacheLXApp.DataMouseMoveInView?.scale || 0.75),
          width:
            ((word.rects[0].width || 0) * 72) /
            (CacheLXApp.DataMouseMoveInView?.scale || 0.75),
          height:
            ((word.rects[0].height || 0) * 72) /
            (CacheLXApp.DataMouseMoveInView?.scale || 0.75),
        };
      }
      if (word.rects && word.rects.length > 0) {
        DocuViewareAPI.Root(
          DataCache.docuViewareID()
        ).dom.SelectedTextRegions.addNew(
          pageNo,
          e,
          "rgba(0, 0, 0, 0.3)",
          null,
          1,
          null
        );
        const container = document.getElementById(`DocuVieware_Main`);
        if (
          !CheckVisibleElement(
            root.dom.SelectedTextRegions._cache[0],
            container
          )
        ) {
          const viewerDocuVieware_Main = document.getElementById(
            "viewerDocuVieware_Main"
          );
          const el = root.dom.SelectedTextRegions._cache[0];
          if (viewerDocuVieware_Main) {
            const el = root.dom.SelectedTextRegions._cache[0];
            viewerDocuVieware_Main.scroll(
              viewerDocuVieware_Main.scrollLeft,
              el.offsetTop - viewerDocuVieware_Main.offsetHeight / 2
            );
          }
        }
      }
      return;
    }
  },
  RemoveSelectedTextRegionByColor: (
    listRegion: any[],
    dom: any,
    colorName: string,
    isAll = false
  ) => {
    if (!listRegion || listRegion?.length == 0) return;
    const arr = [...listRegion];
    if (isAll == true) {
      dom.SelectedTextRegions.clear();
    }
    if (arr) {
      const region = arr.filter((o) => {
        return o.outerHTML.indexOf(colorName) > 0;
      });
      if (region) {
        region.forEach((element) => {
          const index = arr.indexOf(element);
          listRegion.splice(index, 1);
          if (element.parentNode) {
            element.parentNode.removeChild(element);
          }
        });
      }
    }
  },
  calculateCurrentpage: (): number => {
    let oldCurrentPage = CacheLXApp.PageState?.oldCurrentPage || 0;
    const pageObj = CacheLXApp.DocuviewareMain?.DocuviewareInit?.pages?.find(
      (o) => o.id == CacheLXApp.PageState?.oldCurrentPageId
    );
    if (pageObj) {
      return pageObj.viewerPageNo || 0;
    } else {
      if (
        oldCurrentPage >
        (CacheLXApp.DocuviewareMain?.DocuviewareInit?.totalPage || 0)
      ) {
        const lastItem =
          CacheLXApp.DocuviewareMain?.DocuviewareInit?.tabDetails?.slice(-1)[0];
        oldCurrentPage = lastItem?.startPage || 1;
        return oldCurrentPage;
      } else {
        oldCurrentPage--;
      }
    }
    return oldCurrentPage < 0 ? 0 : oldCurrentPage;
  },
 IsCharFocus(x: Api.LxChar, posX: any, posY: any) {
    let result = false;
    if (x.rect) {
      const x1 = x.rect;
      result =
        (x1?.left || 0) <= posX &&
        (x1.left || 0) + (x1.width || 0) > posX &&
        (x1.top || 0) <= posY &&
        (x1.top || 0) + (x1.height || 0) >= posY;
      return result;
    }
    return result;
  }
};
