import * as Api from '../ApiV2';
import { LocalStorageKey } from '../config/constant';
import { annotationService } from '../service/annotationService';
import undoManager from './undo-manager';
import { UndoHightLight } from './undoHightLight';
import { CacheLXApp } from "../App";
const TrackingApi = new Api.TrackingApi();
const AddReplaceCommand = (
  docuViewareID: string | null | undefined,
  paramAddReplace: Api.ReplaceParamWeb | null,
  callback: any,
) => {
  let ParamUndo = '';
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, 'true');
  const AddReplace = (paramAddReplace: Api.ReplaceParamWeb | null) => {
    if (!paramAddReplace) return;
    const dataRequest = {
      Data: JSON.stringify(paramAddReplace),
      Type: 'AddReplaceCommand',
    };
    annotationService.AddAnnotations(docuViewareID, dataRequest, async (res: any) => {
      ParamUndo = res;
      CacheLXApp.pageDetails = [
        (
          await TrackingApi.apiLegalxtractGetWordByPageNoGet(
            CacheLXApp.PageState?.currentPage || 0
          )
        ).data,
      ];
      if (callback) {
        callback();
      }
    });
  };
  const RemoveReplace = () => {
    UndoHightLight(docuViewareID, ParamUndo, callback);
  };
  if (paramAddReplace) {
    AddReplace(paramAddReplace);
    undoManager.add({
      undo: function () {
        RemoveReplace();
      },
      redo: function () {
        AddReplace(paramAddReplace);
      },
    });
  }
};
const AddReplaceAllSearchResultCommand = (
  docuViewareID: string | null | undefined,
  paramAddReplaceAllSearchResult: Api.ReplaceParamWeb | null,
  callback: any,
) => {
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, 'true');
  let ParamUndo = '';
  const AddReplaceAllSearchResult = (paramAddReplaceAllSearchResult: any | null) => {
    if (!paramAddReplaceAllSearchResult) return;
    const dataRequest = {
      Data: JSON.stringify(paramAddReplaceAllSearchResult),
      Type: 'AddReplaceAllSearchResultCommand',
    };
    annotationService.AddAnnotations(docuViewareID, dataRequest, async (res: any) => {
      ParamUndo = JSON.stringify(JSON.parse(res).item2);
      CacheLXApp.pageDetails = [
        (
          await TrackingApi.apiLegalxtractGetWordByPageNoGet(
            CacheLXApp.PageState?.currentPage || 0
          )
        ).data,
      ];
      if (callback) {
        callback();
      }
    });
  };
  const RemoveReplaceFormSearch = () => {
    UndoHightLight(docuViewareID, ParamUndo, callback);
  };
  AddReplaceAllSearchResult(paramAddReplaceAllSearchResult);
  undoManager.add({
    undo: function () {
      RemoveReplaceFormSearch();
    },
    redo: function () {
      AddReplaceAllSearchResult(paramAddReplaceAllSearchResult);
    },
  });
};
const AddReplaceAllItemSelectedCommand = (
  docuViewareID: string | null | undefined,
  paramAddReplaceAllItemSelecteds: Api.ReplaceParamWeb | null,
  callback: any,
) => {
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, 'true');
  let ParamUndo = '';
  const AddReplaceAllItemSelecteds = (paramAddReplaceAllItemSelecteds: any | null) => {
    if (!paramAddReplaceAllItemSelecteds) return;
    const dataRequest = {
      Data: JSON.stringify(paramAddReplaceAllItemSelecteds),
      Type: 'AddReplaceAllItemSelectedsCommand',
    };
    annotationService.AddAnnotations(docuViewareID, dataRequest, async (res: any) => {
      ParamUndo = JSON.stringify(JSON.parse(res).item2);
      CacheLXApp.pageDetails = [
        (
          await TrackingApi.apiLegalxtractGetWordByPageNoGet(
            CacheLXApp.PageState?.currentPage || 0
          )
        ).data,
      ];
      if (callback) {
        callback();
      }
    });
  };
  const RemoveReplaceFormSearch = () => {
    UndoHightLight(docuViewareID, ParamUndo, callback);
  };

  if (paramAddReplaceAllItemSelecteds) {
    AddReplaceAllItemSelecteds(paramAddReplaceAllItemSelecteds);
    undoManager.add({
      undo: function () {
        RemoveReplaceFormSearch();
      },
      redo: function () {
        AddReplaceAllItemSelecteds(paramAddReplaceAllItemSelecteds);
      },
    });
  }
};
const AddReplaceItemFromWatchDogCommand = (  docuViewareID: string | null | undefined,
  addReplaceItemFromWatchDogParam: Api.AddReplaceItemFromWatchDogParam | null,
  callback: any,) => {
    localStorage.setItem(LocalStorageKey.DocumentHasChanged, 'true');
    let ParamUndo = '';
    const AddReplaceAllItemSelecteds = (addReplaceItemFromWatchDogParam: Api.AddReplaceItemFromWatchDogParam | null) => {
      if (!addReplaceItemFromWatchDogParam) return;
      const dataRequest = {
        Data: JSON.stringify(addReplaceItemFromWatchDogParam),
        Type: 'AddReplaceItemFromWatchDogCommand',
      };
      annotationService.AddAnnotations(docuViewareID, dataRequest, async (res: any) => {
        ParamUndo = JSON.stringify(JSON.parse(res).item2);
        CacheLXApp.pageDetails = [
          (
            await TrackingApi.apiLegalxtractGetWordByPageNoGet(
              CacheLXApp.PageState?.currentPage || 0
            )
          ).data,
        ];
        if (callback) {
          callback();
        }
      });
    };
    const UndoAddReplaceItemFromWatchDog = () => {
      UndoHightLight(docuViewareID, ParamUndo, callback);
    };
  
    if (addReplaceItemFromWatchDogParam) {
      AddReplaceAllItemSelecteds(addReplaceItemFromWatchDogParam);
      undoManager.add({
        undo: function () {
          UndoAddReplaceItemFromWatchDog();
        },
        redo: function () {
          AddReplaceAllItemSelecteds(addReplaceItemFromWatchDogParam);
        },
      });
    }
}
const ReplaceAllItemFromWatchdogCommand = (  docuViewareID: string | null | undefined,
  addReplaceItemFromWatchDogParam: Api.ReplaceAllItemFromWatchdogParam | null,
  callback: any,) => {
    localStorage.setItem(LocalStorageKey.DocumentHasChanged, 'true');
    let ParamUndo = '';
    const AddReplaceAllItemSelecteds = (addReplaceItemFromWatchDogParam: Api.AddReplaceItemFromWatchDogParam | null) => {
      if (!addReplaceItemFromWatchDogParam) return;
      const dataRequest = {
        Data: JSON.stringify(addReplaceItemFromWatchDogParam),
        Type: 'ReplaceAllItemFromWatchdogCommand',
      };
      annotationService.AddAnnotations(docuViewareID, dataRequest, async (res: any) => {
        ParamUndo = JSON.stringify(JSON.parse(res).item2);
        CacheLXApp.pageDetails = [
          (
            await TrackingApi.apiLegalxtractGetWordByPageNoGet(
              CacheLXApp.PageState?.currentPage || 0
            )
          ).data,
        ];
        if (callback) {
          callback();
        }
      });
    };
    const UndoAddReplaceItemFromWatchDog = () => {
      UndoHightLight(docuViewareID, ParamUndo, callback);
    };
  
    if (addReplaceItemFromWatchDogParam) {
      AddReplaceAllItemSelecteds(addReplaceItemFromWatchDogParam);
      undoManager.add({
        undo: function () {
          UndoAddReplaceItemFromWatchDog();
        },
        redo: function () {
          AddReplaceAllItemSelecteds(addReplaceItemFromWatchDogParam);
        },
      });
    }
}
export { AddReplaceAllItemSelectedCommand, AddReplaceAllSearchResultCommand, AddReplaceCommand, AddReplaceItemFromWatchDogCommand, ReplaceAllItemFromWatchdogCommand };
