import React from "react";
import { Stack, IconButton, Button, BaseButton } from "@fluentui/react";

type ModalHeaderProps = {
  title: string;
  hiddenClose?: boolean;
  showMax?: boolean;
  onCloseModal?: (
    ev?: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement,
      MouseEvent
    >
  ) => void;
  onMaxModal?: () => void;
};

export default (props: ModalHeaderProps) => (
  <Stack horizontalAlign="space-between" horizontal>
    <h3 style={{ margin: 0, color: "#333", fontSize: 18 }}>{props.title}</h3>
    <div>
      {props.showMax && (
        <IconButton
          style={{ color: "black" }}
          iconProps={{ iconName: "Favicon" }}
          hidden={props.hiddenClose || false}
          ariaLabel="Close popup modal"
          onMouseDown={props.onMaxModal}
        />
      )}

      <IconButton
        style={{
          color: "black",
          display: props.hiddenClose ? "none" : "inline-block",
        }}
        iconProps={{ iconName: "Cancel" }}
        hidden={props.hiddenClose || false}
        ariaLabel="Close popup modal"
        onMouseDown={(ev) => {
          props.onCloseModal && props.onCloseModal(ev);
        }}
        onMouseUp={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      />
    </div>
  </Stack>
);
