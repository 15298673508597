import * as Api from "../ApiV2";
import { LocalStorageKey } from "../config/constant";
import {
  defaultmainPageNumber,
  defaultStampData,
} from "../recoil/topmenuStripState";
export const userService = () => {
  const getUser = (): Api.LoginResponse | null => {
    const userInfoCache = localStorage.getItem(LocalStorageKey.UserInfo);
    let userinfo: Api.LoginResponse | null = null;
    if (userInfoCache) {
      userinfo = JSON.parse(userInfoCache) as Api.LoginResponse;
    }
    return userinfo;
  };
  const getAllOldSetting = () => {
    const userConfigApp = LocalStorageKey.GetOjectItem<Api.UserConfigApp>(
      LocalStorageKey.UserConfigApp
    );
    if (userConfigApp) return null;
    const lxSetting = LocalStorageKey.GetOjectItem<Api.LxSetting>(
      LocalStorageKey.LxSetting
    );
    const stamp = LocalStorageKey.GetOjectItem<StampInfo>(
      LocalStorageKey.StampInfo
    );
    const pagenumbering = LocalStorageKey.GetOjectItem<PageNumbering>(
      LocalStorageKey.PageNumbering
    );
    let LxStamp: Api.LxStamp = defaultStampData;
    if (stamp)
      LxStamp = {
        automaticCount: stamp?.automaticCount || false,
        showFromTOC: stamp?.showFromTOC || false,
        showStamp: stamp?.showStamp || false,
        stamp: {
          addFrame: stamp?.addFrame || false,
          fontColor: stamp?.fontColor || "",
          fontName: stamp?.fontName || "",
          fontSize: stamp?.fontSize || 0,
          index: stamp?.index || "",
          location: stamp?.location || defaultStampData.stamp?.location,
          logoBase64: stamp?.logoBase64 || null,
          title: stamp?.title || "",
          transparent: stamp?.transparent || 0,
        },
        tocIndexPattern: "1",
      };
    let LxPageNumbering: Api.LxPageNumber = defaultmainPageNumber;
    if (pagenumbering) {
      LxPageNumbering = {
        showPageNumber: pagenumbering.included,
        showFromFrontPage: pagenumbering.showFromFrontPage,
        showFromTOC: pagenumbering.showFromTOC,
        pageNumber: {
          fontSize: pagenumbering.fontSize,
          margin: pagenumbering.margin,
          marginSide: pagenumbering.marginSide,
          position: pagenumbering.position,
          fontName: pagenumbering.fontName,
          backColor: pagenumbering.backColor,
          fontColor: pagenumbering.fontColor,
          addFrame: pagenumbering.addFrame,
        },
      };
    }
    return {
      LxSetting: lxSetting,
      LxStamp: LxStamp,
      LxPageNumber: LxPageNumbering,
    };
  };

  return { getUser, getAllOldSetting };
};
export interface StampInfo {
  title: string;
  index: string;
  addFrame: boolean;
  fontName: string;
  fontSize: number;
  fontColor: string;
  logoBase64: any;
  transparent: number;
  location: {
    marginRight: number;
    marginTop: number;
    width: number;
    height: number;
  };
  showStamp: boolean;
  automaticCount: boolean;
  autoScale: boolean;
  showFromTOC: boolean;
}

export interface PageNumbering {
  fontSize: number;
  included: boolean;
  margin: number;
  marginSide: number;
  position: number;
  fontName: string;
  showFromFrontPage: boolean;
  showFromTOC: boolean;
  addFrame: boolean;
  backColor: string;
  fontColor: string;
}
