import {
  DefaultButton,
  IStackTokens,
  Modal,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { TextField } from "@fluentui/react/lib/TextField";
import * as React from "react";
import { useRecoilState } from "recoil";
import * as ApiV2 from "../ApiV2";
import { ModalContent } from "../assets/style/modal/modal";
import ModalHeader from "../control/modalHeader";
import { useLang } from "../i18n/useLang";
import { docuviewareMainState, leftSidebarState, pageSelectedState, trackingState } from "../recoil";
import { CacheLXApp } from "../App";
const SplitDocumentApi = new ApiV2.SplitDocumentApi();

const stackTokens: IStackTokens = { childrenGap: 10 };
const verticalStackTokens: IStackTokens = { childrenGap: 5 };

interface SplitDocumentModalProps {
  isShow: boolean;
  tabName: string;
  pageNo: number;
  onClose: () => void;
}

export const SplitDocumentModal = (props: SplitDocumentModalProps) => {
  const [, setPageState] = useRecoilState(pageSelectedState);
  const [tracking, setTrackingState] = useRecoilState(trackingState);
  const [docuviewareMainCurrentState, setDocuviewareMain] =
    useRecoilState(docuviewareMainState);
  const [, setLeftSidebarState] = useRecoilState(leftSidebarState);
  const { tr } = useLang();
  const GetSplitFileName = (splitTabFileName: string) => {
    let i = 2;
    while (
      docuviewareMainCurrentState.DocuviewareInit?.tabDetails?.find(
        (x) => x.fileName == `${splitTabFileName} (${i})`
      )
    ) {
      i++;
    }
    return `${splitTabFileName} (${i})`;
  };
  const [name, setName] = React.useState(GetSplitFileName(props.tabName));
  const onSubmit = async () => {
    setPageState((cur) => {
      CacheLXApp.PageState = {
        ...cur,
        oldCurrentPage: cur.currentPage,
        oldCurrentPageId: cur.PageObj?.id || "",
      };
      return { ...cur, oldCurrentPage: cur.currentPage, oldCurrentPageId: cur.PageObj?.id || "" }
    });
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: true,
        label: ``,
      },
    });
    const data = await SplitDocumentApi.apiLegalxtractSplitDocumentGet(
      props.pageNo,
      name
    );
    setDocuviewareMain((current) => {
      return {
        ...current,
        DocuviewareInit: data.data,
      };
    });
    setLeftSidebarState((cur) => {
      return { ...cur, refreshTabActive: { IsRefresh: true, TabIndex: -1 } };
    });
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: false,
        label: ``,
      },
    });
    onCloseModal();
  };
  const onCloseModal = () => {
    props.onClose();
  };
  return (
    <Modal isOpen={props.isShow} onDismiss={onCloseModal} isBlocking={true}>
      <ModalContent style={{ width: 550 }}>
        <div className="ms-Grid" dir="ltr">
          <Stack tokens={verticalStackTokens}>
            <ModalHeader
              title={tr("enterANewFileName")}
              onCloseModal={onCloseModal}
            />
            <TextField
              label={tr("filename")}
              value={name as any}
              autoFocus={true}
              onChange={(e: any) => setName(e.target.value)}
            />
            <Stack horizontal horizontalAlign="end" tokens={stackTokens}>
              <DefaultButton
                text={tr("cancel")}
                onClick={onCloseModal}
                allowDisabledFocus
              />
              <PrimaryButton
                text={tr("save")}
                onClick={onSubmit}
                allowDisabledFocus
              />
            </Stack>
          </Stack>
        </div>
      </ModalContent>
    </Modal>
  );
};
