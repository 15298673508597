import { atom } from "recoil";
import * as Api from "../ApiV2";
import { type } from "os";
export enum OnSelectedFileHandlers {
  UPLOAD_FRONT_PAGE = 0,
  OPEN_FILES = 1,
  ADD_FILES = 2,
}
export class DropdownListProps {
  BlankPageChecked = false;
  OCRChecked = false;
  OptiizedForTextChecked = true;
  TOCChecked = false;
  StampChecked = false;
  AutoFlowChecked = false;
  nameOfClickedMenuItem = "";
  onSelectedFileHandleKey: OnSelectedFileHandlers =
    OnSelectedFileHandlers.ADD_FILES;
  FrontPageChecked = false;
  IsUploadFrontPage = false;
  PageNumberingChecked = false;
  displayTagOptions = false;
}

export const defaultStampData: Api.LxStamp = {
  stamp: {
    title: "",
    transparent: 50,
    autoScale: false,
    fontName: "Microsoft Sans Serif",
    fontSize: 25,
    index: "",
    logoBase64: null,
    fontColor: "#333",
    addFrame: true,
    location: {
      width: 2,
      height: 2,
      marginRight: 0.1,
      marginTop: 0.1,
    },
  },
  automaticCount: true,
  showFromTOC: false,
  showStamp: true,
  tocIndexPattern: "",
};
export const defaultmainPageNumber: Api.LxPageNumber = {
  pageNumber: {
    fontSize: 12,
    margin: 2,
    marginSide: 2,
    position: 0,
    fontName: "Arial",
    addFrame: true,
    backColor: "#fff",
    fontColor: "#000",
  },
  showFromFrontPage: false,
  showFromTOC: false,
  showPageNumber: false,
};
interface ITopmenuStripState {
  dropdownList: DropdownListProps;
  mainPageNumber: Api.LxPageNumber;
}
const topmenuStripState = atom({
  key: "topmenuStripState",
  default: {
    dropdownList: new DropdownListProps(),
    mainPageNumber: defaultmainPageNumber,
  } as ITopmenuStripState,
});
export { topmenuStripState };
