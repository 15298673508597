import { useFormik } from "formik";
import * as yup from "yup";
import * as Api from "../../../ApiV2";
import { useEffect, useState } from "react";
import { v4 } from "uuid";
import {
  CompanyCode,
  Constants,
  DataCache,
  LocalStorageKey,
} from "../../../config/constant";
import globalAxiosConfig from "../../../ApiV2/axiosConfig";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
const LoginApi = new Api.LoginApi();
const validationUserSchema = yup.object({
  userName: yup.string().required("User name is a required field"),
  passWord: yup.string().required("Password is a required field"),
});

const defaultUserObj = {
  userName: "",
  passWord: "",
};
export const LoginService = () => {
  const navigate = useNavigate();
  const [msgState, SetMsgState] = useState({
    Msg: "",
    show: false,
  });
  const formikLogin = useFormik({
    initialValues: defaultUserObj,
    validationSchema: validationUserSchema,
    onSubmit: async (values) => {
      const res = await LoginApi.apiLegalxtractLoginAppPost(values);
      if (res && res.data.userDataLogin?.errorMessage) {
        SetMsgState((cur) => {
          return {
            ...cur,
            Msg: res?.data?.userDataLogin?.errorMessage || "",
            show: true,
          };
        });
      } else {
        localStorage.setItem(
          LocalStorageKey.UserInfo,
          JSON.stringify(res.data)
        );
        localStorage.setItem(
          LocalStorageKey.IdentifiedKey,
          v4()
        );
        navigate("/");
      }
    },
  });
  const Logout = () => {
    localStorage.removeItem(LocalStorageKey.UserInfo);
    localStorage.removeItem(LocalStorageKey.UserConfigApp);
    if (DataCache.REACT_APP_CompanyCode != CompanyCode.Nova) {
      Cookies.remove(Constants.TokenThirdParty);
      Cookies.remove(Constants.RequestId);
    }
  };
  useEffect(() => {
    if (DataCache.REACT_APP_CompanyCode == CompanyCode.Nova) {
      navigate("/");
    }
    globalAxiosConfig();
    Logout();
  }, []);
  const handleSnackbarClose = () => {
    SetMsgState((cur) => {
      return { ...cur, Msg: "", show: false };
    });
  };
  return { formikLogin, handleSnackbarClose, msgState };
};
