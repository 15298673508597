import { CacheLXApp } from "../App";

export const annotationService = {
  AddAnnotations: (docuViewareID: string | null | undefined, param: any, callback: any) => {
    const root = DocuViewareAPI.Root(docuViewareID);
    localStorage.getItem('');
    root.AddAnnotCustom(
      param,
      (res: any) => {
        if (callback) {
          callback(res, param);
        }
        // clear annotation được chọn
        CacheLXApp.AnnotState = {
          ...CacheLXApp.AnnotState,
          AnnotationId: "",
          Data: "",
          TagType: "",
          ObjId: "",
          ClientTag: "",
          Subject: "",
          author: "",
          creationTime: "",
        };
      },
      null,
      null,
    );
  },
};
