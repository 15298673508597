import { atom } from "recoil";
import * as Api from "../ApiV2";
interface BookMarkState {
  ShowBookMarkModal: "edit" | "add" | "";
  itemSelecteds: Api.BookmarkInfo[];
  newBookmark: Api.BookmarkObj | null;
  ListBookmark: Api.BookmarkInfo[];
}

export const bookMarkState = atom<BookMarkState>({
  key: "bookMarkState",
  default: {
    ShowBookMarkModal: "",
    itemSelecteds: [],
    newBookmark: null,
    ListBookmark: [],
  },
});
