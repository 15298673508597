import { annotationService } from "../service/annotationService";
import { LocalStorageKey } from "../config/constant";
import * as Api from "../ApiV2";
import { CacheLXApp } from "../App";
const TrackingApi = new Api.TrackingApi();
export const UndoHightLight = (
  docuViewareID: string | null | undefined,
  param: string,
  callback: any
) => {
  const dataRequest = {
    Data: JSON.stringify(param),
    Type: "UndoHightLight",
  };
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, "true");
  annotationService.AddAnnotations(docuViewareID, dataRequest, async () => {
    CacheLXApp.pageDetails = [
      (
        await TrackingApi.apiLegalxtractGetWordByPageNoGet(
          CacheLXApp.PageState?.currentPage || 0
        )
      ).data,
    ];
    if (callback) {
      callback();
    }
  });
};
 