import * as Api from "../ApiV2";
import { LocalStorageKey } from "../config/constant";
import undoManager from "./undo-manager";
const TocApi = new Api.TocApi();
const ReorderApi = new Api.ReorderApi();
const ReOrderFromTocCommand = (
  olddicTabIds: { [key: string]: string } | null,
  oldOrderTabIds: string[],
  newOrderTabIds: string[],
  newdicTabIds: { [key: string]: string } | null,
  callback: any,
  beforeAction: () => void
) => {
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, "true");
  const reOrderToc = async (
    newOrderTabIds: string[],
    newdicTabIds: { [key: string]: string } | null
  ) => {
    beforeAction();
    TocApi.apiLegalxtractReOrderTOCPost({
      tocIds: newdicTabIds,
      newOrderIds: newOrderTabIds,
    }).then((res) => {
      const data = res.data;
      callback(data);
    });
  };
  reOrderToc(newOrderTabIds, newdicTabIds);
  undoManager.add({
    undo: function () {
      reOrderToc(oldOrderTabIds, olddicTabIds);
    },
    redo: function () {
      reOrderToc(newOrderTabIds, newdicTabIds);
    },
  });
};
export default ReOrderFromTocCommand;
