import * as Api from "../ApiV2";
import { LocalStorageKey } from "../config/constant";
import undoManager from "./undo-manager";
const BookMarkApi = new Api.BookMarkApi();
const ReOrderFromBookmarkCommand = (
  oldBookmarkInfos: Api.BookmarkInfo[],
  newBookmarkInfos: Api.BookmarkInfo[],
  callback: any,
  beforeAction: () => void
) => {
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, "true");
  const reOrderBookmark = async (newBookmarkInfos: Api.BookmarkInfo[]) => {
    beforeAction();
    BookMarkApi.apiLegalxtractReorderBookmarkPost({
      newBookmarkInfos: newBookmarkInfos,
    }).then((res) => {
      const data = res.data;
      callback(data);
    });
  };
  reOrderBookmark(newBookmarkInfos);
  undoManager.add({
    undo: function () {
      reOrderBookmark(oldBookmarkInfos);
    },
    redo: function () {
      reOrderBookmark(newBookmarkInfos);
    },
  });
};
export default ReOrderFromBookmarkCommand;
