import * as Api from "../ApiV2";
import { LocalStorageKey } from "../config/constant";
import undoManager from "./undo-manager";
const BookMarkApi = new Api.BookMarkApi();
export const EditBookmarkCommand = (
  oldBookmarkObj: Api.BookmarkObj,
  newBookmarkObj: Api.BookmarkObj,
  callback: () => void,
  beforeAction: () => void
) => {
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, "true");
  const reOrderBookmark = async (newBookmarkObj: Api.BookmarkObj) => {
    beforeAction();
    BookMarkApi.apiLegalxtractUpdateBookMarkPut(newBookmarkObj).then((res) => {
      callback();
    });
  };
  reOrderBookmark(newBookmarkObj);
  undoManager.add({
    undo: function () {
      reOrderBookmark(oldBookmarkObj);
    },
    redo: function () {
      reOrderBookmark(newBookmarkObj);
    },
  });
};
export const AddBookmarkCommand = (
  newBookmarkInfo: Api.BookmarkInfo,
  callback: (page?: Api.Page[]) => void,
  beforeAction: () => void
) => {
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, "true");
  let newBookmark: Api.BookmarkObj | null = null;
  const AddBookmark = async (newBookmarkInfos: Api.BookmarkInfo[]) => {
    beforeAction();
    BookMarkApi.apiLegalxtractAddBookmarkToPagePost({
      bookmarkInfos: newBookmarkInfos
    }).then(
      (res) => {
        callback(res.data.pages || []);
        newBookmark = res.data?.bookmarkObjs![0] || null;
      }
    );
  };
  const deleteBookmark = async (newBookmarkObj: Api.BookmarkObj) => {
    beforeAction();
    BookMarkApi.apiLegalxtractDeleteBookMarkDelete(newBookmarkObj.id).then(
      (res) => {
        callback([res.data]);
      }
    );
  };
  AddBookmark([newBookmarkInfo]);
  undoManager.add({
    undo: function () {
      if (!newBookmark) return;
      deleteBookmark(newBookmark);
    },
    redo: function () {
      AddBookmark([newBookmarkInfo]);
    },
  });
};
export const DeleteBookmarkCommand = (
  bookmarkObj: Api.BookmarkObj,
  oldbookmarks: Api.BookmarkInfo[],
  callback: (pages?: Api.Page[]) => void,
  beforeAction: () => void
) => {
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, "true");
  const deleteBookmark = async (bookmarkObj: Api.BookmarkObj) => {
    beforeAction();
    BookMarkApi.apiLegalxtractDeleteBookMarkDelete(bookmarkObj.id).then(
      (res) => {
        callback([res.data]);
      }
    );
  };
  const AddBookmark = async (newBookmarkInfos: Api.BookmarkInfo[]) => {
    beforeAction();
    BookMarkApi.apiLegalxtractAddBookmarkToPagePost({
      bookmarkInfos: newBookmarkInfos,
    }).then((res) => {
      callback(res.data.pages || []);
    });
  };
  deleteBookmark(bookmarkObj);
  undoManager.add({
    undo: function () {
      if (!oldbookmarks) return;
      AddBookmark(oldbookmarks);
    },
    redo: function () {
      deleteBookmark(bookmarkObj);
    },
  });
};

export const ChangeBookmarkSettingCommand = (
  oldBookmarkSetting: Api.BookmarkSetting,
  newBookmarkSetting: Api.BookmarkSetting,
  callback: () => void,
  beforeAction: () => void
) => {
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, "true");
  const ChangeBookmarkSetting = async (
    bookmarkSetting: Api.BookmarkSetting
  ) => {
    beforeAction();
    BookMarkApi.apiLegalxtractSaveBookMarkSettingPost(bookmarkSetting).then(
      (res) => {
        callback();
      }
    );
  };

  ChangeBookmarkSetting(newBookmarkSetting);
  undoManager.add({
    undo: function () {
      ChangeBookmarkSetting(oldBookmarkSetting);
    },
    redo: function () {
      ChangeBookmarkSetting(newBookmarkSetting);
    },
  });
};
