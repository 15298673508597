import { atom } from "recoil";
import * as Api from "../ApiV2";
import { LocalStorageKey, SearchTypeKey } from "../config/constant";
import { userService } from "../service/userService";
import { v4 } from "uuid";
export interface SearchKeywordParamCustom extends Api.SearchKeywordParam {
  ForceSearchWhenInitialized?: boolean;
}
export interface CustomSearchTemplate extends Api.CustomTemplate {
  id: string;
  checked: boolean;
}
export interface TemplateItem extends Api.TemplateItem {
  isCustom: boolean;
  id: string;
}
interface TemplateItemMap {
  phoneNumber: TemplateItem;
  email: TemplateItem;
  diseases: TemplateItem;
  currency: TemplateItem;
  number: TemplateItem;
  webAddress: TemplateItem;
  assistiveProduct: TemplateItem;
  assistiveProductFuzzy: TemplateItem;
  belgianMunicipalities: TemplateItem;
  policeZones: TemplateItem;
  cvr: TemplateItem;
  creditCard: TemplateItem;
  cpr: TemplateItem;
  bsn: TemplateItem;
  nrn: TemplateItem;
  name: TemplateItem;
  nameAccuracy: TemplateItem;
  address: TemplateItem;
  customTemplate: TemplateItem;
  keyword: TemplateItem;
  tag: TemplateItem;
  none: TemplateItem;
  date: TemplateItem;
  beCompanyID: TemplateItem;
  beHandelsregisternummer: TemplateItem;
  beLicensePlates: TemplateItem;
  bePoliceReport: TemplateItem;
  medication: TemplateItem;
}
export interface SearchTemplateParam {
  version?: number;
  templateItems: TemplateItem[];
  customTemplateItems: CustomSearchTemplate[];
  templatesSelected?: TemplateItem[];
}

export interface ISearchState {
  searchingKeywordParams: SearchKeywordParamCustom;
  searchingTemplateParams: SearchTemplateParam;
  searchTabVm: Api.SearchTabVm;
  searchResult: Api.SearchResultData;
  searchType: string;
  hasMore: boolean;
  searchTagParams: any;
  showLoader: boolean;
  showOptionSearchKeyWord: boolean;
  showCancelLoader: boolean;
}
const GetSearchKeywordParam = () => {
  let obj = null;
  const value = localStorage.getItem(LocalStorageKey.KeywordSearch);
  if (value) {
    obj = JSON.parse(value);
  } else {
    obj = { ForceSearchWhenInitialized: false };
  }
  return obj as SearchKeywordParamCustom;
};
export const GetSearchTemplateParam = () => {
  let obj = null as SearchTemplateParam | null;
  const valueOld = localStorage.getItem("TemplateSearchNew");
  const value = localStorage.getItem(LocalStorageKey.TemplateSearch);
  const userConfigApp = LocalStorageKey.GetOjectItem<Api.UserConfigApp>(
    LocalStorageKey.UserConfigApp
  );
  let customTemplateItemsOld: CustomSearchTemplate[] = [];
  if (valueOld) {
    const objOld = JSON.parse(valueOld);
    if (objOld.customTemplateItems) {
      customTemplateItemsOld =
        objOld.customTemplateItems?.map((o: any) => {
          return {
            ...o,
            id: o.id,
            isRegex: o.isRegex,
            name: o.name,
            pattern: "##",
            checked: false,
          } as CustomSearchTemplate;
        }) || [];
    }
  }
  if (value) {
    obj = JSON.parse(value);
  }
  if (!obj) {
    obj = {
      version: 1,
      templateItems: [],
      customTemplateItems: [],
    };
  }
  const templateItems = [
    {
      id: "Name",
      templateKey: SearchTypeKey.Name,
      checked: false,
      isFull: false,
      isCustom: false,
    },
    {
      id: "PhoneNumber",
      templateKey: SearchTypeKey.PhoneNumber,
      checked: false,
      isFull: false,
      isCustom: false,
    },
    {
      id: "Cpr",
      templateKey: SearchTypeKey.Cpr,
      checked: false,
      isFull: false,
      isCustom: false,
    },
    {
      id: "Email",
      templateKey: SearchTypeKey.Email,
      checked: false,
      isFull: false,
      isCustom: false,
    },
    {
      id: "Address",
      templateKey: SearchTypeKey.Address,
      checked: false,
      isFull: false,
      isCustom: false,
    },
    {
      id: "CompanyName",
      templateKey: SearchTypeKey.CompanyName,
      checked: false,
      isFull: false,
      isCustom: false,
    },
    {
      id: "CreditCard",
      templateKey: SearchTypeKey.CreditCard,
      checked: false,
      isFull: false,
      isCustom: false,
    },
    {
      id: "Currency",
      templateKey: SearchTypeKey.Currency,
      checked: false,
      isFull: false,
      isCustom: false,
    },
    {
      id: "WebAddress",
      templateKey: SearchTypeKey.WebAddress,
      checked: false,
      isFull: false,
      isCustom: false,
    },
    {
      id: "Cvr",
      templateKey: SearchTypeKey.Cvr,
      checked: false,
      isFull: false,
      isCustom: false,
    },
    {
      id: "Number",
      templateKey: SearchTypeKey.Number,
      checked: false,
      isFull: false,
      isCustom: false,
    },
    {
      id: "Date",
      templateKey: SearchTypeKey.Date,
      checked: false,
      isFull: false,
      isCustom: false,
    },
    {
      id: "AssistiveProduct",
      templateKey: SearchTypeKey.AssistiveProduct,
      checked: false,
      isFull: false,
      isCustom: false,
    },
    {
      id: "Diseases",
      templateKey: SearchTypeKey.Diseases,
      checked: false,
      isFull: false,
      isCustom: false,
    },
    {
      id: "Medication",
      templateKey: SearchTypeKey.Medication,
      checked: false,
      isFull: false,
      isCustom: false,
    },
  ] as TemplateItem[];

  if (obj && !obj.templatesSelected) {
    obj.templatesSelected = [
      {
        id: "Name",
        templateKey: SearchTypeKey.Name,
        checked: true,
        isFull: true,
        isCustom: false,
      },
      {
        id: "Address",
        templateKey: SearchTypeKey.Address,
        checked: true,
        isFull: false,
        isCustom: false,
      },
      {
        id: "PhoneNumber",
        templateKey: SearchTypeKey.PhoneNumber,
        checked: false,
        isFull: false,
        isCustom: false,
      },
    ];
  }
  if (userConfigApp && (userConfigApp?.customTemplates?.length || 0) > 0) {
    obj!.customTemplateItems =
      userConfigApp.customTemplates?.map<CustomSearchTemplate>((o) => {
        return {
          ...o,
          id: v4(),
          checked: false,
        } as CustomSearchTemplate;
      }) || [];
  }
  obj!.templateItems = templateItems;
  if (
    customTemplateItemsOld.length > 0 &&
    obj!.customTemplateItems.length <= 0
  ) {
    obj!.customTemplateItems = customTemplateItemsOld;
  }

  return obj as SearchTemplateParam;
};
const GetSearchTagParams = () => {
  let obj = null;
  const value = localStorage.getItem(LocalStorageKey.TagSearch);
  if (value) {
    obj = JSON.parse(value);
  } else {
    obj = {
      accuracyValue: 0,
      accuracy: false,
      tagChecked: [],
      selectAll: false,
      searchFrom: "",
    };
  }
  return obj;
};
const GetSearchTabVm = () => {
  const { getUser } = userService();
  let obj = null;
  const value = localStorage.getItem(LocalStorageKey.SearchTabVm);

  if (value) {
    obj = JSON.parse(value);
    if (getUser()?.userDataLogin?.result?.user_info?.countryCode === "BEL") {
      obj.accuracy = false;
    }
  } else {
    obj = {
      accuracyValue: 0,
      accuracy: false,
      allPages: true,
      highlightWholeWord: false,
    } as Api.SearchTabVm;
  }
  return obj as Api.SearchTabVm;
};

const searchState = atom<ISearchState>({
  key: "searchState",
  default: {
    searchingKeywordParams: GetSearchKeywordParam(),
    searchingTemplateParams: GetSearchTemplateParam(),
    searchTabVm: GetSearchTabVm(),
    searchResult: {},
    searchType: "SearchKeyword",
    hasMore: false,
    searchTagParams: GetSearchTagParams(),
    showLoader: false,
    showOptionSearchKeyWord: false,
    showCancelLoader: false,
  } as ISearchState,
});
export { searchState };
