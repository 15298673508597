import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import * as Api from "../ApiV2";
import WatchDogSelectALLRowCommand from "../command/watchDogSelectAllRow-command";
import { DataCache } from "../config/constant";
import { pageSelectedState, searchState, WatchDogState } from "../recoil";
const WatchDogApi = new Api.WatchDogApi();
export const WatchDogService = () => {
  const [searchCurrentState] = useRecoilState(searchState);
  const [pageState] = useRecoilState(pageSelectedState);
  const [WatchDogCurrentState, setWatchDogState] =
    useRecoilState(WatchDogState);
  useEffect(() => {
    refreshWatchDogList();
  }, [WatchDogCurrentState.currentStates]);
  const refreshWatchDogList = async (callBack?: any) => {
    if (pageState.currentPage === 0) {
      setWatchDogState({ ...WatchDogCurrentState, watchdogData: null });
      return;
    }
    setWatchDogState({
      ...WatchDogCurrentState,
      showLoader: true,
      watchdogData: null,
    });
    WatchDogApi.apiLegalxtractCalculateWatchDogPost({
      param: WatchDogCurrentState.currentStates,
    }).then((response) => {
      setWatchDogState({
        ...WatchDogCurrentState,
        showLoader: false,
        watchdogData: response.data,
      });
      if (callBack) {
        callBack();
      }
    });
  };
  const WatchDogSelect = async (item: Api.WatchDogObj | null) => {
    WatchDogSelectALLRowCommand(
      DataCache.docuViewareID(),
      true,
      {
        watchDogObjs: [item],
        param: WatchDogCurrentState.currentStates,
      } as Api.UnSelectedAllSelectAllRowParam,
      () => {
        refreshWatchDogList();
      }
    );
  };
  const WatchDogDeSelect = async (item: Api.WatchDogObj | null) => {
    WatchDogSelectALLRowCommand(
      DataCache.docuViewareID(),
      false,
      {
        watchDogObjs: [item],
        param: WatchDogCurrentState.currentStates,
      } as Api.UnSelectedAllSelectAllRowParam,
      () => {
        refreshWatchDogList();
      }
    );
  };
  const WatchDogDeSelectAllRow = async (items: Api.WatchDogObj[]) => {
    WatchDogSelectALLRowCommand(
      DataCache.docuViewareID(),
      false,
      {
        watchDogObjs: items,
        param: WatchDogCurrentState.currentStates,
      } as Api.UnSelectedAllSelectAllRowParam,
      () => {
        refreshWatchDogList();
      }
    );
  };
  const WatchDogSelectAllRow = async (items: Api.WatchDogObj[]) => {
    WatchDogSelectALLRowCommand(
      DataCache.docuViewareID(),
      true,
      {
        watchDogObjs: items,
        param: WatchDogCurrentState.currentStates,
      } as Api.UnSelectedAllSelectAllRowParam,
      () => {
        refreshWatchDogList();
      }
    );
  };
  return {
    refreshWatchDogList,
    WatchDogSelectAllRow,
    WatchDogDeSelectAllRow,
    WatchDogDeSelect,
    WatchDogSelect,
  };
};
