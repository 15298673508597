import React from "react";
import { LoginService } from "./service/loginService";
import { DataCache } from "../../config/constant";
import { useLang } from "../../i18n/useLang";
import {
  Link,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
const Login = () => {
  const { tr } = useLang();
  const { formikLogin, handleSnackbarClose, msgState } = LoginService();
  return (
    <>
      {/* <Snackbar
        open={msgState.show}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        style={{ width: "100%" }}
        onClose={handleSnackbarClose}
      >
        <Alert
          severity="error"
          onClose={handleSnackbarClose}
          sx={{ width: "100%" }}
        >
          {msgState.Msg}
        </Alert>
      </Snackbar> */}
      {msgState.Msg && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
          {msgState.Msg}
        </MessageBar>
      )}

      <div
        style={{
          height: "100vh",
          width: "100%",
          padding: "0 10px",
          display: "flex",
          background:
            "linear-gradient(155deg, rgb(51 108 248) 0%, rgb(125 184 254) 49%, rgb(200 212 255) 77%)",
        }}
        onKeyDown={(e) => {
          if (e.code && e.code.toLocaleLowerCase() === "enter") {
            formikLogin.submitForm();
          }
        }}
      >
        <div
          style={{
            margin: "auto",
            maxWidth: 400,
            width: 400,
            background: "#fff",
            borderRadius: 10,
            padding: 20,
          }}
        >
          <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <TextField
                  label={tr("email")}
                  style={{ width: "100%" }}
                  autoFocus
                  name="userName"
                  value={formikLogin.values?.userName}
                  errorMessage={formikLogin.errors.userName}
                  onChange={formikLogin.handleChange}
                  onBlur={formikLogin.handleBlur}
                  iconProps={{ iconName: "Mail", style: { color: "#0078d4" } }}
                />
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <TextField
                  label={tr("password")}
                  type={"password"}
                  style={{ width: "100%" }}
                  name="passWord"
                  value={formikLogin.values?.passWord}
                  errorMessage={formikLogin.errors.passWord}
                  onChange={formikLogin.handleChange}
                  onBlur={formikLogin.handleBlur}
                  iconProps={{ iconName: "Lock", style: { color: "#0078d4" } }}
                />
              </div>
            </div>
            <div className="ms-Grid-row">
              <div
                className="ms-Grid-col ms-sm12"
                style={{ textAlign: "center" }}
              >
                <PrimaryButton
                  style={{ width: "100%" }}
                  onClick={() => {
                    formikLogin.submitForm();
                  }}
                >
                  {tr("login")}
                </PrimaryButton>
              </div>
            </div>
            <div className="ms-Grid-row">
              <div
                className="ms-Grid-col ms-sm12"
                style={{ textAlign: "center" }}
              >
                {tr("notAMember")}{" "}
                <Link href={DataCache.RegisterLink}>{tr("registerNow")}</Link>
              </div>
            </div>
          </Stack>
          {/* <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <TextField
                label={tr("email")}
                style={{ width: "100%" }}
                focused
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                name="userName"
                value={formikLogin.values?.userName}
                error={
                  formikLogin.touched.userName &&
                  Boolean(formikLogin.errors.userName)
                }
                onChange={formikLogin.handleChange}
                onBlur={formikLogin.handleBlur}
                helperText={
                  formikLogin.touched.userName && formikLogin.errors.userName
                }
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                label={tr("password")}
                variant="standard"
                type={"password"}
                style={{ width: "100%" }}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                }}
                name="passWord"
                value={formikLogin.values?.passWord}
                error={
                  formikLogin.touched.passWord &&
                  Boolean(formikLogin.errors.passWord)
                }
                onChange={formikLogin.handleChange}
                onBlur={formikLogin.handleBlur}
                helperText={
                  formikLogin.touched.passWord && formikLogin.errors.passWord
                }
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  formikLogin.submitForm();
                }}
              >
                {tr("login")}
              </Button>
            </Grid>
            <Grid item xs={12} md={12} textAlign={"center"}>
              {tr("notAMember")}{" "}
              <Link href={DataCache.RegisterLink}>{tr("registerNow")}</Link>
            </Grid>
          </Grid> */}
        </div>
      </div>
    </>
  );
};
export default Login;
