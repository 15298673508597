import React, { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import * as ApiV2 from "../../ApiV2";
import undoManager from "../../command/undo-manager";
import { TabIndex } from "../left-sidebar/left-sidebar-container";
import { SaveMode, SaveType } from "../../modal/SaveFileModal";
import { documentService } from "../../service/document";
import { useRecoilState } from "recoil";
import {
  leftSidebarState,
  modalContainerState,
  pageSelectedState,
  replaceState,
  searchState,
  trackingState,
} from "../../recoil";
import {
  _SelectedText,
  _mouseModeState,
} from "../layouts/registerEventDocuvieware";
import { MouseMode } from "../../recoil/mouseState";
import { result } from "lodash";
import { AnnoType, CompanyCode, DataCache } from "../../config/constant";
import { CacheLXApp } from "../../App";
import { saveService } from "../../service/saveService";
import { generateSavingFileName } from "../../utils/helpers";
const ReplaceApi = new ApiV2.ReplaceApi();
let timeOutSpace: any = null;
const HotKeys = () => {
  const [tracking, setTrackingState] = useRecoilState(trackingState);
  const [leftSidebar, setLeftSidebar] = useRecoilState(leftSidebarState);
  const [searchCurrentState, setSearchState] = useRecoilState(searchState);
  const [modalContainer, setModalContainerState] =
    useRecoilState(modalContainerState);
  const [replaceCurrentState, setReplaceState] = useRecoilState(replaceState);
  useEffect(() => {
    const root = document.getElementsByTagName("body")[0];
    root?.addEventListener(
      "keydown",
      (event) => {
        if (event.key && event.key.toLowerCase() == "c" && event.ctrlKey) {
          event.preventDefault();
          event.stopPropagation();
          if (event.shiftKey) {
            if (!CacheLXApp.MainMenuContext?.LastMousePos) return;
            ReplaceApi.apiLegalxtractGetSelectedWordContinuousPost({
              viewerPageNo: CacheLXApp.PageState?.currentPage,
              x: CacheLXApp.MainMenuContext?.LastMousePos.Left / 72,
              y: CacheLXApp.MainMenuContext?.LastMousePos.Top / 72,
              shift: event.shiftKey,
            }).then((res) => {
              const result = res.data;
              if (result && result.value) {
                const textField = document.createElement("textarea");
                textField.innerText = result.value;
                document.body.appendChild(textField);
                textField.select();
                document.execCommand("copy");
                textField.remove();
              }
            });
          } else {
            const textField = document.createElement("textarea");
            textField.innerText = _SelectedText;
            if (
              _mouseModeState?.mouseMode == MouseMode.Select ||
              !_SelectedText
            ) {
              textField.innerText =
                CacheLXApp.WordCurrentState?.wordDetected?.text || "";
            }
            document.body.appendChild(textField);
            textField.select();
            document.execCommand("copy");
            textField.remove();
          }
        }
        if (
          event.key &&
          event.key.toLowerCase() == "f" &&
          ((event.ctrlKey && event.shiftKey) ||
            (event.ctrlKey && !event.shiftKey))
        ) {
          event.preventDefault();
          event.stopPropagation();
          setLeftSidebar((cur) => {
            return { ...cur, lastPanelSelected: TabIndex.SearchTab };
          });
          ReplaceApi.apiLegalxtractGetSelectedWordContinuousPost({
            viewerPageNo: CacheLXApp.PageState?.currentPage,
            x: CacheLXApp.MainMenuContext?.LastMousePos.Left / 72,
            y: CacheLXApp.MainMenuContext?.LastMousePos.Top / 72,
            shift: event.shiftKey,
          }).then((res) => {
            const result = res.data;
            if (result && result.value) {
              onFindKeyword(result.value);
            }
          });
        }
      },
      false
    );
  }, []);
  const RegisterEvent = () => {};
  useHotkeys("ctrl+a", (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.key === "a" && e.ctrlKey)
      documentService.highLightPage(
        {
          viewerPageNos: [CacheLXApp.PageState?.currentPage || 0],
          mode: -1,
        },
        () => {
          setLeftSidebar((cur) => {
            return {
              ...cur,
              refreshTabActive: { IsRefresh: true, TabIndex: -1 },
            };
          });
        }
      );
  });
  useHotkeys("ctrl+f", (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.key === "f" && e.ctrlKey) {
      setLeftSidebar((cur) => {
        return { ...cur, lastPanelSelected: TabIndex.SearchTab };
      });
      onFindKeyword(CacheLXApp.WordCurrentState?.wordDetected?.text || "");
    }
  });

  useHotkeys("ctrl+r", (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.key === "r" && e.ctrlKey) {
      onReplace(
        CacheLXApp.WordCurrentState?.wordDetected || null,
        CacheLXApp.AnnotState?.replaceId || "",
        CacheLXApp.MainMenuContext?.LastMousePos,
        CacheLXApp.PageState?.currentPage || 0
      );
    }
  });
  useHotkeys("Space", (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!timeOutSpace) {
      timeOutSpace = setTimeout(() => {
        documentService.HighLightOrUnHighLightWords(
          CacheLXApp.PageState?.currentPage || 0,
          -1,
          CacheLXApp.FocusWord || CacheLXApp.WordCurrentState?.wordDetected || null,
          () => {
            setLeftSidebar((cur) => {
              return {
                ...cur,
                refreshTabActive: { IsRefresh: true, TabIndex: -1 },
              };
            });
          }
        );
        timeOutSpace = null;
      }, 200);
    } else {
      clearTimeout(timeOutSpace);
      if (!CacheLXApp.WordCurrentState?.wordDetected && !CacheLXApp.FocusWord)
        return;
      let posX = CacheLXApp.MainMenuContext?.LastMousePos.Left / 72;
      let posY = CacheLXApp.MainMenuContext?.LastMousePos.Top / 72;
      if (CacheLXApp.FocusWord && (CacheLXApp.FocusWord.rects || []).length > 0) {
        const rec = (CacheLXApp.FocusWord.rects || [])[0] as ApiV2.RectangleF;
        posX = rec.left || 0;
        posY = (rec.top || 0) + 0.01;
      }
      const param: ApiV2.HighLightUnHighLightLine = {
        x: posX,
        y: posY,
        isHotKey: true,
        pageNo: CacheLXApp.PageState?.currentPage,
      };
      documentService.HighLightLine(param, () => {
        setLeftSidebar((cur) => {
          return {
            ...cur,
            refreshTabActive: { IsRefresh: true, TabIndex: -1 },
          };
        });
        timeOutSpace = null;
      });
    }
  });
  useHotkeys("ctrl+u", (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.key === "u" && e.ctrlKey)
      documentService.HighLightOrUnHighLightWords(
        CacheLXApp.PageState?.currentPage || 0,
        0,
        CacheLXApp.WordCurrentState?.wordDetected || null,
        () => {
          setLeftSidebar((cur) => {
            return {
              ...cur,
              refreshTabActive: { IsRefresh: true, TabIndex: -1 },
            };
          });
        }
      );
  });
  useHotkeys("ctrl+e", (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.key === "e" && e.ctrlKey)
      if (
        DataCache.REACT_APP_CompanyCode?.toLocaleLowerCase() == CompanyCode.Nova
      ) {
        setModalContainerState({
          ...modalContainer,
          saveModal: {
            ...(modalContainer.saveModal ?? {}),
            isShow: true,
            saveMode: SaveMode.Extract,
            saveType: ApiV2.PdfFileType.NUMBER_1,
          },
        });
      } else {
        setModalContainerState({
          ...modalContainer,
          showxtractSettings: true,
        });
      }
  });
  useHotkeys("ctrl+s", (e: any) => {
    if (e.target.querySelector(".invoiceTabItem")) return;
    e.preventDefault();
    e.stopPropagation();
    if (
      e.key === "s" &&
      e.ctrlKey &&
      (CacheLXApp.PageState?.currentPage || 0) > 0
    ) {
      if (
        DataCache.REACT_APP_CompanyCode?.toLocaleLowerCase() != CompanyCode.Nova
      ) {
        if (CacheLXApp.DocuviewareMain) {
          setModalContainerState({
            ...modalContainer,
            saveModal: {
              ...(modalContainer.saveModal ?? {}),
              isShow: true,
              saveMode: SaveMode.Save,
              saveType: SaveType.SaveExtract,
            },
          });
        }
      } else {
        setModalContainerState({
          ...modalContainer,
          saveModal: {
            ...(modalContainer.saveModal ?? {}),
            isShow: true,
            saveMode: SaveMode.Save,
            saveType: SaveType.SaveNova,
          },
        });
      }
    }
  });
  useHotkeys("ctrl+z", (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.key === "z" && e.ctrlKey) undoManager.undo();
  });
  useHotkeys("ctrl+y", (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.key === "y" && e.ctrlKey) undoManager.redo();
  });
  const onFindKeyword = (text: string) => {
    if (!text) return;
    setSearchState({
      ...searchCurrentState,
      searchType: "SearchKeyword",
      searchingKeywordParams: {
        ...searchCurrentState.searchingKeywordParams,
        keyword: text,
        wholeWord: false,
        ForceSearchWhenInitialized: true,
      },
    });
    if (leftSidebar.lastPanelSelected == TabIndex.SearchTab) return;
    setLeftSidebar((cur) => {
      return {
        ...cur,
        SecondaryInitialSize: 425,
        lastPanelSelected: TabIndex.SearchTab,
      };
    });
  };
  const onReplace = async (
    WordDetected: ApiV2.LxWordResponse | null,
    ReplaceId: string,
    LastMousePos: any,
    currentPage: number
  ) => {
    const word = WordDetected as ApiV2.LxWordResponse;
    let result: ApiV2.SelectedWordContinuousResponse;
    let originalWords = "";
    const isReplaceAll = true;
    let rectangleWords: any[] = [];
    let replaceObj: ApiV2.ReplaceObjDisplay | null = null;
    if (!ReplaceId && word && JSON.stringify(word) != "{}") {
      result = (
        await ReplaceApi.apiLegalxtractGetSelectedWordContinuousPost({
          viewerPageNo: currentPage,
          x: LastMousePos.Left / 72,
          y: LastMousePos.Top / 72,
          shift: true,
          matchCase: false,
          wholeWord: false,
        })
      ).data;
      if (result) {
        originalWords = result.value || "";
        rectangleWords = result.rectangleSelections || [];
      }
      setReplaceState((cur) => {
        return {
          ...cur,
          replacementParams: {
            ...cur.replacementParams,
            originalWords: originalWords,
            rectangleWords: rectangleWords,
            onlyReplaceAll: false,
            matchCase: false,
            wholeWord: false,
            isReplaceFromSearch: false,
            isReplaceAll: isReplaceAll,
            isFocusWordHightLight:
              CacheLXApp.AnnotState?.TagType === AnnoType.HIGHLIGHT
                ? true
                : false,
          },
          isOpenCreateEditReplacement: true,
        };
      });
    } else if (ReplaceId) {
      replaceObj =
        replaceCurrentState.replaceWords.find((o) => o.id == ReplaceId) || null;
      setReplaceState((cur) => {
        return {
          ...cur,
          replacementParams: {
            ...cur.replacementParams,
            onlyReplaceAll: false,
            isReplaceFromSearch: false,
            isFocusWordHightLight: true,
          },
          isOpenCreateEditReplacement: true,
          editReplacement: replaceObj,
        };
      });
    } else {
      setReplaceState((cur) => {
        return {
          ...cur,
          replacementParams: {
            ...replaceCurrentState.replacementParams,
            replaceBy: "",
            originalWords: "",
            extractColor: "#ffffff",
            fontName: "Arial",
            fontColor: "#e74c3c",
            fontSize: 10,
            isReplaceAll: true,
            onlyReplaceAll: true,
            matchCase: false,
            wholeWord: false,
            isReplaceFromSearch: false,
            isFocusWordHightLight:
              CacheLXApp.AnnotState?.TagType === AnnoType.HIGHLIGHT
                ? true
                : false,
          },
          isOpenCreateEditReplacement: true,
          editReplacement: replaceObj,
        };
      });
    }
  };
  RegisterEvent();
  return <></>;
};
export default HotKeys;
