import { LocalStorageKey } from "../config/constant";
import { annotationService } from "../service/annotationService";
import undoManager from "./undo-manager";
import { UndoHightLight } from "./undoHightLight";
const DeleteReplaceCommand = (
  docuViewareID: string | null | undefined,
  paramDeleteReplaceFromWord: { x: number; y: number } | null,
  callback: any
) => {
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, "true");
  let ParamUndo = "";
  const DeleteReplaceFromWord = (
    paramDeleteReplaceFromWord: { x: number; y: number } | null
  ) => {
    if (!paramDeleteReplaceFromWord) return;
    const dataRequest = {
      Data: JSON.stringify(paramDeleteReplaceFromWord),
      Type: "DeleteReplaceFromWordCommand",
    };
    annotationService.AddAnnotations(docuViewareID, dataRequest, (res: any) => {
      if (!res) return;
      ParamUndo = res;
      if (callback) {
        callback();
      }
    });
  };
  DeleteReplaceFromWord(paramDeleteReplaceFromWord);
  undoManager.add({
    undo: function () {
      UndoHightLight(docuViewareID, ParamUndo, callback);
    },
    redo: function () {
      DeleteReplaceFromWord(paramDeleteReplaceFromWord);
    },
  });
};
const DeleteReplaceObjCommand = (
  docuViewareID: string | null | undefined,
  paramDeleteReplaceObj: { requestBody: string[] } | null,
  callback: any
) => {
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, "true");
  let ParamUndo = "";
  const DeleteReplace = (
    paramDeleteReplaceFromWord: { requestBody: string[] } | null
  ) => {
    if (!paramDeleteReplaceFromWord) return;
    const dataRequest = {
      Data: JSON.stringify(paramDeleteReplaceFromWord),
      Type: "DeleteReplaceCommand",
    };
    annotationService.AddAnnotations(docuViewareID, dataRequest, (res: any) => {
      if (!res) return;
      ParamUndo = res;
      if (callback) {
        callback();
      }
    });
  };
  DeleteReplace(paramDeleteReplaceObj);
  undoManager.add({
    undo: function () {
      UndoHightLight(docuViewareID, ParamUndo, callback);
    },
    redo: function () {
      DeleteReplace(paramDeleteReplaceObj);
    },
  });
};
const DeleteAllReplaceCommand = (
  docuViewareID: string | null | undefined,
  callback: any
) => {
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, "true");
  let ParamUndo = "";
  const DeleteAllReplace = () => {
    const dataRequest = {
      Data: undefined,
      Type: "DeleteAllReplaceCommand",
    };
    annotationService.AddAnnotations(docuViewareID, dataRequest, (res: any) => {
      if (!res) return;
      ParamUndo = res;
      if (callback) {
        callback();
      }
    });
  };
  DeleteAllReplace();
  undoManager.add({
    undo: function () {
      UndoHightLight(docuViewareID, ParamUndo, callback);
    },
    redo: function () {
      DeleteAllReplace();
    },
  });
};

export {
  DeleteReplaceCommand,
  DeleteReplaceObjCommand,
  DeleteAllReplaceCommand,
};
