import { useRecoilState } from "recoil";

import * as Api from "../ApiV2";
import { TOCState } from "../recoil/tocState";
import {
  docuviewareMainState,
  leftSidebarState,
  modalContainerState,
  pageSelectedState,
  topmenuStripState,
  trackingState,
} from "../recoil";
import { ocrSocketSignalRSService } from "../service";
import { DataCache, LocalStorageKey } from "../config/constant";
import { documentService } from "./document";
import { CacheLXApp } from "../App";
import undoManager from "../command/undo-manager";
const AutoFlowApi = new Api.AutoFlowApi();
const CheckDuplicateApi = new Api.CheckDuplicateApi();
export const AutoFlowService = () => {
  const { RunOCRAllTabsNotSendMail } = ocrSocketSignalRSService();
  const [, setLeftSidebarState] = useRecoilState(leftSidebarState);
  const [docuviewareMain, setDocuviewareMain] =
    useRecoilState(docuviewareMainState);
  const [pageState, setPageState] = useRecoilState(pageSelectedState);
  const [modalContainer, setModalContainerState] =
    useRecoilState(modalContainerState);
  const [tracking, setTrackingState] = useRecoilState(trackingState);
  const [topmenuStrip, setTopmenuStripState] =
    useRecoilState(topmenuStripState);
  const RunAutoFlow = async (tabs: Api.TabDetail[]) => {
    const userConfigApp = LocalStorageKey.GetOjectItem<Api.UserConfigApp>(
      LocalStorageKey.UserConfigApp
    );
    if (!userConfigApp) return;
    setPageState((cur) => {
      return {
        ...cur,
        oldCurrentPage: cur.currentPage,
        oldCurrentPageId: cur.PageObj?.id || "",
      };
    });
    const autoFlowTemplate = userConfigApp.autoFlow?.autoFlowTemplates?.find(
      (o) => o.id === userConfigApp.autoFlow?.usingAutoFlowId
    );
    if (userConfigApp.autoFlow?.isUsingAutoFlow && !autoFlowTemplate) return;
    setTrackingState((cur) => {
      return {
        ...cur,
        openingLoadingState: {
          ...cur.openingLoadingState,
          isOpen: true,
          label: ``,
        },
      };
    });
    const response = await AutoFlowApi.apiLegalxtractRunAutoFlowPost();

    if (autoFlowTemplate?.isOcr) {
      RunOCRAllTabsNotSendMail(tabs, async () => {
        if (autoFlowTemplate?.isFindDuplicate) {
          CheckDuplicate();
        } else {
          AfterAutoFlowDone();
        }
      });
    }
    if (autoFlowTemplate?.isOcr === false) {
      setTrackingState({
        ...tracking,
        openingLoadingState: {
          ...tracking.openingLoadingState,
          isOpen: true,
          label: ``,
        },
      });
      if (autoFlowTemplate?.isFindDuplicate) {
        CheckDuplicate();
      } else {
        AfterAutoFlowDone();
      }
    }
  };
  const CheckDuplicate = () => {
    setTrackingState((cur) => {
      return {
        ...cur,
        openingLoadingState: {
          ...cur.openingLoadingState,
          isOpen: true,
          label: ``,
        },
      };
    });
    CheckDuplicateApi.apiLegalxtractCheckDuplicatePost({
      isCurrentDocument: false,
      includeHideOnExtract: false,
    }).then((data) => {
      if (data.data) {
        setModalContainerState((cur) => {
          return {
            ...cur,
            previewFindDuplicates: {
              isShow: true,
              CheckDuplicateResponse: data.data,
              onOk: async () => {
                AfterAutoFlowDone();
              },
              onClosed: () => {
                AfterAutoFlowDone();
              },
            },
          };
        });
      } else {
        AfterAutoFlowDone();
      }
    });
  };
  const AfterAutoFlowDone = async () => {
    undoManager.clear();
    const userConfigApp = LocalStorageKey.GetOjectItem<Api.UserConfigApp>(
      LocalStorageKey.UserConfigApp
    );
    setTrackingState((cur) => {
      return { ...cur, openingLoadingState: { isOpen: true } };
    });
    await AutoFlowApi.apiLegalxtractRunAutoFlowSearchAndReplacePost();
    const res = await AutoFlowApi.apiLegalxtractAfterAutoFlowDonePost(
      CacheLXApp.PageState?.currentPage || 1,
      userConfigApp?.autoFlow?.usingAutoFlowId || ""
    );
    setDocuviewareMain((cur) => {
      return {
        ...cur,
        DocuviewareInit: res.data,
      };
    });
    setTopmenuStripState((cur) => {
      return {
        ...cur,
        dropdownList: {
          ...cur.dropdownList,
          StampChecked: res.data.showStamp || false,
          PageNumberingChecked: res.data.isIncludedPageNumber || false,
          TOCChecked: res.data.isTOC || false,
          BlankPageChecked: res.data.isBlankPage || false,
        },
      };
    });
    setTrackingState((cur) => {
      return {
        ...cur,
        openingLoadingState: {
          ...cur.openingLoadingState,
          isOpen: false,
          label: ``,
        },
      };
    });
    setLeftSidebarState((cur) => {
      return {
        ...cur,
        refreshTabActive: { IsRefresh: true, TabIndex: -1 },
      };
    });
    documentService.RefreshPage(() => {
      DocuViewareAPI.UpdateLayout(DataCache.docuViewareID());
      DocuViewareAPI.PostCustomServerAction(
        DataCache.docuViewareID(),
        false,
        "RedrawPages",
        "",
        () => {}
      );
    }, DataCache.docuViewareID());
  };
  return { RunAutoFlow };
};
