import React, { CSSProperties, useEffect } from "react";
import { Label, getColorFromString, IColor } from "@fluentui/react";
import CalloutColorPicker from "./CalloutColorPicker";
type ModalHeaderProps = {
  Label?: string;
  onColorDismiss?: (newColor: IColor) => void;
  style?: CSSProperties | undefined;
  className?: string | undefined;
  color: string;
};

export const ColorPicker = (props: ModalHeaderProps) => {
  const [isShowColor, setisShowColor] = React.useState<boolean>(false);
  const [Color, setColor] = React.useState<string>("#000");
  const textColorDivElement = React.useRef(null);
  const onColorDismiss = (newColor: IColor) => {
    if (props.onColorDismiss) props.onColorDismiss(newColor);
    setisShowColor(false);
  };
  const a = getColorFromString(Color || "#000")!;
  useEffect(() => {
    setColor(props.color);
  }, [props.color]);

  return (
    <>
      {props.Label && <Label>{props.Label}</Label>}
      <input
        ref={textColorDivElement}
        className="child-item"
        style={{
          ...props.style,
          background: Color || "#fff",
          border: "1px solid #605e5c",
          height: props.style && props.style.height ? props.style.height : 30,
          width: props.style && props.style.width ? props.style.width : "100%",
          caretColor: "transparent",
        }}
        onClick={() => {
          setisShowColor(true);
          setTimeout(() => {
            document
              .querySelector(".ms-Layer.ms-Layer--fixed:last-child")
              ?.setAttribute("style", "z-index: 1000002");
          }, 100);
        }}
      />
      {isShowColor && (
        <CalloutColorPicker
          color={getColorFromString(Color || "#000")!}
          colorDivElement={textColorDivElement}
          onCalloutDismiss={onColorDismiss}
        />
      )}
    </>
  );
};
