import {
  CompanyCode,
  Constants,
  DataCache,
  LocalStorageKey,
} from "../config/constant";
import * as Api from "../ApiV2";
import { GetLxSetting, lxSettingDefault } from "../recoil/docuviewareState";
import { userService } from "../service/userService";
export const AppHelper = {
  ConfigSettings: () => {
    const { getUser } = userService();
    const user = getUser();
    let lxSetting = GetLxSetting();
    if (DataCache.REACT_APP_CompanyCode == CompanyCode.Nova) {
      lxSetting = {
        ...lxSetting,
        dictionaryName: Api.Country.NUMBER_1,
        namesWillNotBeginOrEndWith: "",
      };
    }
    if (user?.userDataLogin?.result?.user_info?.countryCode == "DNK") {
      lxSetting = {
        ...lxSetting,
        dictionaryName: Api.Country.NUMBER_1,
      };
    }
    return lxSetting;
  },
  CmToInch: (cm: number) => {
    return cm * 0.393700787;
  },
  InchToCm: (inch: number) => {
    return parseFloat((inch / 0.393700787).toFixed(2));
  },
  ArgbToRgba: (argb: string) => {
    if (argb.indexOf("#") != 0) return argb;
    if (argb.length < 8) return argb;
    // Loại bỏ ký tự '#' nếu có
    argb = argb.replace("#", "");

    // Tách các thành phần ARGB
    const a = parseInt(argb.substring(0, 2), 16) / 255; // Alpha (0-1)
    const r = parseInt(argb.substring(2, 4), 16); // Red (0-255)
    const g = parseInt(argb.substring(4, 6), 16); // Green (0-255)
    const b = parseInt(argb.substring(6, 8), 16); // Blue (0-255)

    // Trả về chuỗi RGBA
    return `rgba(${r}, ${g}, ${b}, ${a.toFixed(2)})`;
  },
  RgbaToArgb: (rgba: string) => {
    // Tách các thành phần RGBA
    const [r, g, b, a] = rgba
      .replace("rgba(", "")
      .replace(")", "")
      .split(",")
      .map((x) => parseFloat(x.trim())); // Loại bỏ khoảng trắng và chuyển thành số

    // Trả về chuỗi ARGB
    return `#${Math.round(a * 255)
      .toString(16)
      .padStart(2, "0")}${Math.round(r)
      .toString(16)
      .padStart(2, "0")}${Math.round(g)
      .toString(16)
      .padStart(2, "0")}${Math.round(b).toString(16).padStart(2, "0")}`;
  },
};
