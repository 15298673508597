import { atom } from "recoil";
import * as Api from "../ApiV2";
import { CustomSearchTemplate } from "./searchState";
import { SaveFileModalProps } from "../modal/SaveFileModal";
import { SplitBookmarkModalProps } from "../modal/SplitBookmarkModal";
import { TOCListModal } from "../modal/toc/TocListModal";
interface IModalContainerState {
  changeLineColor: boolean;
  showEditHeaderToc: boolean;
  EditTocHeaderParam: Api.EditTocHeaderParam;
  replaceBoxModalState: {
    openReplaceBoxModal: "edit" | "add" | "";
    replaceId: string;
    boxId: string;
  };
  isShowFrontPageModal: boolean;
  displaySettings: boolean;
  showxtractSettings: boolean;
  showTocSettings: boolean;
  displayPageNumbering: boolean;
  displayStamp: "edit" | "add" | "";
  displayStampIndex: boolean;
  openAddCustomTemplate: boolean;
  editCustomTemplate: CustomSearchTemplate | null;
  saveModal: SaveFileModalProps | null;
  showSplitDocument: boolean;
  showTOCListModal: boolean;
  showBookMarkModal: "edit" | "add" | "";
  showApprovalStampModal: boolean;
  findDuplicates: boolean;
  showReplaceTemplate?: {
    isShow: boolean;
    addReplaceTemplate?: Api.ReplaceTemplate;
    onClosed?: () => void;
  };
  showSplitBookmarkModal?: SplitBookmarkModalProps;
  previewFindDuplicates: {
    isShow: boolean;
    CheckDuplicateResponse?: Api.CheckDuplicateResponse;
    onClosed?: () => void;
    onOk?: () => void;
  };
  showAutoFlow?: {
    isShow: boolean;
    ShowFromOpenFile: boolean;
    onClosed: () => void;
    onOk: () => void;
  };
}

const modalContainerState = atom<IModalContainerState>({
  key: "modalContainerState",
  default: {
    changeLineColor: false,
    showEditHeaderToc: false,
    previewFindDuplicates: {
      isShow: false,
    },
    EditTocHeaderParam: {},
    replaceBoxModalState: {
      openReplaceBoxModal: "",
      replaceId: "",
      boxId: "",
    },
    isShowFrontPageModal: false,
    displaySettings: false,
    showTocSettings: false,
    showxtractSettings: false,
    displayPageNumbering: false,
    findDuplicates: false,
    displayStamp: "",
    displayStampIndex: false,
    openAddCustomTemplate: false,
    editCustomTemplate: null,
    saveModal: null,
    showTOCListModal: false,
    showSplitDocument: false,
    showApprovalStampModal: false,
    showBookMarkModal: "",
  },
});
export { modalContainerState };
