import * as Api from "../ApiV2";
import { LocalStorageKey } from "../config/constant";
import undoManager from "./undo-manager";
const TocApi = new Api.TocApi();
const TocChangeDataCommand = (
  oldTocTabInfos: Api.TocTabInfo[],
  oldTocSettings: Api.TocSettings,
  newTocSettings: Api.TocSettings,
  newTocTabInfos: Api.TocTabInfo[],
  callback: (data?: Api.DocuviewareInit) => void,
  beforeAction: () => void
) => {
  const Callback = callback;
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, "true");
  const EditToc = async (
    tocTabInfos: Api.TocTabInfo[],
    tocSettings: Api.TocSettings
  ) => {
    beforeAction();
    const data = (
      await TocApi.apiLegalxtractEditTocPost({
        tocSettings: tocSettings,
        tocTabs: tocTabInfos,
      })
    ).data;
    if (Callback) {
      Callback(data.docuviewareInit);
    }
  };
  EditToc(newTocTabInfos, newTocSettings);
  undoManager.add({
    undo: function () {
      EditToc(oldTocTabInfos, oldTocSettings);
    },
    redo: function () {
      EditToc(newTocTabInfos, newTocSettings);
    },
  });
};
export default TocChangeDataCommand;

export const TocRowChangeDataCommand = (
  tocRow: Api.TocTabInfo,
  callback: (data: Api.EditTocRowResponse, tocRow: Api.TocTabInfo) => void,
  beforeAction: () => void
) => {
  const Callback = callback;
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, "true");
  let undoParam: Api.TocTabInfo | null = null;
  const UpdateTocRow = async (tocRow: Api.TocTabInfo) => {
    beforeAction();
    const data = (await TocApi.apiLegalxtractEditTocRowPost(tocRow)).data;
    undoParam = data.oldTocRow || null;
    if (Callback) {
      Callback(data, tocRow);
    }
  };
  UpdateTocRow(tocRow);
  undoManager.add({
    undo: function () {
      undoParam && UpdateTocRow(undoParam);
    },
    redo: function () {
      UpdateTocRow(tocRow);
    },
  });
};
