import {
  Checkbox,
  CheckboxVisibility,
  CommandBarButton,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  DirectionalHint,
  Dropdown,
  IColor,
  IColumn,
  IDropdownOption,
  IStackItemStyles,
  IStackTokens,
  IconButton,
  Label,
  Modal,
  PrimaryButton,
  SelectionMode,
  Stack,
  TooltipHost,
} from "@fluentui/react";
import { Selection } from "@fluentui/react/lib/DetailsList";
import { saveAs } from "file-saver";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import * as ApiV2 from "../ApiV2";
import { ModalContent } from "../assets/style/modal/modal";
import ModalHeader from "../control/modalHeader";
import { useLang } from "../i18n/useLang";
import { dialogState, leftSidebarState, tagState } from "../recoil";
import { tagService } from "../service";
import { CustomSearchTemplate, searchState } from "../recoil/searchState";
import AddSearchCustomTemplateModal from "./AddSearchCustomTemplateModal";
const SearchApi = new ApiV2.SearchApi();
const stackTokens: IStackTokens = { childrenGap: 10 };
const verticalStackTokens: IStackTokens = { childrenGap: 5 };
const stackStyles: IStackItemStyles = {
  root: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  },
};
interface ListCustomTemplateProps {
  isShow: boolean;
  onClose: () => void;
  onSave?: (datas: CustomSearchTemplate[]) => void;
}

export const ListCustomTemplate = React.memo(
  (props: ListCustomTemplateProps) => {
    const { tr } = useLang();
    const { getTags } = tagService();
    const formChange = React.useRef<boolean>(false);
    const [searchCurrentState] = useRecoilState(searchState);
    const [dialog, setDialogState] = useRecoilState(dialogState);
    const [lstCustomTemplate, SetListCustomTemplate] = useState<
      CustomSearchTemplate[]
    >(searchCurrentState.searchingTemplateParams?.customTemplateItems || []);
    const [showAddCustomTemplate, SetShowAddCustomTemplate] = useState<{
      isShow: boolean;
      editItem: CustomSearchTemplate | null;
    }>();
    React.useEffect(() => {
      getTags();
    }, []);
    function getKey(item: any): string {
      return item.key;
    }

    const columns: IColumn[] = [
      {
        key: "name",
        name: tr("name"),
        fieldName: "name",
        minWidth: 95,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
      },
      {
        key: "isRegex",
        name: tr("isRegex"),
        fieldName: "isRegex",
        minWidth: 40,
        data: "string",
        onRender: (item: CustomSearchTemplate) => {
          return (
            <Checkbox
              checked={item.isRegex}
              onChange={() => {
                SetListCustomTemplate(
                  lstCustomTemplate.map((x) =>
                    x.id === item.id ? { ...x, isRegex: !x.isRegex } : x
                  )
                );
              }}
            ></Checkbox>
          );
        },
      },
      {
        key: "pattern",
        name: tr("pattern"),
        fieldName: "pattern",
        minWidth: 95,
        isRowHeader: true,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "Edit",
        name: tr("edit"),
        fieldName: "Edit",
        minWidth: 20,
        isRowHeader: true,
        isResizable: true,
        data: "number",
        onRender: (item: CustomSearchTemplate) => {
          return (
            <TooltipHost
              content={tr("edit")}
              directionalHint={DirectionalHint.rightCenter}
            >
              <IconButton
                iconProps={{ iconName: "Edit" }}
                style={{ cursor: "pointer", fontSize: "15px" }}
                onClick={() => {
                  SetShowAddCustomTemplate({ isShow: true, editItem: item });
                }}
              />
            </TooltipHost>
          );
        },
        isPadded: true,
      },
      {
        key: "Delete",
        name: tr("delete"),
        fieldName: "Delete",
        minWidth: 20,
        onRender: (item: CustomSearchTemplate) => {
          return (
            <TooltipHost
              content={tr("delete")}
              directionalHint={DirectionalHint.rightCenter}
            >
              <IconButton
                iconProps={{ iconName: "Cancel" }}
                style={{ cursor: "pointer", fontSize: "15px" }}
                onClick={() => {
                  setDialogState({
                    ...dialog,
                    MsgDialog: tr("doYouWantToDelete"),
                    CallBackConfirmDialog: {
                      action: () => {
                        SetListCustomTemplate(
                          lstCustomTemplate.filter((x) => x.id !== item.id)
                        );
                        formChange.current = true;
                      },
                    },
                    Type: "ConfirmationDialog",
                  });
                }}
              />
            </TooltipHost>
          );
        },
        isPadded: true,
      },
    ];
    const onClose = () => {
      if (formChange.current) {
        setDialogState({
          ...dialog,
          MsgDialog: tr("customTemplateHasChangedDoYouWantToSave"),
          CallBackConfirmDialog: {
            action: () => {
              onOk();
            },
            actionNo: () => {
              props.onClose();
            },
          },
          Type: "YesNoCancel",
        });
      } else {
        props.onClose();
      }
    };
    const onOk = () => {
      SearchApi.apiLegalxtractSaveCustomTemplatePost({
        customTemplates: lstCustomTemplate,
      }).then((res) => {
        // có thể xóa dòng này
        localStorage.removeItem("TemplateSearchNew");
        
        props.onSave && props.onSave(lstCustomTemplate);
        props.onClose();
      });
    };
    return (
      <Modal isOpen={props.isShow} onDismiss={onClose} isBlocking={true}>
        <ModalContent style={{ width: 700 }}>
          <Stack tokens={verticalStackTokens}>
            <ModalHeader
              title={tr("customTemplate")}
              onCloseModal={props.onClose}
            />
            <div style={{ height: 400, overflow: "auto" }}>
              <DetailsList
                styles={{ root: { overflowX: "hidden" } }}
                items={lstCustomTemplate}
                columns={columns}
                layoutMode={DetailsListLayoutMode.justified}
                checkboxVisibility={CheckboxVisibility.hidden}
                getKey={getKey}
                isHeaderVisible={true}
              />
            </div>
            <Stack
              horizontal
              style={{ justifyContent: "space-between" }}
              tokens={stackTokens}
            >
              <DefaultButton
                text={tr("add")}
                allowDisabledFocus
                onClick={() => {
                  SetShowAddCustomTemplate({ isShow: true, editItem: null });
                }}
              />
              <Stack horizontal tokens={stackTokens}>
                <DefaultButton
                  text={tr("close")}
                  allowDisabledFocus
                  onClick={() => {
                    onClose();
                  }}
                />
                <PrimaryButton
                  text={tr("ok")}
                  allowDisabledFocus
                  onClick={() => {
                    onOk();
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
          {showAddCustomTemplate?.isShow && (
            <AddSearchCustomTemplateModal
              isShow={showAddCustomTemplate.isShow}
              onCloseModal={() => {
                SetShowAddCustomTemplate({ isShow: false, editItem: null });
              }}
              editTemplate={showAddCustomTemplate.editItem}
              handleSaveCustomTemplates={(param) => {
                if (showAddCustomTemplate.editItem) {
                  const arr = [
                    ...lstCustomTemplate.map((template: any) =>
                      template.id === showAddCustomTemplate.editItem?.id
                        ? { ...template, ...param }
                        : template
                    ),
                  ];
                  SetListCustomTemplate(arr);
                } else {
                  SetListCustomTemplate([...lstCustomTemplate, param]);
                }
                formChange.current = true;
              }}
            />
          )}
        </ModalContent>
      </Modal>
    );
  }
);
