import { useRecoilState } from "recoil";
import * as Api from "../ApiV2";
import { bookMarkState, pageSelectedState } from "../recoil";
const BookMarkApi = new Api.BookMarkApi();

export const bookMarkService = () => {
  const [bookMark, SetBookMarkState] = useRecoilState(bookMarkState);
  const [pageState] = useRecoilState(pageSelectedState);
  const getBookMarkList = async () => {
    if (pageState.currentPage === 0) {
      SetBookMarkState((cur) => {
        return {
          ...cur,
          ListBookmark: [],
          ShowBookMarkModal: "",
        };
      });
      return;
    }
    const BookMarkInfos = (
      await BookMarkApi.apiLegalxtractGetAllBookmarkObjsGet()
    ).data;

    SetBookMarkState((cur) => {
      return {
        ...cur,
        ListBookmark: BookMarkInfos,
        ShowBookMarkModal: "",
      };
    });
  };
  const GetAllNodesByParentId = (
    nodes: Api.BookmarkInfo[],
    parentId: string
  ) => {
    const result: Api.BookmarkInfo[] = [];
    function findChildren(parentId: string) {
      const children = nodes.filter((node) => node.parentId === parentId);
      children.forEach((child) => {
        result.push(child);
        findChildren(child.id || ""); // Đệ quy để tìm các node con của node hiện tại
      });
    }
    findChildren(parentId);
    return result;
  };
  return { getBookMarkList, GetAllNodesByParentId };
};
