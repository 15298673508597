import React, { useState, useContext, useEffect } from "react";
import { useLang } from "../../../i18n/useLang";
import {
  Check,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  PrimaryButton,
  SelectionMode,
  Stack,
} from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";
import {
  ContextualMenu,
  DirectionalHint,
  IContextualMenuItem,
  IContextualMenuStyles,
} from "@fluentui/react/lib/ContextualMenu";
import { CheckboxVisibility, Selection } from "@fluentui/react/lib/DetailsList";
import { TooltipHost } from "@fluentui/react/lib/Tooltip";
import * as Api from "../../../ApiV2";
import { multiSort } from "../../../utils/helpers";
import {
  DeleteAllReplaceCommand,
  DeleteReplaceObjCommand,
} from "../../../command/delete-replace-command";
import { DataCache, LocalStorageKey } from "../../../config/constant";
import { StyledContainer } from "../../../assets/style/left-sidebar/replace-panel";
import { StyledActionIconContainer } from "../../../assets/style/left-sidebar/leftLayout";
import { useRecoilState } from "recoil";
import {
  dialogState,
  docuviewareMainState,
  modalContainerState,
  replaceState,
} from "../../../recoil";
import { DocuVieware } from "../../../recoil/docuviewareState";
import { replaceService } from "../../../service";
import { Edit20Regular } from "@fluentui/react-icons";
import { useBoolean } from "@fluentui/react-hooks";
import { documentService } from "../../../service/document";

export const genTreeReplaceTemplateMenu = (props: {
  onClick: (
    ev: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: Api.ReplaceTemplate
  ) => void;
}) => {
  const userConfigApp = LocalStorageKey.GetOjectItem<Api.UserConfigApp>(
    LocalStorageKey.UserConfigApp
  );
  const data = userConfigApp?.replaceTemplate?.templates || [];
  const mapReplateTemplate = new Map<string, IContextualMenuItem>();
  if (!data) return [];
  data.forEach((item) => {
    mapReplateTemplate.set(item?.id || "", {
      key: item?.id || "",
      name: item?.replaceBy || "",
      onRenderContent: (itemProps: any) => {
        const hasSubItem =
          (itemProps?.item?.subMenuProps?.items.length || 0) > 0;
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              fontSize: 11,
              color: "#333",
              paddingLeft: 28,
            }}
          >
            <div
              style={{ width: "100%", cursor: "pointer", flex: 1 }}
              onClick={(ev) => {
                props.onClick(ev, item);
              }}
            >
              {item?.replaceBy}
            </div>
            {hasSubItem && <Icon iconName="ChevronRight"></Icon>}
          </div>
        );
      },
    });
  });
  const result = [] as IContextualMenuItem[];
  data.forEach((item) => {
    if (
      item?.parentId &&
      item?.parentId !== "00000000-0000-0000-0000-000000000000"
    ) {
      const parent = mapReplateTemplate.get(item?.parentId);
      if (parent) {
        const child = mapReplateTemplate.get(item?.id || "");
        if (child) {
          if (!parent?.subMenuProps) {
            parent.subMenuProps = { items: [] };
          }
          parent?.subMenuProps?.items.push(child);
        }
      }
    } else {
      const parentItem = mapReplateTemplate.get(item?.id || "");
      parentItem && result.push(parentItem);
    }
  });
  return result;
};
const MenuContext = ({
  targetPoint,
  hideMenu,
  replaceObject,
}: {
  targetPoint: any;
  hideMenu: () => void;
  replaceObject?: Api.ReplaceObjDisplay;
}) => {
  const [replaceCurrentState, setReplaceState] = useRecoilState(replaceState);
  const [modalContainer, setModalContainerState] =
    useRecoilState(modalContainerState);
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const { RefreshReplaceWord } = replaceService();
  const showReplaceTemplateList = () => {
    setModalContainerState({
      ...modalContainer,
      showReplaceTemplate: { isShow: true },
    });
  };
  const onEditReplace = async (
    replaceTemplace: Api.ReplaceTemplate | null = null
  ) => {
    if (!replaceObject) return;
    const newReplaceObj = { ...replaceObject };
    if (replaceTemplace) {
      newReplaceObj.extractColor = replaceTemplace.extractColor;
      newReplaceObj.fontColor = replaceTemplace.fontColor;
      newReplaceObj.replaceBy = replaceTemplace.replaceBy;
      newReplaceObj.fontSize = replaceTemplace.fontSize;
    }
    setReplaceState((cur) => {
      return {
        ...cur,
        replacementParams: {
          ...cur.replacementParams,
          onlyReplaceAll: false,
          isReplaceFromSearch: false,
          rectangleWords: null,
          fontSize:
            newReplaceObj.fontSize || cur.replacementParams.fontSize || 10,
        },
        replaceBoxParams: { ...cur.replaceBoxParams },
        editReplacement: newReplaceObj,
        isOpenCreateEditReplacement: true,
      };
    });
  };
  const { tr } = useLang();
  useEffect(() => {
    document.addEventListener("mousedown", hideMenu);
    return () => {
      document.removeEventListener("mousedown", hideMenu);
    };
  }, []);
  const deleteReplace = (item: Api.ReplaceObj) => {
    DeleteReplaceObjCommand(
      DataCache.docuViewareID(),
      { requestBody: [item?.id || ""] },
      () => {
        setTimeout(() => {
          RefreshReplaceWord(replaceCurrentState);
        }, 300);
      }
    );
  };
  const editReplaceBoxModal = (
    state?: "edit" | "add" | "",
    replaceObj?: Api.ReplaceObjDisplay | null,
    template: Api.ReplaceTemplate | null = null
  ) => {
    if (!replaceObj) return;
    if (replaceObj.pageNo) {
      documentService.GotoPage(DataCache.docuViewareID(), replaceObj.pageNo);
    }
    setTimeout(() => {
      if (template) {
        setReplaceState((cur) => {
          return {
            ...cur,
            replaceBoxParams: {
              ...cur.replaceBoxParams,
              isReplaceTemplate: true,
              replaceBy: template.replaceBy || "",
              fontName: template.fontName || "Arial",
              fontSize: template.fontSize || 10,
              foreColor: template.fontColor || "#e74c3c",
              backColor: template.extractColor || "#ffffff",
            },
          };
        });
      } else {
        setReplaceState((cur) => {
          return {
            ...cur,
            replaceBoxParams: {
              ...cur.replaceBoxParams,
              isReplaceTemplate: false,
            },
          };
        });
      }

      if (replaceObj.id) {
        return setModalContainerState({
          ...modalContainer,
          replaceBoxModalState: {
            openReplaceBoxModal: state || "",
            replaceId: replaceObj.id || "",
            boxId: "",
          },
        });
      }
    }, 300);
  };
  const items = [
    {
      key: "selectAll",
      name: `${
        replaceObject?.replaceType === 0 ? tr("replaceText") : tr("replaceBox")
      }`,
      onClick: (ev) => {
        hideMenu();
      },
      subMenuProps: {
        items: [
          {
            key: "mniEditReplace",
            name: tr("edit") + " " + tr("replace"),
            iconProps: {},
            onRenderIcon: () => {
              return <Edit20Regular></Edit20Regular>;
            },
            onClick: (ev) => {
              hideMenu();
              if (replaceObject?.replaceType === 0) {
                onEditReplace(null);
              } else {
                editReplaceBoxModal("edit", replaceObject, null);
              }
            },
          },
          ...genTreeReplaceTemplateMenu({
            onClick: (ev, item) => {
              hideMenu();
              if (replaceObject?.replaceType === 0) {
                onEditReplace(item);
              } else {
                editReplaceBoxModal("edit", replaceObject, item);
              }
            },
          }),
          {
            key: "mniReplaceNew",
            name: tr("newTemplate"),
            iconProps: {},
            onClick: (ev) => {
              hideMenu();
              showReplaceTemplateList();
            },
          },
        ],
      },
    },
    {
      key: "remove",
      name: tr("deleteReplace"),
      onClick: (ev) => {
        hideMenu();
        setDialogState({
          ...dialog,
          MsgDialog: tr("deleteSelectedReplace"),
          CallBackConfirmDialog: {
            action: deleteReplace,
            param: replaceObject,
          },
          Type: "ConfirmationDialog",
        });
      },
    },
  ] as IContextualMenuItem[];
  return <ContextualMenu target={targetPoint} items={items} />;
};
export default () => {
  const { tr } = useLang();
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const [modalContainer, setModalContainerState] =
    useRecoilState(modalContainerState);
  const [replaceCurrentState, setReplaceState] = useRecoilState(replaceState);
  const { RefreshReplaceWord } = replaceService();
  const [docuviewareMain, setDocuviewareMain] =
    useRecoilState<DocuVieware>(docuviewareMainState);

  const COLUMNS: IColumn[] = [
    {
      key: "originalWords",
      name: tr("originalWords"),
      fieldName: "originalWords",
      minWidth: 100,
      isResizable: true,
      onRender: (item: Api.ReplaceObj) => {
        return <div title={`${item.originalWords}`}>{item.originalWords}</div>;
      },
    },
    {
      key: "replaceby",
      name: tr("replaceBy"),
      fieldName: "replaceby",
      minWidth: 100,
      isResizable: true,
      onRender: (item: Api.ReplaceObj) => {
        return (
          <div
            title={`${item.replaceBy}`}
            style={{
              display: "flex",
              textAlign: "center",
              width: "100%",
              justifyContent: "center",
              backgroundColor: item.extractColor as string,
              height: "100%",
              alignItems: "center",
            }}
          >
            <label
              style={{
                color: item.fontColor as string,
                fontWeight: "bolder",
                textAlign: "center",
              }}
            >
              {item.replaceBy}
            </label>
          </div>
        );
      },
    },
    {
      key: "Count",
      name: tr("count"),
      fieldName: "Count",
      minWidth: 64,
      maxWidth: 64,
      isResizable: true,
      onRender: (item: Api.ReplaceObjDisplay) => {
        return <div style={{ paddingLeft: 12 }}>{item.cCount}</div>;
      },
    },
  ];
  const [columns, setColumns] = useState<IColumn[]>(COLUMNS);
  const [itemDelete, setItemDelete] = React.useState<Api.ReplaceObj | null>(
    null
  );
  const onOpenReplaceModal = (): void => {
    setReplaceState((cur) => {
      return {
        ...cur,
        replacementParams: {
          ...cur.replacementParams,
          rectangleWords: null,
          isReplaceFromWatchDog: false,
          sortKeys: null,
          replaceBy: "",
          originalWords: "",
          isReplaceAll: true,
          onlyReplaceAll: true,
          isReplaceFromSearch: false,
          isReplaceTemplate: false,
          matchCase: false,
          wholeWord: false,
          isFocusWordHightLight: false,
        },
        editReplacement: null,
        isOpenCreateEditReplacement: true,
      };
    });
  };
  const deleteAllReplace = () => {
    setDialogState({
      ...dialog,
      MsgDialog: tr("youAreAboutToRemoveAllHighlightAndReplaces"),
      CallBackConfirmDialog: {
        action: () => {
          DeleteAllReplaceCommand(DataCache.docuViewareID(), () => {
            setTimeout(() => {
              RefreshReplaceWord(replaceCurrentState);
            }, 300);
          });
        },
      },
      Type: "ConfirmationDialog",
    });
  };
  const showReplaceTemplateList = () => {
    setModalContainerState({
      ...modalContainer,
      showReplaceTemplate: { isShow: true },
    });
  };

  const _onColumnClick = (
    listItem: any[],
    event: React.MouseEvent<HTMLElement> | undefined,
    column: IColumn | undefined
  ): void => {
    if (!column) return;
    let isSortedDescending = column.isSortedDescending;
    // If we've sorted this column, flip it.
    if (column.isSorted) {
      isSortedDescending = !isSortedDescending;
    }
    // Sort the items.
    const key1 = `{"${column.key}" : ${
      isSortedDescending ? '"desc"' : '"asc"'
    }}`;
    const arr = multiSort(listItem, JSON.parse(key1));
    setReplaceState((cur) => {
      return {
        ...cur,
        replaceWords: arr,
        isOpenCreateEditReplacement: false,
        sort: key1,
      };
    });
    const arrcolumn = columns.map((item) => {
      if (item) {
        item.isSorted = item.key === column.key;
        if (item.isSorted) {
          item.isSortedDescending = isSortedDescending;
        }
      }
      return item;
    });
    // Reset the items and columns to match the state.
    setColumns(arrcolumn);
  };
  useEffect(() => {
    if ((docuviewareMain?.DocuviewareInit?.tabDetails || []).length > 0) {
      RefreshReplaceWord(replaceCurrentState);
    }
  }, []);
  const [IsShowContextMenu, { toggle: SetIsShowContextMenu }] =
    useBoolean(false);
  const [targetPoint, SetTargetPoint] = useState<any>();
  const [selection] = useState<Selection>(
    new Selection({
      onSelectionChanged: () => {
        const items = selection.getSelection();
        if (!items) return;
        const selectedItems = items.map((o: any) => {
          return o as Api.ReplaceObj;
        });
        if (selectedItems.length > 0) {
          setItemDelete(selectedItems[0]);
        }
      },
    })
  );
  return (
    <Stack
      verticalAlign="center"
      tokens={{ childrenGap: 15 }}
      style={{ height: "100%" }}
    >
      <div className="header-left-panel">{tr("replace")}</div>
      <div>
        <PrimaryButton
          style={{ minWidth: 120, float: "left" }}
          text={tr("addReplaceWords")}
          onClick={onOpenReplaceModal}
          allowDisabledFocus
        />
        <PrimaryButton
          style={{ minWidth: 120, float: "right" }}
          text={tr("template")}
          onClick={showReplaceTemplateList}
          allowDisabledFocus
        />
      </div>

      <StyledContainer
        onContextMenu={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          SetIsShowContextMenu();
          SetTargetPoint({ x: ev.clientX, y: ev.clientY });
        }}
      >
        <DetailsList
          className="replaceList"
          items={replaceCurrentState.replaceWords}
          columns={columns}
          selectionMode={SelectionMode.single}
          selection={selection}
          onColumnHeaderClick={(ev, col) => {
            _onColumnClick(replaceCurrentState.replaceWords, ev, col);
          }}
          setKey="none"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          checkboxVisibility={CheckboxVisibility.hidden}
        />
        {IsShowContextMenu && (
          <MenuContext
            hideMenu={() => {
              SetIsShowContextMenu();
            }}
            targetPoint={targetPoint}
            replaceObject={itemDelete || undefined}
          />
        )}
      </StyledContainer>
      <PrimaryButton
        style={{ minWidth: "100%", float: "left" }}
        text={tr("removeAllReplace")}
        onClick={deleteAllReplace}
        allowDisabledFocus
      />
    </Stack>
  );
};
