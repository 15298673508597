import {
  Callout,
  Checkbox,
  CheckboxVisibility,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  DirectionalHint,
  IColumn,
  IconButton,
  Link,
  PrimaryButton,
  SelectionMode,
  Stack,
} from "@fluentui/react";
import { ITooltipHostStyles, TooltipHost } from "@fluentui/react/lib/Tooltip";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useLang } from "../../../i18n/useLang";
import { pageSelectedState, searchState } from "../../../recoil";
import { Selection } from "@fluentui/react/lib/DetailsList";
import { LocalStorageKey, SearchTypeKey } from "../../../config/constant";
import {
  CustomSearchTemplate,
  TemplateItem,
} from "../../../recoil/searchState";
import { useBoolean } from "@fluentui/react-hooks";
import { ListCustomTemplate } from "../../../modal/ListCustomTemplate";
import { get, set } from "lodash";
import { UserConfigApp } from "../../../ApiV2";
interface TemplateSearchProps {
  heightList: number;
}
export const TemplateSearchDefault = (props: TemplateSearchProps) => {
  const [searchCurrentState, setSearchState] = useRecoilState(searchState);
  const [TemplateSearchSelecteds, setTemplateSearchSelecteds] = React.useState<
    TemplateItem[]
  >(searchCurrentState.searchingTemplateParams.templatesSelected || []);
  const { tr } = useLang();
  const getIsFull = (item: TemplateItem) => {
    const mapping: { [key: string]: string } = {
      Cpr: tr("module11"),
      Bsn: tr("module97"),
      Nrn: tr("module11"),
      Name: tr("firstName"),
      Address: tr("fullAddress"),
      CompanyName: tr("companyForm"),
    };
    const name = mapping[item.templateKey || ""] || item.templateKey || "";
    if (
      item.templateKey === SearchTypeKey.Name ||
      item.templateKey === SearchTypeKey.Address ||
      item.templateKey === SearchTypeKey.Cpr ||
      item.templateKey === SearchTypeKey.Bsn ||
      item.templateKey === SearchTypeKey.CompanyName ||
      item.templateKey === SearchTypeKey.Nrn
    ) {
      return (
        <>
          <Checkbox
            label={name}
            disabled={!item.checked}
            checked={item.isFull}
            onChange={(e, value) => {
              setTemplateSearchSelecteds((cur) => {
                return cur.map((c) => {
                  if (c.templateKey === item.templateKey) {
                    return {
                      ...c,
                      isFull: value || false,
                    };
                  }
                  return c;
                });
              });
              setSearchState((cur) => {
                return {
                  ...cur,
                  searchingTemplateParams: {
                    ...cur.searchingTemplateParams,
                    templatesSelected:
                      cur.searchingTemplateParams.templatesSelected?.map(
                        (c) => {
                          if (c.templateKey === item.templateKey) {
                            return { ...c, isFull: value || false };
                          }
                          return c;
                        }
                      ),
                  },
                };
              });
            }}
          />
        </>
      );
    }
    return <></>;
  };
  const GetTemplateSearchNotSelected = (
    customTemplateItems: CustomSearchTemplate[],
    templateItem: TemplateItem[],
    templatesSelected: TemplateItem[]
  ) => {
    const customTemplateNotSelected = customTemplateItems.filter(
      (o) =>
        templatesSelected
          ?.filter((o) => o.isCustom)
          .map((o) => o.templateKey)
          .indexOf(o?.name || "") === -1
    );
    const templateItemNotSelected = templateItem.filter(
      (o) =>
        templatesSelected
          ?.filter((o) => !o.isCustom)
          ?.map((o) => o.templateKey)
          .indexOf(o.templateKey) === -1
    );
    return templateItemNotSelected.concat(
      customTemplateNotSelected.map((o) => {
        return {
          id: o.id || "",
          templateKey: o.name || "",
          checked: false,
          isFull: false,
          isCustom: true,
          value: o.pattern || "",
        };
      })
    );
  };
  const [TemplateSearchListNotSelected, setTemplateSearchListNotSelected] =
    React.useState<TemplateItem[]>([]);
  const columns: IColumn[] = [
    {
      key: "templateKey",
      name: "",
      fieldName: "templateKey",
      minWidth: 125,
      isRowHeader: true,
      isResizable: true,
      isPadded: true,
      onRender: (item: TemplateItem) => {
        return (
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ flex: "50%" }}>
              <Checkbox
                label={getDisplayText(item.templateKey || "")}
                checked={item.checked}
                onChange={(e, value) => {
                  setTemplateSearchSelecteds((cur) => {
                    return cur.map((c) => {
                      if (c.templateKey === item.templateKey) {
                        return {
                          ...c,
                          checked: value || false,
                          isFull: false,
                        };
                      }
                      return c;
                    });
                  });
                  setSearchState((cur) => {
                    return {
                      ...cur,
                      searchingTemplateParams: {
                        ...cur.searchingTemplateParams,
                        templatesSelected:
                          cur.searchingTemplateParams.templatesSelected?.map(
                            (c) => {
                              if (c.templateKey === item.templateKey) {
                                return {
                                  ...c,
                                  checked: value || false,
                                  isFull: false,
                                };
                              }
                              return c;
                            }
                          ),
                      },
                    };
                  });
                }}
              />
            </div>
            <div style={{ flex: "50%" }}>{getIsFull(item)}</div>
          </div>
        );
      },
    },
    {
      key: "Delete",
      name: "",
      fieldName: "Delete",
      minWidth: 10,
      onRender: (item: any) => {
        return (
          <IconButton
            iconProps={{ iconName: "Cancel" }}
            onClick={() => {
              setTemplateSearchSelecteds((cur) => {
                return cur.filter((c) => c.templateKey !== item.templateKey);
              });
              setSearchState((cur) => {
                return {
                  ...cur,
                  searchingTemplateParams: {
                    ...cur.searchingTemplateParams,
                    templatesSelected:
                      cur.searchingTemplateParams.templatesSelected?.filter(
                        (c) => c.templateKey !== item.templateKey
                      ),
                  },
                };
              });
            }}
            style={{ cursor: "pointer", fontSize: "15px" }}
          />
        );
      },
      isPadded: true,
    },
  ];
  const columnscallOut: IColumn[] = [
    {
      key: "templateKey",
      name: "",
      fieldName: "keyword",
      minWidth: 125,
      isRowHeader: true,
      isResizable: true,
      isPadded: true,
      onRender: (item: TemplateItem) => {
        return <>{getDisplayText(item.templateKey || "")}</>;
      },
    },
  ];
  function getKey(item: any): string {
    return item.key;
  }
  const [selection, setSelection] = useState<Selection>(new Selection());
  const getDisplayText = (key: string): string => {
    const mapping: { [key: string]: string } = {
      PhoneNumber: tr("phoneNumber"),
      Email: tr("email"),
      Diseases: tr("diseases"),
      Medication: tr("medications"),
      Currency: tr("prices"),
      Number: tr("number"),
      WebAddress: tr("webAddress"),
      AssistiveProduct: tr("assistiveProduct"),
      BelgianMunicipalities: "belgian municipalities",
      PoliceZones: "police zones",
      Date: tr("date"),
      Cvr: tr("cvr"),
      CompanyName: tr("companyName"),
      CreditCard: tr("creditCard"),
      Cpr: tr("cpr"),
      Bsn: "BSN (BE-ID)",
      Nrn: "NRN (NL-ID)",
      Name: tr("fullName"),
      Address: tr("address"),
      IsFullCpr: tr("module11"),
      IsFullBsn: tr("module97"),
      IsFullNrn: tr("module11"),
      IsFullName: tr("firstName"),
      IsFullAddress: tr("fullAddress"),
      BELicensePlates: tr("BE - License plates"),
      BEPoliceReport: tr("BE - Police report (PV)"),
      BECompanyID: tr("BE - Company ID"),
      BEHandelsregisternummer: tr("BE - Handelsregisternummer"),
    };

    return mapping[key] || key;
  };
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const [showListCustomTemplate, setShowListCustomTemplate] = useState({
    isShow: false,
  });
  const btnOK = () => {
    const itemSelectes = (selection.getSelection() || []) as TemplateItem[];
    setSearchState((cur) => {
      return {
        ...cur,
        searchingTemplateParams: {
          ...cur.searchingTemplateParams,
          templatesSelected: [
            ...(cur.searchingTemplateParams.templatesSelected || []),
            ...itemSelectes,
          ],
        },
      };
    });
    setTemplateSearchSelecteds((cur) => {
      return [...(cur || []), ...itemSelectes];
    });
    setSelection(new Selection());
    toggleIsCalloutVisible();
  };
  const sortTemplateSelected = () => {
    if (!TemplateSearchSelecteds || TemplateSearchSelecteds.length === 0)
      return [];
    let templateItems = TemplateSearchSelecteds.filter(
      (o) => o.isCustom === false
    );
    templateItems = templateItems.slice().sort((x, y) => {
      const xIndex =
        searchCurrentState.searchingTemplateParams.templateItems.findIndex(
          (o) => x.templateKey === o.templateKey
        );
      const yIndex =
        searchCurrentState.searchingTemplateParams.templateItems.findIndex(
          (o) => y.templateKey === o.templateKey
        );
      return xIndex - yIndex;
    });
    return [
      ...templateItems,
      ...TemplateSearchSelecteds.filter((o) => o.isCustom),
    ];
  };
  return (
    <div style={{ height: "100%" }}>
      <div
        className="ms-Grid-row"
        style={{ height: "calc(100% - 44px)", width: "100%" }}
      >
        <div
          className="ms-Grid-col ms-sm12"
          style={{ height: props.heightList, overflow: "auto" }}
        >
          <DetailsList
            styles={{ root: { overflowX: "hidden" } }}
            className="templateSearchList"
            items={sortTemplateSelected()}
            columns={columns}
            selectionMode={SelectionMode.multiple}
            layoutMode={DetailsListLayoutMode.justified}
            getKey={getKey}
            isHeaderVisible={false}
            checkboxVisibility={CheckboxVisibility.hidden}
          />
        </div>
      </div>
      <div className="ms-Grid-row mg-top15">
        <div className="ms-Grid-col ms-sm6">
          <DefaultButton
            text={tr("customTemplate")}
            style={{ width: "100%" }}
            onClick={() => {
              setShowListCustomTemplate({ isShow: true });
            }}
          />
        </div>
        <div className="ms-Grid-col ms-sm6">
          <DefaultButton
            text={tr("addTemplate")}
            id="addTemplate"
            style={{ width: "100%" }}
            onClick={() => {
              setTemplateSearchListNotSelected(
                GetTemplateSearchNotSelected(
                  searchCurrentState.searchingTemplateParams
                    .customTemplateItems,
                  searchCurrentState.searchingTemplateParams.templateItems,
                  searchCurrentState.searchingTemplateParams
                    .templatesSelected || []
                )
              );
              toggleIsCalloutVisible();
            }}
          />
          {isCalloutVisible ? (
            <Callout
              role="dialog"
              style={{
                width: 320,
                padding: "20px 24px",
              }}
              gapSpace={0}
              target={`#addTemplate`}
              isBeakVisible={true}
              beakWidth={10}
              onDismiss={toggleIsCalloutVisible}
              directionalHint={DirectionalHint.bottomLeftEdge}
              setInitialFocus
            >
              <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                <div className="ms-Grid-row">
                  <div
                    className="ms-Grid-col ms-sm12"
                    style={{ height: 200, overflow: "auto" }}
                  >
                    <DetailsList
                      styles={{ root: { overflowX: "hidden" } }}
                      className="templateSearchList"
                      items={TemplateSearchListNotSelected}
                      columns={columnscallOut}
                      selectionMode={SelectionMode.multiple}
                      layoutMode={DetailsListLayoutMode.justified}
                      getKey={getKey}
                      isHeaderVisible={true}
                      selection={selection}
                      checkboxVisibility={CheckboxVisibility.always}
                      // onRenderCheckbox={(props) => {
                      //   return <Checkbox {...props} />;
                      // }}
                    />
                  </div>
                </div>
                <div className="ms-Grid-row">
                  <div
                    className="ms-Grid-col ms-sm12"
                    style={{ textAlign: "right" }}
                  >
                    <DefaultButton
                      text={tr("cancel")}
                      onClick={toggleIsCalloutVisible}
                      style={{ width: "60px", marginRight: "10px" }}
                    />
                    <PrimaryButton
                      text={tr("ok")}
                      onClick={btnOK}
                      style={{ width: "60px" }}
                    ></PrimaryButton>
                  </div>
                </div>
              </Stack>
            </Callout>
          ) : null}
        </div>
      </div>
      {showListCustomTemplate.isShow && (
        <ListCustomTemplate
          isShow={showListCustomTemplate.isShow}
          onClose={() => {
            setShowListCustomTemplate({ isShow: false });
          }}
          onSave={(datas) => {
            const stateTemplateSelected = JSON.parse(
              JSON.stringify(
                searchCurrentState.searchingTemplateParams.templatesSelected
              )
            ) as TemplateItem[];
            stateTemplateSelected
              ?.filter((o) => o.isCustom)
              .forEach((o) => {
                const obj = datas.find((x) => x.id === o.id);
                if (obj) {
                  o.templateKey = obj.name;
                  o.value = obj.pattern;
                }
              });
            const newTemplateItemsSelected = stateTemplateSelected.filter(
              (o) =>
                o.isCustom == false ||
                (o.isCustom && datas.findIndex((x) => x.id == o.id) > -1)
            );
            setSearchState((cur) => {
              return {
                ...cur,
                searchingTemplateParams: {
                  ...cur.searchingTemplateParams,
                  customTemplateItems: datas,
                  templatesSelected: [...newTemplateItemsSelected],
                },
              };
            });
            setTemplateSearchSelecteds([...newTemplateItemsSelected]);
            LocalStorageKey.SetItem(
              {
                ...LocalStorageKey.GetOjectItem<UserConfigApp>(
                  LocalStorageKey.UserConfigApp
                ),
                customTemplates: datas,
              } as UserConfigApp,
              LocalStorageKey.UserConfigApp
            );
          }}
        ></ListCustomTemplate>
      )}
    </div>
  );
};
