import React, { useEffect, useState } from "react";
import { useLang } from "../../../i18n/useLang";

import {
  Checkbox,
  ChoiceGroup,
  ContextualMenu,
  IContextualMenuItem,
  Pivot,
  PivotItem,
  PrimaryButton,
  Stack,
  StackItem,
} from "@fluentui/react";
import { useRecoilState } from "recoil";
import { LocalStorageKey } from "../../../config/constant";
import {
  dialogState,
  modalContainerState,
  pageSelectedState,
  replaceState,
  searchState,
} from "../../../recoil";
import { replaceService, searchService } from "../../../service";
import { documentService } from "../../../service/document";
import TagSearchOptions from "./TagSearchOptions";
import { TemplateSearch } from "./templateSearch";
import MainSearchOptions from "./mainSearchOptions";
import SearchResultComponent from "./searchResult";
import { userService } from "../../../service/userService";
import SplitPane from "split-pane-react";
import * as Api from "../../../ApiV2";
import { genTreeReplaceTemplateMenu } from "../replace/replace-panel";
import { Edit20Regular } from "@fluentui/react-icons";
import { useBoolean } from "@fluentui/react-hooks";
export default () => {
  const [searchCurrentState, setSearchState] = useRecoilState(searchState);
  const { onSearchWord } = searchService();
  const { tr } = useLang();
  const [pageState] = useRecoilState(pageSelectedState);

  useEffect(() => {
    let value = null;
    switch (searchCurrentState.searchType) {
      case "SearchTemplate":
        value = localStorage.getItem(LocalStorageKey.TemplateSearch);
        if (value) {
          setSearchState({
            ...searchCurrentState,
            searchingTemplateParams: JSON.parse(value),
          });
        }
        break;
      case "SearchTag": {
        value = localStorage.getItem(LocalStorageKey.TagSearch);
        if (value) {
          setSearchState({
            ...searchCurrentState,
            searchTagParams: JSON.parse(value),
          });
        }
        break;
      }

      default: {
        value = localStorage.getItem(LocalStorageKey.KeywordSearch);
        if (value && !searchCurrentState.searchingKeywordParams.keyword) {
          setSearchState({
            ...searchCurrentState,
            searchingKeywordParams: JSON.parse(value),
          });
        }
        break;
      }
    }
    if (
      searchCurrentState.searchTabVm.autoSearch &&
      (searchCurrentState.searchingKeywordParams.ForceSearchWhenInitialized ==
        undefined ||
        !searchCurrentState.searchingKeywordParams.ForceSearchWhenInitialized)
    ) {
      onSearchWord(pageState.currentPage);
    }
    setTimeout(() => {
      documentService.CalculateHeightSearchResult();
    }, 0);
  }, [searchCurrentState.searchType]);

  useEffect(() => {
    localStorage.setItem(
      LocalStorageKey.SearchTabVm,
      JSON.stringify(searchCurrentState.searchTabVm)
    );
  }, [searchCurrentState.searchTabVm]);
  useEffect(() => {
    onSearchWord(pageState.currentPage, null);
    window.addEventListener("resize", () => {
      documentService.CalculateHeightSearchResult();
    });
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);
  const [sizes, setSizes] = useState<(number | string)[]>([200, "auto"]);
  return (
    <>
      <div className="header-left-panel">{tr("find")}</div>
      <Stack
        style={{
          height: "100%",
          overflowY: "auto",
          marginBottom: 15,
          display: "block",
        }}
        className="searchtab"
        verticalAlign="center"
        tokens={{ childrenGap: 10 }}
      >
        <Pivot
          onLinkClick={(e) => {
            setSearchState({
              ...searchCurrentState,
              searchType: e?.props.itemKey || "SearchKeyword",
            });
          }}
          onClick={(e) => {
            // @ts-ignore
            if (e.target.parentElement.className.indexOf("ms-Pivot") > -1) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          selectedKey={searchCurrentState.searchType}
          style={{
            height: `${
              searchCurrentState.searchType === "SearchTemplate"
                ? "calc(100% - 49px)"
                : "auto"
            }`,
          }}
        >
          <PivotItem
            className={"param-search SearchKeyword"}
            headerText={tr("keywordSearch")}
            itemKey={"SearchKeyword"}
            headerButtonProps={{
              "data-order": 1,
            }}
          >
            <MainSearchOptions
              searchingKeywordParams={searchCurrentState.searchingKeywordParams}
              setSearchingKeywordParams={(obj) => {
                setSearchState({
                  ...searchCurrentState,
                  searchingKeywordParams: obj,
                });
              }}
              key={"main-search-options"}
            />
            {searchCurrentState.searchType === "SearchKeyword" && (
              <SearchResult></SearchResult>
            )}
          </PivotItem>
          <PivotItem
            className={"param-search SearchTemplate"}
            headerText={tr("templateSearch")}
            itemKey={"SearchTemplate"}
            style={{ height: "100%" }}
          >
            <SplitPane
              split="horizontal"
              sizes={sizes}
              onChange={(sizes) => {
                setSizes(sizes);
                documentService.CalculateHeightSearchResult();
              }}
              sashRender={(sashProps) => {
                return (
                  <div
                    style={{
                      backgroundColor: "red",
                      width: 10,
                      cursor: "col-resize",
                    }}
                  />
                );
              }}
            >
              <TemplateSearch
                heightList={(sizes[0] as number) - 60}
                key={
                  searchCurrentState.searchingKeywordParams
                    .ForceSearchWhenInitialized
                    ? new Date().getTime()
                    : "custom-search-component"
                }
              />
              {(searchCurrentState.searchType === "SearchTemplate" && (
                <SearchResult></SearchResult>
              )) || <></>}
            </SplitPane>
          </PivotItem>
          <PivotItem
            className={"param-search SearchTag"}
            headerText={tr("tagSearch")}
            itemKey={"SearchTag"}
          >
            <TagSearchOptions />
            {searchCurrentState.searchType === "SearchTag" && (
              <SearchResult></SearchResult>
            )}
          </PivotItem>
        </Pivot>
      </Stack>
    </>
  );
};
export const SearchResult = () => {
  const [IsShowContextMenu, SetIsShowContextMenu] = useState({
    isShow: false,
    targetPoint: { x: 0, y: 0 },
  });
  const [searchCurrentState, setSearchState] = useRecoilState(searchState);
  const [replaceCurrentState, setReplaceState] = useRecoilState(replaceState);
  const { onSearchWord } = searchService();
  const { tr } = useLang();
  const [pageState] = useRecoilState(pageSelectedState);
  const { getUser } = userService();
  const checkUserBelgiumCountry = () => {
    const user = getUser();
    return user?.userDataLogin?.result?.user_info?.countryCode == "BEL";
  };
  useEffect(() => {
    if (searchCurrentState.searchingKeywordParams.ForceSearchWhenInitialized) {
      onSearchWord(pageState.currentPage);
      setSearchState((cur) => {
        return {
          ...cur,
          searchingKeywordParams: {
            ...cur.searchingKeywordParams,
            ForceSearchWhenInitialized: false,
          },
        };
      });
    }
  }, [searchCurrentState.searchingKeywordParams.ForceSearchWhenInitialized]);
  useEffect(() => {
    // if (
    //   !searchCurrentState.searchingKeywordParams.ForceSearchWhenInitialized &&
    //   searchCurrentState.searchTabVm.autoSearch
    // )
    //   onSearchWord(pageState.currentPage, null);
  }, []);
  // useEffect(() => {
  //   let value = "";
  //   switch (searchCurrentState.searchType) {
  //     case "SearchTemplate":
  //       value = localStorage.getItem(LocalStorageKey.TemplateSearch) || "";
  //       if (value) {
  //         setSearchState((cur) => {
  //           return {
  //             ...cur,
  //             searchingTemplateParams: JSON.parse(value as string),
  //           };
  //         });
  //       }
  //       break;
  //     case "SearchTag": {
  //       value = localStorage.getItem(LocalStorageKey.TagSearch) || "";
  //       if (value) {
  //         setSearchState((cur) => {
  //           return {
  //             ...cur,
  //             searchTagParams: JSON.parse(value as string),
  //           };
  //         });
  //       }
  //       break;
  //     }

  //     default: {
  //       value = localStorage.getItem(LocalStorageKey.KeywordSearch) || "";
  //       if (value && !searchCurrentState.searchingKeywordParams.keyword) {
  //         setSearchState((cur) => {
  //           return {
  //             ...cur,
  //             searchingKeywordParams: JSON.parse(value as string),
  //           };
  //         });
  //       }
  //       break;
  //     }
  //   }
  //   if (
  //     searchCurrentState.searchTabVm.autoSearch &&
  //     (searchCurrentState.searchingKeywordParams.ForceSearchWhenInitialized ==
  //       undefined ||
  //       !searchCurrentState.searchingKeywordParams.ForceSearchWhenInitialized)
  //   ) {
  //     onSearchWord(pageState.currentPage);
  //   }
  // }, [searchCurrentState.searchType]);

  const onShowReplaceModal = () => {
    if (
      !searchCurrentState.searchResult?.searchData ||
      searchCurrentState.searchResult?.searchData?.length == 0
    )
      return;
    const itemSelecteds = searchCurrentState.searchResult?.searchData
      ?.filter((o) => o.checked == true)
      .map((o) => o.sortKey || 0);
    setReplaceState((cur) => {
      return {
        ...cur,
        replacementParams: {
          ...cur.replacementParams,
          originalWords: "",
          replaceBy: "",
          rectangleWords: null,
          onlyReplaceAll: false,
          isReplaceAll: true,
          isReplaceFromSearch: true,
          isFocusWordHightLight: false,
          sortKeys: itemSelecteds || [],
        },
        editReplacement: null,
        isOpenCreateEditReplacement: true,
      };
    });
    // if (!isReplaceAll) {
    //   const itemSelecteds = searchCurrentState.searchResult?.searchData
    //     ?.filter((o) => o.checked == true)
    //     .map((o) => o.sortKey || 0);
    //   if ((itemSelecteds?.length || 0) == 0) return;
    //   setReplaceState((cur) => {
    //     return {
    //       ...cur,
    //       replacementParams: {
    //         ...cur.replacementParams,
    //         originalWords: "",
    //         replaceBy: "",
    //         rectangleWords: null,
    //         onlyReplaceAll: true,
    //         isReplaceAll: true,
    //         isReplaceFromSearch: true,
    //         sortKeys: itemSelecteds || [],
    //       },
    //       isOpenCreateEditReplacement: true,
    //     };
    //   });
    // } else {
    //   if ((searchCurrentState.searchResult?.searchData?.length || 0) == 0)
    //     return;
    //   setReplaceState((cur) => {
    //     return {
    //       ...cur,
    //       replacementParams: {
    //         ...cur.replacementParams,
    //         originalWords: "",
    //         replaceBy: "",
    //         rectangleWords: null,
    //         onlyReplaceAll: true,
    //         isReplaceAll: true,
    //         isReplaceFromSearch: true,
    //         sortKeys: [],
    //       },
    //       isOpenCreateEditReplacement: true,
    //     };
    //   });
    // }
  };
  useEffect(() => {
    localStorage.setItem(
      LocalStorageKey.SearchTabVm,
      JSON.stringify(searchCurrentState.searchTabVm)
    );
  }, [searchCurrentState.searchTabVm]);

  const checkSearchWildcard = () => {
    if (
      searchCurrentState.searchType == "SearchKeyword" &&
      searchCurrentState.searchingKeywordParams?.wildCard &&
      ((searchCurrentState.searchingKeywordParams.keyword || "").indexOf("*") >=
        0 ||
        (searchCurrentState.searchingKeywordParams.keyword || "").indexOf(
          "?"
        ) >= 0)
    ) {
      setSearchState({
        ...searchCurrentState,
        searchingKeywordParams: {
          ...searchCurrentState.searchingKeywordParams,
          wholeWord: true,
          highlightWholeWord: true,
        },
      });
    }
  };
  return (
    <Stack
      style={{ marginTop: 10 }}
      verticalAlign="center"
      tokens={{ childrenGap: 10 }}
    >
      <div className="ms-Grid" dir="ltr">
        <Stack verticalAlign="center" tokens={{ childrenGap: 5 }}>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm6 ">
              <ChoiceGroup
                disabled={pageState.currentPage <= 0}
                onChange={(event, option) => {
                  setSearchState({
                    ...searchCurrentState,
                    searchTabVm: {
                      ...searchCurrentState.searchTabVm,
                      allPages: false,
                    },
                  });
                }}
                selectedKey={searchCurrentState.searchTabVm.allPages ? "" : "A"}
                options={[
                  {
                    key: "A",
                    text: tr("currentPage"),
                    onRenderField: (props, render) => {
                      return (
                        <div className={"option-left"}>{render!(props)}</div>
                      );
                    },
                  },
                ]}
                required={true}
              />
            </div>
            <div className="ms-Grid-col ms-sm6 ">
              <ChoiceGroup
                disabled={pageState.currentPage <= 0}
                onChange={(event, option) => {
                  setSearchState({
                    ...searchCurrentState,
                    searchTabVm: {
                      ...searchCurrentState.searchTabVm,
                      allPages: true,
                    },
                  });
                }}
                selectedKey={searchCurrentState.searchTabVm.allPages ? "B" : ""}
                options={[
                  {
                    key: "B",
                    text: tr("allPages"),
                  },
                ]}
                required={true}
              />
            </div>
          </div>
          <div className="ms-Grid-row">
            <div
              className="ms-Grid-col ms-sm6 "
              style={{ display: "flex", alignItems: "baseline" }}
            >
              {!checkUserBelgiumCountry() && (
                <Checkbox
                  label={tr("accuracy")}
                  disabled={pageState.currentPage <= 0}
                  checked={searchCurrentState.searchTabVm.accuracy}
                  onChange={(ev, checked) => {
                    setSearchState({
                      ...searchCurrentState,
                      searchTabVm: {
                        ...searchCurrentState.searchTabVm,
                        accuracy: checked,
                      },
                    });
                  }}
                />
              )}
            </div>
            <div className="ms-Grid-col ms-sm6 ">
              <Checkbox
                label={tr("autoSearch")}
                checked={searchCurrentState.searchTabVm.autoSearch}
                disabled={pageState.currentPage <= 0}
                onChange={(ev, checked) => {
                  setSearchState({
                    ...searchCurrentState,
                    searchTabVm: {
                      ...searchCurrentState.searchTabVm,
                      autoSearch: checked,
                    },
                  });
                }}
              />
            </div>
          </div>
        </Stack>
      </div>

      <StackItem>
        <PrimaryButton
          style={{ width: "100%" }}
          text={tr("search")}
          disabled={pageState.currentPage <= 0}
          onClick={() => {
            checkSearchWildcard();
            setTimeout(() => {
              onSearchWord(pageState.currentPage);
            }, 300);
          }}
        />
      </StackItem>

      <SearchResultComponent />
      <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
        <PrimaryButton
          text={tr("replace")}
          style={{ width: "100%" }}
          disabled={pageState.currentPage <= 0}
          type={"button"}
          onClick={() => {
            onShowReplaceModal();
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            SetIsShowContextMenu({
              isShow: true,
              targetPoint: { x: e.clientX, y: e.clientY },
            });
          }}
        />
        {IsShowContextMenu.isShow && (
          <MenuContext
            targetPoint={IsShowContextMenu.targetPoint}
            hideMenu={() => {
              SetIsShowContextMenu({
                isShow: false,
                targetPoint: { x: 0, y: 0 },
              });
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};
const MenuContext = ({
  targetPoint,
  hideMenu,
}: {
  targetPoint: any;
  hideMenu: () => void;
}) => {
  const [, setReplaceState] = useRecoilState(replaceState);
  const [modalContainer, setModalContainerState] =
    useRecoilState(modalContainerState);
  const showReplaceTemplateList = () => {
    setModalContainerState({
      ...modalContainer,
      showReplaceTemplate: { isShow: true },
    });
  };
  const [searchCurrentState] = useRecoilState(searchState);
  const onReplace = async (
    replaceTemplace: Api.ReplaceTemplate | null = null
  ) => {
    if (
      !searchCurrentState.searchResult?.searchData ||
      searchCurrentState.searchResult?.searchData?.length == 0
    )
      return;
    const itemSelecteds = searchCurrentState.searchResult?.searchData
      ?.filter((o) => o.checked == true)
      .map((o) => o.sortKey || 0);
    if (replaceTemplace) {
      setReplaceState((cur) => {
        return {
          ...cur,
          replacementParams: {
            ...cur.replacementParams,
            onlyReplaceAll: false,
            isReplaceAll: true,
            isReplaceFromSearch: true,
            isFocusWordHightLight: false,
            isReplaceOrHightLightSelected: false,
            rectangleWords: null,
            isReplaceFromWatchDog: false,
            extractColor: replaceTemplace.extractColor || "#ffffff",
            fontColor: replaceTemplace.fontColor || "#e74c3c",
            fontSize: replaceTemplace.fontSize || 10,
            fontName: replaceTemplace.fontName || "Arial",
            replaceBy: replaceTemplace.replaceBy || "",
            originalWords: "",
            sortKeys: itemSelecteds,
          },
          editReplacement: null,
          isOpenCreateEditReplacement: true,
        };
      });
    } else {
      setReplaceState((cur) => {
        return {
          ...cur,
          replacementParams: {
            ...cur.replacementParams,
            onlyReplaceAll: false,
            isReplaceAll: true,
            isReplaceFromSearch: true,
            isFocusWordHightLight: false,
            isReplaceOrHightLightSelected: false,
            rectangleWords: null,
            isReplaceFromWatchDog: false,
            replaceBy: "",
            originalWords: "",
            sortKeys: itemSelecteds,
          },
          editReplacement: null,
          isOpenCreateEditReplacement: true,
        };
      });
    }
  };
  const { tr } = useLang();
  useEffect(() => {
    document.addEventListener("mousedown", hideMenu);
    return () => {
      document.removeEventListener("mousedown", hideMenu);
    };
  }, []);

  const items = [
    ...genTreeReplaceTemplateMenu({
      onClick: (ev, item) => {
        hideMenu();
        onReplace(item);
      },
    }),
    {
      key: "mniReplaceNew",
      name: tr("newTemplate"),
      iconProps: {},
      onClick: (ev) => {
        hideMenu();
        showReplaceTemplateList();
      },
    },
  ] as IContextualMenuItem[];
  return <ContextualMenu target={targetPoint} items={items} />;
};
