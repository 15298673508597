import { useLang } from "../../../i18n/useLang";
import {
  Checkbox,
  ContextualMenu,
  IContextualMenuItem,
  PrimaryButton,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import TreeBookmark from "./tree-bookmark";
import * as Api from "../../../ApiV2";
import { DataCache, LocalStorageKey } from "../../../config/constant";
import { documentService } from "../../../service/document";
import { GoToPositon } from "../../../utils/helpers";
import { StyleList } from "../../../assets/style/left-sidebar/bookmark-panel";
import { useRecoilState } from "recoil";
import { bookMarkService } from "../../../service";
import {
  bookMarkState,
  dialogState,
  docuviewareMainState,
  IPageState,
  pageSelectedState,
} from "../../../recoil";
import { SaveBookMarkModal } from "../../../modal/bookmark/ExportBookmarkModal";
import { SaveBookMarkSetting } from "../../../modal/bookmark/SaveBookMarkSetting";
const BookMarkApi = new Api.BookMarkApi();
export default () => {
  const [pageState] = useRecoilState<IPageState>(pageSelectedState);
  const [bookMark, setBookMarkState] = useRecoilState(bookMarkState);

  const { tr } = useLang();
  const timeOutClickItem = React.useRef<any>();
  const ClickItem = React.useRef<boolean>();
  const { getBookMarkList } = bookMarkService();
  const [IsShowModal, { toggle: SetIsShowModal }] = useBoolean(false);
  const [IsShowSettingModal, { toggle: SetIsShowSettingModal }] =
    useBoolean(false);
  const [dataForm, setDataForm] = useState({
    ShowBookMark: false,
  });
  React.useEffect(() => {
    getBookMarkList();
    const userConfigData = LocalStorageKey.GetOjectItem<Api.UserConfigApp>(
      LocalStorageKey.UserConfigApp
    );
    setDataForm((cur) => {
      return {
        ...cur,
        ShowBookMark:
          userConfigData?.bookmarkSetting?.showBookmarkInPage || false,
      };
    });
  }, []);
  return (
    <StyleList>
      <div className="header-left-panel">{tr("bookMark")}</div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingTop: "10px",
          justifyContent: "flex-end",
        }}
      >
        <PrimaryButton
          iconProps={{ iconName: "Settings" }}
          onClick={() => {
            SetIsShowSettingModal();
          }}
        ></PrimaryButton>
        &nbsp; &nbsp;
        <PrimaryButton
          id="exportBookmark"
          text={tr("export")}
          onClick={() => {
            SetIsShowModal();
          }}
        ></PrimaryButton>
      </div>
      <div className="bookmark-list">
        <TreeBookmark
          Datas={bookMark.ListBookmark}
          selectedItem={null}
          onSelected={(item) => {}}
        ></TreeBookmark>
      </div>
      {IsShowModal && (
        <SaveBookMarkModal
          isShow={IsShowModal}
          onClose={() => {
            SetIsShowModal();
          }}
        ></SaveBookMarkModal>
      )}
      {IsShowSettingModal && (
        <SaveBookMarkSetting
          isShow={IsShowSettingModal}
          onClose={() => {
            SetIsShowSettingModal();
          }}
        ></SaveBookMarkSetting>
      )}
    </StyleList>
  );
};
