import {
  Checkbox,
  CheckboxVisibility,
  DetailsList,
  DirectionalHint,
  PrimaryButton,
  Stack,
  TooltipHost,
} from '@fluentui/react';
import { Selection } from '@fluentui/react/lib/DetailsList';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { format } from 'react-string-format';
import { useRecoilState } from 'recoil';
import * as Api from '../../../ApiV2';
import { StyledResultContainer } from '../../../assets/style/left-sidebar/searchTab';
import HighlightSearchCommand from '../../../command/hightlight-searchresult-command';
import { DataCache } from '../../../config/constant';
import LoaderSearch from '../../../control/loading/loading-search';
import { useLang } from '../../../i18n/useLang';
import { IPageState, leftSidebarState, pageSelectedState, searchState } from '../../../recoil';
import { FocusedText } from '../../../utils/helpers';
import { TabIndex } from '../left-sidebar-container';
import { documentService } from '../../../service/document';

const SearchApi = new Api.SearchApi();

let _currentPage = 0;
export default () => {
  const [leftSidebar, setLeftSidebarState] = useRecoilState(leftSidebarState);
  const [pageState, setPageState] = useRecoilState<IPageState>(pageSelectedState);
  const [focusedItem, setFocusedItem] = useState<Api.SearchResult>();
  const [searchCurrentState, setSearchState] = useRecoilState(searchState);

  const _searchResult = useRef<Api.SearchResultData | null>();
  const { tr } = useLang();
  const [selection, setSelection] = useState<Selection>(
    new Selection({
      onSelectionChanged: () => {
        const item = selection.getItems()[selection.getSelectedIndices()[0]];
        if (!item) return;
        setTimeout(() => {
          onSearchResultItemClicked(item as Api.SearchResult);
        }, 200);
      },
    }),
  );

  useEffect(() => {
    setSearchState({
      ...searchCurrentState,
      hasMore:
        (searchCurrentState.searchResult?.totalSearchResult || 0) >
        (searchCurrentState.searchResult?.searchData?.length || 0),
      searchingKeywordParams: {
        ...searchCurrentState.searchingKeywordParams,
        keyword: (searchCurrentState.searchingKeywordParams.keyword || '').trim(),
        ForceSearchWhenInitialized: false,
      },
    });
    _searchResult.current = searchCurrentState.searchResult;
  }, [searchCurrentState.searchResult]);
  useEffect(() => {
    _currentPage = pageState.currentPage;
  }, [pageState.currentPage]);
  const onSearchResultItemClicked = (item: Api.SearchResult) => {
    goToTextInPage(item);
  };
  const goToTextInPage = (item: Api.SearchResult) => {
    setFocusedItem(item);
    if (_currentPage == item.viewerPageNo) {
      FocusedText(item.rectangleFs || null, item.viewerPageNo, DataCache.docuViewareID() || '');
    } else {
      setPageState((cur) => {
        return {
          ...cur,
          rectangleFSelected: {
            rectangleSelections: item.rectangleFs || null,
            color: 'rgb(84 181 254 / 50%)',
            pageNo: item.viewerPageNo || 0,
          },
        }
      });
      DocuViewareAPI.SelectPage(DataCache.docuViewareID(), item.viewerPageNo);
    }
  };
  useEffect(() => {
    if (!focusedItem) {
      setPageState((cur) => {
        return {
          ...cur,
          rectangleFSelected: null,
        }
      });
    }
  }, [focusedItem]);
  const onChangeSelectAll = async (isSelectAll: boolean | undefined) => {
    if (isSelectAll == undefined) {
      isSelectAll = false;
    }
    HighlightSearchCommand(DataCache.docuViewareID(), { isHighlight: isSelectAll, isSelectAll: true }, (res: any) => {
      setLeftSidebarState((cur) => {
        return { ...cur, refreshTabActive: { IsRefresh: true, TabIndex: -1, ForceSearch: true } };
      });
    });
  };
  const onSearchResultCheckboxClicked = async (item: Api.SearchResult, refValue: boolean) => {
    // if (item.isReplace == true || !item) return;
    HighlightSearchCommand(
      DataCache.docuViewareID(),
      { isHighlight: refValue, sortKeys: [item.sortKey || 0] },
      (res: any) => {
        if (leftSidebar.lastPanelSelected === TabIndex.SearchTab) {
          setSearchState({
            ...searchCurrentState,
            searchResult: res,
          });
        }
      },
    );
    goToTextInPage(item);
  };
  const fetchMoreData = () => {
    setSearchState({
      ...searchCurrentState,
      showLoader: true,
      showCancelLoader: true,
    });
    const page = Math.round((searchCurrentState.searchResult?.searchData?.length || 0) / 500);
    SearchApi.apiLegalxtractGetDataSearchResultPagingGet(page + 1, 500).then((res) => {
      const data = res.data;
      if (data) {
        const oldData = searchCurrentState.searchResult?.searchData || [];
        const newData = [...oldData, ...(data.searchData || [])];
        setSearchState({
          ...searchCurrentState,
          showLoader: false,
          searchResult: {
            ...searchCurrentState.searchResult,
            searchData: newData,
            totalPage: data.totalPage || 0,
            totalSearchResult: data.totalSearchResult || 0,
          },
          showCancelLoader: false,
        });
      }
    });
  };

  return (
    <StyledResultContainer className="SearchResultContainer">
      <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
          <PrimaryButton
            style={{ width: '50%' }}
            text={tr('selectAll')}
            type={'button'}
            disabled={pageState.currentPage <= 0}
            onClick={() => {
              if (searchCurrentState.searchResult && (searchCurrentState.searchResult.searchData?.length || 0) > 0)
                onChangeSelectAll(true);
            }}
            className="btn-searchResult"
          />
          <PrimaryButton
            text={tr('deselectAll')}
            style={{ width: '50%' }}
            type={'button'}
            disabled={pageState.currentPage <= 0}
            onClick={() => {
              if (searchCurrentState.searchResult && (searchCurrentState.searchResult.searchData?.length || 0) > 0)
                onChangeSelectAll(false);
            }}
            className="btn-searchResult"
          />
        </Stack>
        <span>
          {format(
            tr('found0MatchesIn1PageS'),
            `${searchCurrentState.searchResult?.totalSearchResult || 0}`,
            `${searchCurrentState.searchResult?.totalPage || 0}`,
          )}
        </span>
        <div style={{ position: 'relative' }}>
          <LoaderSearch
            showCancelLoader={searchCurrentState.showCancelLoader}
            showLoader={searchCurrentState.showLoader}
            onClickCancel={() => {
              setSearchState({
                ...searchCurrentState,
                showLoader: false,
              });
              SearchApi.apiLegalxtractCancelSearchGet();
            }}
          ></LoaderSearch>
          <InfiniteScroll
            style={{ border: '1px #b2b2b2 solid', width: 'auto', position: 'relative', padding: '0 5px' }}
            dataLength={searchCurrentState.searchResult?.searchData?.length || 0}
            next={fetchMoreData}
            hasMore={searchCurrentState.hasMore}
            loader={''}
            height={300}
            endMessage={''}
          >
            <DetailsList
              isHeaderVisible={false}
              columns={[
                {
                  key: 'word',
                  name: tr('word'),
                  fieldName: 'Word',
                  minWidth: 75,
                  isRowHeader: true,
                  isResizable: true,
                  isPadded: true,
                  onRender: (item: Api.SearchResult) => {
                    if (!item.phrase || !item.highLightLength) return null;
                    const phrase = item.phrase;
                    const start = phrase.substr(0, item.highLightFromIdx).replace(/</g, '&lt;').replace(/>/g, '&gt;');
                    const mid = phrase
                      .substr(item.highLightFromIdx || 0, item.highLightLength)
                      .replace(/</g, '&lt;')
                      .replace(/>/g, '&gt;');
                    const end = phrase
                      .substr((item.highLightFromIdx || 0) + item.highLightLength)
                      .replace(/</g, '&lt;')
                      .replace(/>/g, '&gt;');
                    const result = `${start}<mark>${mid}</mark>${end}`;
                    return (
                      <>
                        <TooltipHost content={phrase} directionalHint={DirectionalHint.topCenter}>
                          <Checkbox
                            key={item.id}
                            checked={item.checked}
                            onChange={(event, value: any) => onSearchResultCheckboxClicked(item, value)}
                          />
                          <label
                            style={{
                              flex: 1,
                              display: 'inline-block',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                            dangerouslySetInnerHTML={{ __html: result }}
                          />
                        </TooltipHost>
                      </>
                    );
                  },
                },
              ]}
              items={searchCurrentState.searchResult?.searchData || []}
              checkboxVisibility={CheckboxVisibility.hidden}
              selection={selection}
            ></DetailsList>
          </InfiniteScroll>
        </div>
      </Stack>
    </StyledResultContainer>
  );
};
