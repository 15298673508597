import { fileSave } from "browser-fs-access";
import {
  Check,
  Checkbox,
  DefaultButton,
  Dropdown,
  IColor,
  IStackTokens,
  Label,
  Modal,
  PrimaryButton,
  SpinButton,
  Stack,
} from "@fluentui/react";
import * as React from "react";
import * as ApiV2 from "../../ApiV2";
import { ModalContent } from "../../assets/style/modal/modal";
import ModalHeader from "../../control/modalHeader";
import { useLang } from "../../i18n/useLang";
import { DataCache, LocalStorageKey } from "../../config/constant";
import { ColorPicker } from "../../control/ColorPicker";
import { ChangeBookmarkSettingCommand } from "../../command/Bookmark-command";
import { AppHelper } from "../../utils/AppHelper";
import { documentService } from "../../service/document";

interface SaveBookMarkSettingModalProps {
  isShow: boolean;
  onClose: () => void;
}

export const SaveBookMarkSetting = React.memo(
  (props: SaveBookMarkSettingModalProps) => {
    const { tr } = useLang();
    const [data, setData] = React.useState<ApiV2.BookmarkSetting>({
      color: "#ff000000",
      iconSize: 0.5,
      marginLeft: 1,
      marginTop: 1,
      exportBookmarkType: ApiV2.ExportBookmarkType.NUMBER_0,
      showBookmarkInPage: false,
      showDocumentBookmark: true,
    });
    React.useEffect(() => {
      const userConfigData = LocalStorageKey.GetOjectItem<ApiV2.UserConfigApp>(
        LocalStorageKey.UserConfigApp
      );
      if (userConfigData) {
        setData(userConfigData.bookmarkSetting || data);
      }
    }, [props.isShow]);
    const onSubmit = async () => {
      const userConfigData = LocalStorageKey.GetOjectItem<ApiV2.UserConfigApp>(
        LocalStorageKey.UserConfigApp
      );
      ChangeBookmarkSettingCommand(
        userConfigData?.bookmarkSetting || ({} as ApiV2.BookmarkSetting),
        data,
        () => {
          if (userConfigData) {
            userConfigData.bookmarkSetting = data;
            LocalStorageKey.SetItem(
              userConfigData,
              LocalStorageKey.UserConfigApp
            );
            documentService.RefreshPage(() => {},
            DataCache.docuViewareID() || "");
          }
        },
        () => {}
      );
      props.onClose();
    };
    const onCloseModal = () => {
      props.onClose();
    };
    const fileTypes = [
      { key: ApiV2.ExportBookmarkType.NUMBER_0, text: tr("Excel") },
      { key: ApiV2.ExportBookmarkType.NUMBER_1, text: tr("PDF") },
    ];
    function setValueField(
      value: string,
      fieldName: any,
      max: number,
      min: number
    ) {
      const obj = JSON.parse(JSON.stringify(data)) as ApiV2.BookmarkSetting;
      if (Number(value).toString() != "NaN") {
        // @ts-ignore
        obj[fieldName] = Number(value);
        if (Number(value) > max) {
          // @ts-ignore
          obj[fieldName] = max;
        }
        if (Number(value) < min) {
          // @ts-ignore
          obj[fieldName] = min;
        }
        setData({ ...obj });
      }
    }
    const onFontColorDismiss = (newColor: IColor) => {
      if (!newColor) return;
      let scolor = newColor.str;
      if (scolor.indexOf("rgba") > -1) {
        scolor = AppHelper.RgbaToArgb(scolor) || "#ff000000";
      }
      setData((cur) => {
        return {
          ...cur,
          color: scolor || "#ff000000",
        };
      });
    };
    return (
      <Modal isOpen={props.isShow} onDismiss={onCloseModal} isBlocking={true}>
        <ModalContent style={{ width: 550 }}>
          <div className="ms-Grid" dir="ltr">
            <Stack tokens={{ childrenGap: 5 }}>
              <ModalHeader title={tr("settings")} onCloseModal={onCloseModal} />
              <div className="ms-Grid" dir="ltr">
                <div
                  className="ms-Grid-row"
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <div className="ms-Grid-col ms-md4">
                    <Checkbox
                      checked={data.showBookmarkInPage}
                      onChange={(ev, checked) => {
                        setData((cur) => {
                          return {
                            ...cur,
                            showBookmarkInPage: checked || false,
                          };
                        });
                      }}
                      label={tr("showBookmark")}
                    ></Checkbox>
                  </div>
                  {data.showBookmarkInPage && (
                    <>
                      <div className="ms-Grid-col ms-md4">
                        <Label>{`${tr("iconSize")} (cm)`}</Label>
                        <SpinButton
                          value={`${data?.iconSize}`}
                          min={0.5}
                          max={100}
                          onIncrement={(value) => {
                            setData((cur) => {
                              return {
                                ...cur,
                                iconSize: +value + 1,
                              };
                            });
                          }}
                          onDecrement={(value) => {
                            if (Number(data?.iconSize) > 0) {
                              setData((cur) => {
                                return {
                                  ...cur,
                                  iconSize: +value - 1,
                                };
                              });
                            } else {
                              setData((cur) => {
                                return {
                                  ...cur,
                                  iconSize: 0.5,
                                };
                              });
                            }
                          }}
                          onBlur={(ev) => {
                            setValueField(
                              ev.target.value,
                              "iconSize",
                              100,
                              0.5
                            );
                          }}
                          incrementButtonAriaLabel={"Increase value by 1"}
                          decrementButtonAriaLabel={"Decrease value by 1"}
                        />
                      </div>
                      <div className="ms-Grid-col ms-md4">
                        <Label>{tr("color")}</Label>
                        <ColorPicker
                          color={AppHelper.ArgbToRgba(
                            data?.color || "#ff000000"
                          )}
                          onColorDismiss={(color) => {
                            onFontColorDismiss(color);
                          }}
                        ></ColorPicker>
                      </div>
                    </>
                  )}
                </div>
                {data.showBookmarkInPage && (
                  <>
                    <div
                      className="ms-Grid-row"
                      style={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <div className="ms-Grid-col ms-md4">
                        <Checkbox
                          checked={data.showDocumentBookmark}
                          onChange={(ev, checked) => {
                            setData((cur) => {
                              return {
                                ...cur,
                                showDocumentBookmark: checked || false,
                              };
                            });
                          }}
                          label={tr("showDocumentBookmark")}
                        ></Checkbox>
                      </div>
                      {data.showDocumentBookmark && (
                        <>
                          <div className="ms-Grid-col ms-md4">
                            <Label>{`${tr("margin")} (cm)`}</Label>
                            <SpinButton
                              value={`${data?.marginLeft}`}
                              min={0}
                              max={100}
                              onIncrement={(value) => {
                                setData((cur) => {
                                  return {
                                    ...cur,
                                    marginLeft: +value + 1,
                                  };
                                });
                              }}
                              onDecrement={(value) => {
                                if (Number(data?.marginLeft) > 0) {
                                  setData((cur) => {
                                    return {
                                      ...cur,
                                      marginLeft: +value - 1,
                                    };
                                  });
                                }
                              }}
                              onBlur={(ev) => {
                                setValueField(
                                  ev.target.value,
                                  "marginLeft",
                                  100,
                                  0
                                );
                              }}
                              incrementButtonAriaLabel={"Increase value by 1"}
                              decrementButtonAriaLabel={"Decrease value by 1"}
                            />
                          </div>
                          <div className="ms-Grid-col ms-md4">
                            <Label>{`${tr("marginSide")} (cm)`}</Label>
                            <SpinButton
                              value={`${data?.marginTop}`}
                              min={0}
                              max={100}
                              onIncrement={(value) => {
                                setData((cur) => {
                                  return {
                                    ...cur,
                                    marginTop: +value + 1,
                                  };
                                });
                              }}
                              onDecrement={(value) => {
                                if (Number(data?.marginTop) > 0) {
                                  setData((cur) => {
                                    return {
                                      ...cur,
                                      marginTop: +value - 1,
                                    };
                                  });
                                }
                              }}
                              onBlur={(ev) => {
                                setValueField(
                                  ev.target.value,
                                  "marginTop",
                                  100,
                                  0
                                );
                              }}
                              incrementButtonAriaLabel={"Increase value by 1"}
                              decrementButtonAriaLabel={"Decrease value by 1"}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
              <Stack
                horizontal
                horizontalAlign="end"
                tokens={{ childrenGap: 10 }}
              >
                <DefaultButton
                  text={tr("cancel")}
                  onClick={onCloseModal}
                  allowDisabledFocus
                />
                <PrimaryButton
                  text={tr("save")}
                  onClick={onSubmit}
                  allowDisabledFocus
                />
              </Stack>
            </Stack>
          </div>
        </ModalContent>
      </Modal>
    );
  }
);
